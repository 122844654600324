.inputText {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 201px;
  left: 88px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);
  width: 213px;
  height: 33px;
  left: 10vh;
  object-fit: cover;
  cursor: pointer;
}

.inputText2 {
  position: relative;
  line-height: 24px;
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 1;
  position: absolute;
  top: 0px;
  width: 90vw;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  object-fit: scale-down;
  flex-shrink: 0;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stepcircle {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: #fa6f32;
}

.instanceChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}

.dot {
  position: relative;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.stepcircle1 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stepcircleWithText1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.userName {
  align-self: stretch;
  position: relative;
  line-height: 32px;
  font-weight: 600;
}

.inputText3 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #334155;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 24px;
  color: #0f172a;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.inputField {
  position: absolute;
  height: 100%;
  width: 100% !important;
  left: 0px;
  line-height: 24px;
  padding: 10px;
  font-size: 16px !important;
}

.basicInput {
  display:flex;
  align-items: center;
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.inputWithLabel1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.inputText9 {
  font-size: 16px !important;
  line-height: 24px;
  width: 100% !important;
  height: 100%;
  padding: 10px;
}

.iconoutlineeye {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.inputWithLabelWrapper {
  align-self: stretch;
  flex: 1;
  /* display: flex; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdownIcon {
  position: relative;
  border-radius: 8px 0px 0px 8px;
  width: 79px;
  height: 44px;
}

.basicInput3 {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  border-radius: 0px 8px 8px 0px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  margin-left: -1px;
}

.phoneNumberInput {
  font-size: 16px !important;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  color: #0f172a;
}

.phoneNumberInput {
  height: auto;
}

.phoneNumberInput>div {
  height: 44px;
}

.phoneNumberInput>div>div:hover {
  background-color: #f1f5f9;
}

.frameContainer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.inputText12 {
  position: relative;
  line-height: 16px;
}

.iconsolidcheckCircleParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.frameDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 12px;
  color: #334155;
}

.body {
  border-radius: 8px;
  background-color: #fff;
  width: 316px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  color: #0f172a;
}

.frameGroup {
  align-self: stretch;
  /* height: 244px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.frameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  font-size: 14px;
}

.iconoutlineeye {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
}

.iconoutlineeye:hover {
  color: #fa6f32;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
  display: none;
}

.button1 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border: none;
  border-radius: 0.5rem;
  background-color: #FA6F32;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.button1:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.inputText17 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 139px;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: #475569;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.tagParent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.textButtonGroup {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  font-size: 16px;
  color: #475569;
}

.termsOfService {
  color: #fa6f32;
}

.inputTextTxtContainer {
  width: 100%;
  text-align: left;
}

.inputText19 {
  align-self: stretch;
  color: #334155;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 1rem;
}

.backButtonParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #64748B;
  gap: 0.5rem;
}

.backButtonIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.backButtonText {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.inputTextParent {
  color: #fff;
}

.regSideNav {
  background-color: #f3f3f3;
}

.signUpStep {
  color: #FA6F32;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}

@media screen and (max-width: 1250px) {
  .regSideNav {
    display: none;
  }

  .registrationSidebarRenter {
    display: none;
  }

  .backButton {
    width: 100%;
    float: left;
  }
}

@media screen and (max-width: 700px) {
  .textButtonGroup {
    padding: 1.5rem 1rem;
  }

  .backButtonParent {
    padding: 0 1rem;
  }

  .inputWithLabelParent,
  .frameContainer {
    display: unset;
  }

  .frameGroup {
    height: unset;
  }

  .inputTextTxtContainer {
    font-size: x-small;
  }

  .textButton {
    display: none;
  }

  .signUpStep {
    display: block;
  }

  .inputText2 {
    font-size: x-small;
  }

  .theLogoMakerBuiltFor1000 {
    /* position: unset; */
    left: 0;
    /* margin-top: 3vh; */
  }

  .frameParent {
    gap: unset;
  }

  .reefer {
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .inputText2 {
    display: none;
  }

  .stepcircleWithText,
  .stepcircleWithText1,
  .stepcircleWithText2 {
    gap: 3px;
  }

  .userName {
    font-size: 20px;
  }
}
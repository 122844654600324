.container {
  /* background-image: ("./landing/K1TruckHero.png"); */
  background-image: url(/public/landing/landing5_upscale2.jpeg);
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the image covers the full container */
  background-position: center;
  display: flex;
  min-height: 637px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 188px;
}

.content {
  display: flex;
  width: 727px;
  max-width: 100%;
  flex-direction: column;
  color: var(--Basic-White, #fff);
  justify-content: flex-start;
}

.title {
  font-size: 64px;
  font-weight: 600;
}

.description {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 12px;
}

.searchSection {
  display: flex;
  margin-top: 48px;
  width: 728px;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.searchHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.searchTitle {
  color: var(--Basic-White, #fff);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

.searchForm {
  display: flex;
  margin-top: 8px;
  height: 53px;
  width: 100%;
  gap: 12px;
  font-size: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.inputGroup {
  align-self: flex-end;
  display: flex;
  /* min-width: 240px; */
  align-items: flex-start;
  gap: -1px;
  color: var(--Neutral-400, #94a3b8);
  font-weight: 400;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 32px;
}

.locationInput {
  border-radius: 8px 0 0 8px;
  border: 1px solid var(--Neutral-400, #94a3b8);
  background: var(--Basic-White, #fff) !important;
  flex: 1;
  padding: 17px 12px;
  height: 53px !important;
}

.typeDropdown {
  justify-content: flex-end;
  border-radius: 0 8px 8px 0;
  border: 1px solid var(--Neutral-400, #94a3b8);
  background: var(--Basic-White, #fff);
  display: flex;
  gap: 40px 100px;
  white-space: nowrap;
  width: 211px;
  padding: 17px 10px;
}

.dropdownIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  margin: auto 0;
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Brand-600---main, #fa6f32);
  gap: 8px;
  overflow: hidden;
  color: var(--Basic-White, #fff) !important;
  font-weight: 600;
  white-space: nowrap;
  width: 173px;

  border: none;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.buttonContainer > button {
  background-color: #fa6f32;
  border: none;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.buttonContainer > button:hover {
  background-color: darkorange;
}

@media (max-width: 1050px) {
  .searchButton {
    white-space: initial;
  }
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .title {
    max-width: 100%;
    font-size: 40px;
  }
  .description {
    max-width: 100%;
  }
  .searchSection {
    margin-top: 40px;
  }
  .searchHeader {
    max-width: 100%;
  }
  .searchTitle {
    max-width: 100%;
  }
  .searchForm {
    max-width: 100%;
  }
  .inputGroup {
    max-width: 100%;
  }
  .locationInput {
    padding-right: 20px;
  }
  .typeDropdown {
    white-space: initial;
  }
}

@media (max-width: 710px) {
  .searchForm {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .searchButton {
    width: 100%;
  }
  .inputGroup {
    width: 100% !important;
    flex-wrap: unset;
  }
  .locationInput {
    font-size: 16px !important;
  }
}

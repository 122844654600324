.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  align-self: stretch;
  background-color: #334155;
}

.container {
  display: flex;
  width: 100%;
  padding: 4rem 10rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.footerContent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5rem;
  align-self: stretch;
}

.footerLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.logo {
  width: 2.73438rem;
  height: 2.5rem;
}

.description {
  align-self: stretch;
  margin: 0;
  color: #94A3B8;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.socialLink {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.footerLinks {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.footerColumn {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;
  align-self: stretch;
}

.sectionTitle {
  align-self: stretch;
  margin: 0;
  color: #CBD5E1;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;
}

.links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  color: #94A3B8;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.links li {
  cursor: pointer;
}

.footerLinks a {
  color: inherit;
  text-decoration: none;
}

.footerLinks a:hover {
  text-decoration: underline;
}

.footerBottom {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-top: 0.0625rem solid #E2E8F0;
  justify-content: center;
  align-items: center;
}

.footerBottom p {
  margin: 0;
  padding-top: 2rem;
  color: #CBD5E1;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}



@media only screen and (max-width: 1536px) {
  .container {
    padding: 4rem 8rem;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    padding: 4rem 4rem;
  }
  .footerContent {
    gap: 1rem;
  }
  .footerLinks {
    gap: 1rem;
  }
  .footerColumn {
    gap: 1rem;
  }
}

@media only screen and (max-width: 1024px) {
  .footerContent {
    flex-direction: column;
    gap: 3rem;
  }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 640px) {
  .container {
    padding: 3rem 1rem;
    align-self: stretch;
  }

  .footerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    align-self: stretch;
  }

  .footerLinks {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4rem;
    align-self: stretch;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }

}

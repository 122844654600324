.adminNavBar {
  display: none;
}

.adminSideBar {
  display: block;
  position: sticky;
  top: 0;
}

.allContent {
  width: 100%;
  overflow: auto;
  padding: 48px;
  font-family: Inter;
  text-align: left;
  line-height: 35px;
  background-color: #f8fafc;
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin: 4px 4px;
  padding: 24px 16px;
  color: #64748b;
  gap: 5px;
}

.dot {
  margin-bottom: 2px;
}

.allContent>div {
  margin-bottom: 1%;
}

.lightHeadText {
  font-weight: 600;
  font-size: 24px;
  color: #94a3b8;
}

.headText {
  color: #0f172a;
  font-weight: 600;
  font-size: 24px;
}

.smallHeadText {
  font-size: 14px;
  font-weight: 400;
  color: #94a3b8;
  display: inline-block;
  width: 100%;
}

.imageContainer {
  padding: 24px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  background: #ffffff;
}

.verticleCard {
  width: 280px;
  gap: 0px;
  border-radius: 8px;
  float: left;
}

.imageContainerRight {
  margin-left: 20px;
  float: left;
}

.imageContainerRightText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #cbd5e1;
  border-radius: 50%;
  display: inline-block;
}

.imageContainerLightText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #64748b;
}

.ratingStar {
  margin-left: 2px;
  margin-right: 2px;
}

.border {
  width: 410px;
  height: 1px;
  background-color: #e2e8f0;
}

.imageContainerLightColorText {
  color: #94a3b8;
}

.activeButton {
  float: right;
  font-size: 14px;
  font-weight: 600;
  background-color: #2dd4bf;
  color: white;
  border-radius: 16px;
  padding-left: 6px;
  padding-right: 6px;
  height: 34px;
  text-align: center;
}

.imageContainerAccept {
  width: 99px;
  height: 36px;
  padding: 6px;
  border-radius: 8px;
  background-color: #fa6f32;
  color: white;
  margin: 5px;
}

.imageContainerDecline {
  background-color: #f1f5f9;
  width: 99px;
  height: 36px;
  padding: 6px;
  border-radius: 8px;
  margin: 5px;
}

.declineTypography {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.subDiv {
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 24px 16px;
  width: 19%;
  display: inline-block;
  margin: 0.5%;
  height: 100%;
}

.subDiv span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.subDiv h4 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

@media (max-width: 800px) {
  .adminNavBar {
    display: block;
  }

  .box {
    padding: 16px 12px;
  }
  
  .allContent {
    padding: 24px;
  }

  .adminSideBar {
    display: none;
  }
}

@media (max-width: 1650px) {
  .imageContainerRight {
    width: 515px;
  }
}

@media (max-width: 1270px) {
  .subDiv {
    height: 160px;
  }
}

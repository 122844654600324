.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin: 4px 4px;
  padding: 24px 16px;
  color: #64748b;
  gap: 5px;
}

.navigationLinks {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

:hover.navigationLinks {
  background-color: rgba(128, 128, 128, 0.144);
  transition: background-color 0.3s ease;
}

.theLogoMakerBuiltFor1000 {
  width: 149.5px;
  position: relative;
  height: 24px;
  object-fit: cover;
  cursor: pointer;
}
.collapsedHeader {
  position: fixed;
  z-index: 1;
  left: 0;
}
.icon {
  position: absolute;
  width: 75%;
  top: calc(50% - 7px);
  right: 4%;
  left: 21%;
  max-width: 100%;
  overflow: hidden;
  height: 14px;
}
.iconoutlinearrowHideChild {
  position: absolute;
  height: 79%;
  width: 8.5%;
  top: 8.5%;
  right: 83%;
  bottom: 12.5%;
  left: 8.5%;
  border-radius: 30px;
  background-color: #94a3b8;
}
.iconoutlinearrowHide {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.homeIcon,
.text {
  position: relative;
}
.homeIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  line-height: 20px;
}
.content,
.verticalNavigationitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content {
  gap: 0 12px;
}
.verticalNavigationitem {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinebookOpen {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text1 {
  position: relative;
  line-height: 20px;
}
.content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
}
.iconsolidchevronDown {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.verticalNavigationitem1,
.settingSubTab {
  align-self: stretch;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 8px;
  box-sizing: border-box;
}

.settingSubTab {
  position: relative;
  padding-left: 17%;
}

.line {
  position: absolute;
  left: 0;
  height: 111%;
  width: 1px;
  background-color: #d3d3d3;
  top: 0;
  margin-left: 5%;
}

/* Add Dot for Start of the Line */
.line::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #d3d3d3;
  border-radius: 50%;
  top: 40%;
  left: -3px;
}

.firstLine {
  height: 70%;
  top: 45%;
}

.lastLine {
  height: 50%;
  top: -5%;
}

.lastLine::before {
  top: 100%;
}

.firstLine::before {
  top: 0%;
}

.sidebarLinks {
  transition: background-color 0.3s ease;
  width: 100% !important;
}

.settingSubTab:hover {
  transition: background-color 0.3s ease;
  background-color: rgba(128, 128, 128, 0.1);
}
.component28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #475569;
}
.iconoutlinecurrencyDollar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text2 {
  position: relative;
  line-height: 20px;
}
.content2,
.verticalNavigationitem2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content2 {
  gap: 0 12px;
}
.verticalNavigationitem2 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechartSquareBar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text3 {
  position: relative;
  line-height: 20px;
}
.content3,
.verticalNavigationitem3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content3 {
  gap: 0 12px;
}
.verticalNavigationitem3 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechartBar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text4 {
  position: relative;
  line-height: 20px;
}
.content4,
.verticalNavigationitem4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content4 {
  gap: 0 12px;
}
.verticalNavigationitem4 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechatAlt2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text5 {
  position: relative;
  line-height: 20px;
}
.content5,
.verticalNavigationitem5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content5 {
  gap: 0 12px;
}
.verticalNavigationitem5 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.navigationChild {
  align-self: stretch;
  position: relative;
  border-radius: 30px;
  background-color: #f1f5f9;
  height: 2px;
}
.iconoutlinechat {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text6 {
  position: relative;
  line-height: 20px;
}
.content6,
.verticalNavigationitem6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content6 {
  gap: 0 12px;
}
.verticalNavigationitem6 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinequestionMarkCir {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text7 {
  position: relative;
  line-height: 20px;
}
.content7,
.verticalNavigationitem7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content7 {
  gap: 0 12px;
}
.verticalNavigationitem7 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinecog {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text8 {
  position: relative;
  line-height: 20px;
}
.content8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
}
.iconsolidchevronUp {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.verticalNavigationitem8 {
  width: 240px;
  border-radius: 6px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
}
.homeChild,
.homeItem {
  position: absolute;
  background-color: #cbd5e1;
}
.homeChild {
  top: 13px;
  left: calc(50% - 1px);
  width: 2px;
  height: 23px;
}
.homeItem {
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.home,
.text9 {
  position: relative;
}
.home {
  width: 24px;
  height: 24px;
}
.text9 {
  line-height: 20px;
}
.content9,
.verticalNavigationitem9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content9 {
  gap: 0 12px;
}
.verticalNavigationitem9 {
  /* width: 240px; */
  border-radius: 6px;
  height: 40px;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
}
.ellipseDiv,
.homeInner {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 1px);
  background-color: #cbd5e1;
  width: 2px;
  height: 48px;
}
.ellipseDiv {
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.home1,
.text10 {
  position: relative;
}
.home1 {
  width: 24px;
  height: 24px;
}
.text10 {
  line-height: 20px;
}
.content10,
.verticalNavigationitem10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content10 {
  gap: 0 12px;
}
.verticalNavigationitem10 {
  /* width: 240px; */
  border-radius: 6px;
  height: 40px;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
}
.homeChild1,
.rectangleDiv {
  position: absolute;
  background-color: #cbd5e1;
}
.rectangleDiv {
  top: -12px;
  left: calc(50% - 1px);
  width: 2px;
  height: 26px;
}
.homeChild1 {
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  border-radius: 50%;
  width: 6px;
  height: 6px;
}
.home2,
.text11 {
  position: relative;
}
.home2 {
  width: 24px;
  height: 24px;
}
.text11,
.text9 {
  line-height: 20px;
  text-align: left;
}
.content11,
.verticalNavigationitem11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content11 {
  gap: 0 12px;
}
.verticalNavigationitem11 {
  /* width: 240px; */
  border-radius: 6px;
  background-color: #f3f4f6;
  height: 40px;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
  cursor: pointer;
  color: #111827;
}
.component30 {
  gap: 4px 0;
  color: #475569;
}
.component30,
.leadingContent,
.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigation {
  align-self: stretch;
  padding: 0 8px;
  gap: 4px 0;
}

.navigation > *:not(:last-child):hover,
.verticalNavigationitem9:hover,
.verticalNavigationitem10:hover,
.verticalNavigationitem11:hover {
  background-color: rgba(128, 128, 128, 0.144);
  cursor: pointer;
}

.leadingContent {
  width: auto;
  padding: 20px 0 16px;
  box-sizing: border-box;
  gap: 20px 0;
}
.iconoutlinelightningBolt {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text12 {
  position: relative;
  line-height: 20px;
}
.content12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 2px;
}
.text13 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.tag,
.verticalNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag {
  border-radius: 16px;
  background-color: #fa6f32;
  height: 28px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 4px 8px;
  box-sizing: border-box;
  text-align: right;
  font-size: 12px;
  color: #fff;
  width: max-content;
}
.verticalNavigation {
  align-self: stretch;
  border-radius: 6px;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  cursor: pointer;
}
.topBorder {
  align-self: stretch;
  position: relative;
  background-color: #e5e7eb;
  height: 1px;
}
.avatarIcon {
  width: 36px;
  border-radius: 18px;
  height: 36px;
  object-fit: cover;
}
.supportingText,
.title {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.supportingText {
  width: 68px;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  display: none;
}
.avatarWithText,
.text14 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatarWithText {
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
}
.iconoutlineselector {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.content13 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 6px;
  background-color: transparent; /* Initially transparent background */
  transition: background-color 0.3s ease; /* Transition effect for background color */
}

.content13:hover {
  background-color: rgba(
    128,
    128,
    128,
    0.1
  ); /* Slightly grayish opaque background on hover */
}

.footer {
  width: auto;
  align-items: center;
  cursor: pointer;
  color: #374151;
}
.simpleWithIconsBadgesAnd {
  position: fixed;
  height: 100vh;
  background-color: #fff;
  align-items: flex-start;
}
.userAdministration1 {
  color: #111827;
}
.text15 {
  position: relative;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.heyThereThis {
  position: relative;
  line-height: 20px;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
}
.iconoutlinecog1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button {
  border-radius: 8px;
  background-color: #e2e8f0;
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
}
.text17 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button1 {
  border-radius: 8px;
  background-color: #e2e8f0;
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
}
.iconoutlineuserAdd {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text18 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
}
.button2,
.buttonParent {
  display: flex;
  flex-direction: row;
}
.button2 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 36px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #fff;
}
.buttonParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 8px;
  color: #0f172a;
}
.heyThereThisBabyIsAPoweParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.creditsUsed {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameChild {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
  height: 8px;
  overflow: hidden;
}
.numberCardInner {
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
}
.ofCreditsUsed {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
}
.numberCard {
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 4px 0;
}
.creditsUsed1 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div1 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameItem,
.frameWrapper {
  height: 8px;
  overflow: hidden;
}
.frameItem {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
}
.frameWrapper {
  width: 158px;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  flex-shrink: 0;
  display: none;
}
.ofCreditsUsed1 {
  width: 111px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
  display: none;
}
.frameParent {
  align-self: stretch;
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 4px 0;
}
.creditsUsed2 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div2 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameInner {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
  height: 8px;
  overflow: hidden;
}
.frameContainer {
  width: 158px;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.ofCreditsUsed2 {
  width: 111px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
  display: none;
}
.frameGroup,
.numberCardParent {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  flex-direction: column;
  padding: 24px 16px;
  gap: 4px 0;
}
.numberCardParent {
  border-radius: 8px;
  background-color: #fff;
  flex-direction: row;
  padding: 32px 24px;
  gap: 0 8px;
  color: #64748b;
}
.tab1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab1Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild {
  align-self: stretch;
  position: relative;
  background-color: #fb814c;
  height: 3px;
}
.desktopTab {
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fa6f32;
}
.tab2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab2Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabItem {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab1 {
  cursor: pointer;
  height: 44px;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab3Wrapper {
  flex: 1;
  /*overflow: hidden;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabInner {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab2 {
  cursor: pointer;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab4Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild1 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab3 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab5Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild2 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab4 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab6Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild3 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab5 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab7Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild4 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab6,
.tabs {
  overflow: hidden;
  align-items: center;
}
.desktopTab6 {
  height: 44px;
  display: none;
  flex-direction: column;
  justify-content: center;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 30px;
}
.underLine {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.tabExampleLightDesktop,
.tabExampleLightDesktop1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.text19 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.divider,
.tables {
  align-self: stretch;
}
.tables {
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  font-size: 12px;
  color: #64748b;
}
.divider {
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.avatarIcon1 {
  width: 40px;
  border-radius: 8px;
  height: 40px;
  object-fit: cover;
}
.supportingText1,
.title1 {
  position: relative;
  line-height: 20px;
}
.supportingText1 {
  color: #64748b;
}
.tables1,
.text20 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tables1 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  padding: 16px 20px;
  gap: 0 16px;
  cursor: pointer;
}
.divider1 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.avatarIcon2 {
  width: 40px;
  border-radius: 8px;
  height: 40px;
  object-fit: cover;
}
.supportingText2,
.title2 {
  position: relative;
  line-height: 20px;
}
.supportingText2 {
  color: #64748b;
}
.tables2,
.text21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tables2 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  padding: 16px 20px;
  gap: 0 16px;
}
.divider2 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.avatarIcon3 {
  width: 40px;
  border-radius: 8px;
  height: 40px;
  object-fit: cover;
}
.supportingText3,
.title3 {
  position: relative;
  line-height: 20px;
}
.supportingText3 {
  color: #64748b;
}
.text22 {
  flex-direction: column;
}
.tables3,
.tablesParent,
.text22 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tables3 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  padding: 16px 20px;
  gap: 0 16px;
}
.tablesParent {
  flex: 1;
  flex-direction: column;
}
.text23 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tables4 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  text-align: left;
  color: #64748b;
}
.divider3 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text24 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.tables5,
.tag1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag1 {
  border-radius: 16px;
  background-color: #2dd4bf;
  height: 28px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 4px 8px;
  box-sizing: border-box;
}
.tables5 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  padding: 16px 20px;
}
.divider4 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text25 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.tables6,
.tag2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag2 {
  border-radius: 16px;
  background-color: #fa6f32;
  height: 28px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 4px 8px;
  box-sizing: border-box;
}
.tables6 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  padding: 16px 20px;
}
.divider5 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text26 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.tag3 {
  border-radius: 16px;
  background-color: #94a3b8;
  height: 28px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  box-sizing: border-box;
}
.tables7,
.tablesGroup {
  align-self: stretch;
  display: flex;
}
.tables7 {
  flex: 1;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
}
.tablesGroup {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: right;
  font-size: 12px;
  color: #fff;
}
.text27 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tables8 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  font-size: 12px;
  color: #64748b;
}
.divider6,
.supportingText4,
.title4 {
  align-self: stretch;
  position: relative;
}
.divider6 {
  background-color: #e2e8f0;
  height: 1px;
}
.supportingText4,
.title4 {
  line-height: 20px;
}
.title4 {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  flex-shrink: 0;
}
.supportingText4 {
  color: #64748b;
}
.tables9 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider7,
.supportingText5,
.title5 {
  align-self: stretch;
  position: relative;
}
.divider7 {
  background-color: #e2e8f0;
  height: 1px;
}
.supportingText5,
.title5 {
  line-height: 20px;
}
.title5 {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  flex-shrink: 0;
}
.supportingText5 {
  color: #64748b;
}
.tables10 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider8,
.supportingText6,
.title6 {
  align-self: stretch;
  position: relative;
}
.divider8 {
  background-color: #e2e8f0;
  height: 1px;
}
.supportingText6,
.title6 {
  line-height: 20px;
}
.title6 {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  flex-shrink: 0;
}
.supportingText6 {
  color: #64748b;
}
.tables11,
.tablesContainer {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tables11 {
  flex: 1;
  background-color: #fff;
  display: flex;
  padding: 16px 20px;
}
.tablesContainer {
  width: 305px;
  display: none;
}
.text28 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tables12 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  font-size: 12px;
  color: #64748b;
}
.divider9 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text29 {
  position: relative;
  line-height: 20px;
  cursor: pointer;
}
.tables13 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider10 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text30 {
  position: relative;
  line-height: 20px;
}
.tables14 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider11 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text31 {
  position: relative;
  line-height: 20px;
}
.tables15,
.tablesParent1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.tables15 {
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.tablesParent1 {
  flex-direction: column;
  align-items: flex-start;
}
.text32 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tables16 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  font-size: 12px;
  color: #64748b;
}
.divider12 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text33 {
  position: relative;
  line-height: 20px;
}
.tables17 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider13 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text34 {
  position: relative;
  line-height: 20px;
}
.tables18 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider14 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text35 {
  position: relative;
  line-height: 20px;
}
.tables19,
.tablesParent2 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.tables19 {
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.tablesParent2 {
  flex-direction: column;
  align-items: flex-start;
}
.text36 {
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.tablesiconoutlineselector5 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.tables20 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px 14px;
  gap: 0 4px;
  font-size: 12px;
  color: #64748b;
}
.divider15 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text37 {
  position: relative;
  line-height: 20px;
}
.tables21 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider16 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text38 {
  position: relative;
  line-height: 20px;
}
.tables22 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.divider17 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text39 {
  position: relative;
  line-height: 20px;
}
.tables23,
.tablesParent3 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.tables23 {
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.tablesParent3 {
  flex-direction: column;
  align-items: flex-start;
}
.text40 {
  width: 36px;
  position: relative;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
}
.tables24 {
  align-self: stretch;
  background-color: #f8fafc;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px;
  box-sizing: border-box;
  font-size: 12px;
  color: #64748b;
}
.divider18 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text41 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tables25,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tables25 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  padding: 16px 20px;
}
.divider19 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text42 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tables26,
.textButton1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tables26 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  padding: 16px 20px;
}
.divider20 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text43 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tables27,
.textButton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tables27 {
  align-self: stretch;
  flex: 1;
  background-color: #fff;
  justify-content: center;
  padding: 16px 20px;
}
.frameDiv,
.frameParent1,
.tablesParent4 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}
.tablesParent4 {
  display: none;
  flex-direction: column;
  color: #b73b04;
}
.frameDiv,
.frameParent1 {
  display: flex;
}
.frameParent1 {
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
  overflow: hidden;
  flex-direction: row;
}
.frameDiv {
  flex-direction: column;
  color: #0f172a;
}
.divider21 {
  width: 1200px;
  position: relative;
  background-color: #e5e7eb;
  height: 1px;
  display: none;
}
.details {
  width: 190px;
  display: none;
}
.details,
.details1 {
  position: relative;
  line-height: 20px;
}
.iconsolidchevronDown1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.details2 {
  position: relative;
  line-height: 20px;
}
.detailsParent {
  width: 451.3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 2px;
  color: #334155;
}
.chevronLeftIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.paginationstepLink {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px 0 0 6px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px;
}
.number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink {
  position: absolute;
  top: 0;
  left: 77px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.number1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink1 {
  position: absolute;
  top: 0;
  left: 119px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.number2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink2 {
  position: absolute;
  top: 0;
  left: 162px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.number3 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink3 {
  position: absolute;
  top: 0;
  left: 207px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.number4 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink4 {
  position: absolute;
  top: 0;
  left: 249px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.number5 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink5 {
  position: absolute;
  top: 0;
  left: 291px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
}
.chevronRightIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.paginationstepLink1 {
  position: absolute;
  top: 0;
  left: 340px;
  border-radius: 0 6px 6px 0;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px;
}
.number6 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.paginationnumberLink6 {
  position: absolute;
  top: 0;
  left: 37px;
  background-color: #feede5;
  border: 1px solid #fb814c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9px 17px;
  color: #fa6f32;
}
.paginationstepLinkParent {
  width: 378px;
  position: relative;
  height: 38px;
  text-align: center;
  color: #64748b;
}
.content14 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}
.cardFooterWithPageButtons {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #374151;
}
.tabExampleLightDesktopParent,
.textParent,
.userAdministration {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tabExampleLightDesktopParent {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  padding: 32px 24px;
  gap: 24px 0;
  color: #475569;
}
.textParent,
.userAdministration {
  background-color: #f8fafc;
}
.textParent {
  flex: 1;
  flex-direction: column;
  padding: 48px;
  gap: 16px 0;
  color: #94a3b8;
}
.userAdministration {
  width: 100%;
  position: relative;
  overflow: auto;
  flex-direction: row;
  text-align: left;
  font-size: 14px;
  color: #4b5563;
  font-family: Inter;
}

.tablePagination {
  justify-content: flex-end;
}

.tablePagination > div {
  display: flex;
  align-items: center;
}

.tablePagination > div > p {
  margin-bottom: 0;
}

li:hover,
button:hover {
  background-color: #cecece7a !important;
  /* background-color: #fb804c7a !important; */
}

li:active,
button:active {
  background-color: #fb804c7a !important;
}

.tableRow:hover {
  background-color: rgba(128, 128, 128, 0.144);
  cursor: pointer;
}

.adminNavBar {
  display: none;
}

.adminSideBar {
  display: block;
  position: sticky;
  top: 0;
}

.openSidebarToggle {
  position: absolute;
  top: 48px;
  left: 12px;
  z-index: 10;
  cursor: pointer;
}

.toggleAble {
  width: 13vw;
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateX(0);
  opacity: 1;
  background: white;
  z-index: 1000;
}

.toggleAble.hidden {
  transform: translateX(-100%);
  opacity: 0;
  display: none;
}

.addLocationParent {
  width: 92%;
  margin: 4% 4%;
}

.inputTextField {
  align-self: stretch;
  height: 46px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
}

.inputTextField:focus {
  border: 1px solid #94a3b8 !important;
}

@media (max-width: 1100px) {
  .box {
    padding: 16px 12px;
  }

  .numberCardParent {
    display: inline;
    padding: 2%;
  }
  .numberCard,
  .frameParent,
  .frameGroup {
    width: 31%;
    float: left;
    margin: 1%;
    height: 20%;
    padding: 24px 6px;
  }

  .iconoutlinequestionMarkCir1 {
    width: 15px;
  }
  .text18,
  .button2 {
    font-size: small;
    padding: 6px 5px;
  }
  .iconoutlineuserAdd {
    width: 15px;
  }
  .frameDiv th,
  .frameDiv td,
  .frameDiv,
  .ofCreditsUsed,
  .userTable th {
    font-size: xx-small;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .userTable td {
    font-size: xx-small;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .userTable div,
  .frameDiv div {
    min-height: unset;
  }
  .userNumberCard {
    padding: 8px 6px;
  }
  .heyThereThis {
    flex-shrink: unset;
  }
}

@media (max-width: 800px) {
  .simpleWithIconsBadgesAnd {
    display: none;
  }
  .footer {
    width: unset;
  }
  .verticalNavigation {
    padding: 15px;
  }
  .verticalNavigationitem1,
  .settingSubTab,
  .component28 {
    width: 100%;
  }
  .adminNavBar {
    display: block;
  }
  .adminSideBar {
    display: none;
  }
  .header {
    display: none;
  }
  .leadingContent {
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 540px) {
  .numberCard,
  .frameParent {
    width: 48%;
  }
}

@media (max-width: 520px) {
  .heyThereThis,
  .text18,
  .numberCardParent,
  .tableHeaderCell {
    font-size: small;
  }

  .tabs > div {
    width: 50px;
  }

  tr > td {
    font-size: 12px !important;
  }

  .tabExampleLightDesktop1 {
    overflow: auto;
  }
  .button2 {
    padding: 0px 5px;
  }
  .iconoutlineuserAdd {
    width: 10px;
    height: 10px;
  }
  .textParent,
  .numberCardParent,
  .tableHeaderCell {
    padding: 10px;
  }

  .tabExampleLightDesktopParent {
    padding: 15px;
  }
  .tabs {
    font-size: small;
    overflow: unset;
  }
  .tablePagination {
    justify-content: space-around;
  }
  .text15 {
    font-size: medium;
  }
}

.publishTransport {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 40px;
  font-weight: 600;
  font-family: inherit;
}
.chooseWhichWay {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  color: #000;
}
.pricingPagesText {
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: 100%;
}
.addTruck,
.pricingPagesText,
.rentFrame,
.shapesFrame {
  display: flex;
  justify-content: flex-start;
}
.shapesFrame {
  width: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
  max-width: 100%;
}
.addTruck,
.rentFrame {
  flex-direction: column;
  align-items: center;
}
.rentFrame {
  align-self: stretch;
  background-color: #f8fafc;
  padding: 128px 20px 300px;
  box-sizing: border-box;
  gap: 48px;
  max-width: 100%;
  text-align: left;
  font-size: 36px;
  color: #0f172a;
  font-family: Inter;
}
.addTruck {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  letter-spacing: normal;
}
@media screen and (max-width: 1200px) {
  .rentFrame {
    padding-top: 83px;
    padding-bottom: 195px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .publishTransport {
    font-size: 29px;
    line-height: 32px;
  }
}
@media screen and (max-width: 750px) {
  .rentFrame {
    gap: 24px;
    padding-top: 54px;
    padding-bottom: 127px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .publishTransport {
    font-size: 22px;
    line-height: 24px;
  }
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.descriptionInput {
  width: 100%;
  position: relative;
}

.basicInput2Description {
  width: 100%;
  position: relative;
  height: 10vh;
  align-self: stretch;

  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;

  font-size: 16px;
  color: #94a3b8;
}

.inputLeftContent2Description {
  border: none !important;
  height: 100%;
  padding: 2px;
}

textarea {
  outline: none !important;
}

.inputLeftContent2Description2 {
  font-size: 16px !important;
  border: none;
  outline: none;
  height: 8vh;
  width: 100%;
}

.iconoutlinebell {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}

.photo,
.text1 {
  position: relative;
}

.photo {
  width: 20px;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}

.button3,
.iconsolidmenu {
  overflow: hidden;
  flex-shrink: 0;
}

.iconsolidmenu {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
}

.button3 {
  width: 44px;
  border-radius: 0 8px 8px 0;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.button3,
.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.alreadyHaveAccount {
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
}

.registerATruckOrEdit6PChild {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.text2 {
  color:  #64748B;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText {
  color: #334155;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButtonParent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.component35Inner {
  position: absolute;
  height: 18.52%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 81.48%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.userName {
  color: #0F172A;
  font-family: Inter,sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  align-self: stretch;
}

.inputText1 {
  color: #334155;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  align-self: stretch;
}

.userNameParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex-shrink: 0;
}

.component35 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.icon1,
.text3 {
  position: relative;
}

.icon1 {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text3 {
  line-height: 20px;
  font-weight: 600;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.inputText2,
.inputText3 {
  position: relative;
  line-height: 20px;
}

.inputText2 {
  font-weight: 600;
}

.inputText3 {
  flex: 1;
}

.toggleOnIcon {
  position: absolute;
  top: calc(50% - 12px);
  right: 2px;
  width: 24px;
  height: 24px;
}

.on {
  position: absolute;
  top: 9px;
  left: 9px;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
  opacity: 0.8;
}

.inputTextParent,
.inputWithLabel {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.inputWithLabel {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.inputText4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText5 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText7 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent1 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput1 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel2,
.inputWithLabelGroup,
.inputWithLabelParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel2 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.inputWithLabelGroup,
.inputWithLabelParent {
  align-self: stretch;
}

.inputWithLabelGroup {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.inputWithLabelParent {
  flex-direction: column;
  gap: 16px;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}

.inputText8 {
  position: relative;
  line-height: 20px;
}

.inputText8 {
  font-weight: 600;
}

.inputText9 {
  flex: 1 0 0;
  color: #475569;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.toggleOnIcon1 {
  position: absolute;
  top: calc(50% - 12px);
  right: 2px;
  width: 24px;
  height: 24px;
}

.on1 {
  position: absolute;
  top: 9px;
  left: 9px;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
  opacity: 0.8;
}

.toggle1 {
  width: 48px;
  position: relative;
  border-radius: 40px;
  background-color: #fb814c;
  height: 28px;
  font-size: 9px;
  color: #fff;
  font-family: "Open Sans";
}

.inputTextGroup,
.inputWithLabel3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}

.inputWithLabel3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.inputText10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText11 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent2 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput2 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText13 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent3 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput3 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel5,
.inputWithLabelContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel5 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.inputWithLabelContainer {
  align-self: stretch;
}

.frameDiv {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.inputWithLabelContainer {
  flex-direction: column;
  gap: 16px;
}

.frameItem {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}

.inputText14 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText15 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent4 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput4 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel6 {
  width: 536px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.frameInner {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}

.inputText16 {
  color: #475569;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.iconoutlinecurrencyDollar5 {
  width: 1.25rem;
  height: 1.25rem;
}

.inputText17 {
  font-size: 16px !important;
  width: 100%;
  height: 19px;
  outline: none;
  border: none;
  color: #94A3B8;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.inputLeftContent5 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput5 {
  display: flex;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  align-items: center;
  align-self: stretch;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #94A3B8;
  background: #FFF;
}

.inputWithLabel7 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.inputText18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar6 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText19 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent6 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput6 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel8 {
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText20 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinecurrencyDollar7 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.inputText21 {
  position: relative;
  line-height: 24px;
}

.inputLeftContent7 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.basicInput7 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel9 {
  width: 260px;
  flex-direction: column;
  gap: 4px;
}

.inputWithLabelParentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.inputWithLabelParent1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  align-self: stretch;
}

.inputText22 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl1 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextContainer,
.tagParent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  width: 836px;
  display: none;
  font-size: 16px;
}

.inputText23 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl2 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl3 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl4 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextParent1,
.tag4,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextParent1,
.tagGroup {
  justify-content: flex-start;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextParent1 {
  width: 836px;
  display: none;
  font-size: 16px;
}

.textButtonGroup {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.text4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.button4:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
  cursor: pointer;
}

.text5 {
  color:  #0F172A;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button5 {
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #F1F5F9;
}

.text6 {
  color: #FFF;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button6 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #FA6F32;
}

.button6:hover {
  background-color: #e0571a !important;
  color: #fff !important;
  transition: background-color 0.3s !important;
}

.buttonParent1 {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttonContainer {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.component35Parent {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: -117px;
  width: 536px;
  height: 402px;
  object-fit: contain;
  display: none;
}

.inputText24,
.inputText25 {
  align-self: stretch;
  position: relative;
}

.inputText24 {
  line-height: 28px;
  font-weight: 600;
}

.inputText25 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent2 {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 307px;
  left: -31px;
  width: 564px;
  height: 527px;
  object-fit: contain;
}

.theLogoMakerBuiltFor10001 {
  position: absolute;
  top: calc(50% - 575px);
  left: 88px;
  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.celesClubPVidiGruzovikhMaParent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #cbd5e1;
  width: 376px;
  height: 1200px;
  overflow: hidden;
  font-size: 18px;
  color: #0f172a;
}

.registerATruckOrEdit6P {
  width: 100%;
  border-radius: 20px;
  position: relative;
  background-color: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1rem;
  gap: 16px;
  letter-spacing: normal;
}

.checkbox2 {
  content: unset !important;
}

.checkbox2:checked {
  background: #fb814c !important;
}

.toggle {
  position: relative;
  border-radius: 40px;
  background-color: #e2e8f0;
  width: 48px;
  height: 28px;
  font-size: 9px;
  color: #fff;
  font-family: "Open Sans";
}

.toggleChecked {
  background-color: #fb814c;
}

.toggle input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 8%;
  left: 5%;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  transition: 0.4s;
}

.toggle input:checked[type="checkbox"]::after {
  left: 45%;
}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .component35Parent {
    width: 100%;
  }

  .frameDiv {
    flex-direction: column;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
  }

  .inputWithLabelParent1 {
    flex-direction: column;
  }

  .buttonContainer {
    flex-direction: column;
    padding: 1rem;
    gap: 0.625rem;
  }

  .buttonParent1 {
    width: 100%;
    flex-direction: column;
  }

  .button5,
  .button6 {
    width: 100%;
  }
}

@media screen and (max-width: 625px) {
  .inputWithLabel6 {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {

  .inputText3,
  .inputText4,
  .inputText6,
  .inputText10,
  .inputText12,
  .text4,
  input::placeholder {
    font-size: small;
  }

  .inputWithLabel8,
  .inputWithLabel9 {
    width: 100%;
  }

  .inputText5 {
    font-size: x-small;
  }
}
html {
  scroll-behavior: smooth;
}

.homePage {
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}

main {
  flex: 1;
}

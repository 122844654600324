.registerATruckOrEdit4PChild {
  width: 1600px;
  height: 1200px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  max-width: 100%;
}

.theLogoMakerBuiltFor1000 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.text {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  height: 44px;
  border-radius: 8px;
  background-color: #fa6f32;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.iconoutlinebell {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.avatarFace04 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  line-height: 16px;
  font-weight: 600;
  z-index: 1;
}

.photo,
.text1,
.vt {
  position: relative;
}

.photo {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 3px 2px 2px;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  gap: 8px;
}

.iconsolidmenu {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button3 {
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  gap: 12px;
}

.alreadyHaveAccount,
.alreadyHaveAccountWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.alreadyHaveAccount {
  flex: 1;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}

.alreadyHaveAccountWrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 85px;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.textbuttonlabel {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText {
  position: relative;
  line-height: 20px;
  color: #334155;
}

.textButtonParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #317fd1;
  width: 149px;
  height: 24px;
}

.rectangleWrapper {
  align-self: stretch;
  height: 10px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.frameParent,
.parentFrameInner {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.frameParent {
  width: 600px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.parentFrameInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.inputText1,
.userName {
  align-self: stretch;
  position: relative;
}

.userName {
  margin: 0;
  font-size: inherit;
  line-height: 32px;
  font-weight: 600;
  font-family: inherit;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.parentFrameChild,
.userNameParent {
  display: flex;
  align-items: flex-start;
}

.userNameParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.parentFrameChild {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 10px;
  font-size: 24px;
}

.icon1,
.text2 {
  position: relative;
}

.icon1 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text2 {
  line-height: 20px;
  font-weight: 600;
}

.textButton1 {
  width: 118px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.inputText2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument {
  width: 1.5rem;
  height: 1.5rem;
}

.addImage1 {
  color: var(--Neutral-500, #64748B);
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.docsAddImageParent {
  display: flex;
  width: 16.4375rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.625rem;
  background: #CBD5E1;
}

.docsAddImages {
  width: 14rem;
  height: 9rem;
  border-radius: 0.625rem;
  flex-shrink: 0;
}

.dragAndDrop {
  color: var(--Neutral-500, #64748B);
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.addImageParent,
.iconoutlinedocumentParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addImageParent {
  align-self: stretch;
  text-align: center;
}

.iconoutlinedocumentParent {
  display: flex;
  width: 16.4375rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  border: 1px dashed var(--Neutral-500, #64748B);
}

.addImage {
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
}

.inputTextParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}

.inputText3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument1 {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.addImage3 {
  font-weight: 600;
}

.addImage3,
.dragAndDrop1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.addImage2,
.iconoutlinedocumentGroup,
.inputTextGroup {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.iconoutlinedocumentGroup {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 32px 31px;
  gap: 4px;
}

.addImage2,
.inputTextGroup {
  align-self: stretch;
  align-items: flex-start;
}

.addImage2 {
  gap: 2%;
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.inputTextGroup {
  flex-direction: column;
  gap: 4px;
}

.inputText4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument2 {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.addImage5 {
  font-weight: 600;
}

.addImage5,
.dragAndDrop2 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageContainer,
.iconoutlinedocumentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addImageContainer {
  align-self: stretch;
  align-items: flex-start;
}

.iconoutlinedocumentContainer {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  align-items: center;
  padding: 32px 31px;
  gap: 4px;
  max-width: 100%;
}

.addImage4,
.inputTextContainer {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.addImage4 {
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.inputTextContainer {
  flex-direction: column;
  gap: 4px;
}

.inputText5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument3 {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.addImage7 {
  font-weight: 600;
}

.addImage7,
.dragAndDrop3 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.addImage6,
.frameDiv,
.iconoutlinedocumentParent1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.iconoutlinedocumentParent1 {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 32px 31px;
  gap: 4px;
}

.addImage6,
.frameDiv {
  align-self: stretch;
  align-items: flex-start;
}

.addImage6 {
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.frameDiv {
  flex-direction: column;
  gap: 4px;
}

.inputText6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText7 {
  position: relative;
  line-height: 24px;
}

.secondlinetextIcon,
.textareaInputChild {
  width: 5.7px;
  height: 5.7px;
  position: absolute;
  margin: 0 !important;
  right: 4.3px;
  bottom: 4px;
  object-fit: contain;
}

.textareaInputChild {
  width: 2.8px;
  height: 2.8px;
  right: 4.2px;
  z-index: 1;
}

.inputWithLabel,
.textareaInput {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.textareaInput {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  padding: 8px 10px;
  position: relative;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel {
  height: 114px;
  gap: 4px;
  width: 100% !important;
}

.inputText8 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl1 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextParent1,
.tag1,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextParent1,
.tagParent {
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 212px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextParent1 {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}

.inputText9 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl2 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl3 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl4 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextParent2,
.tag4,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.inputTextParent2,
.tagGroup {
  justify-content: flex-start;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 454px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextParent2 {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}

.frameSection,
.textButtonGroup,
.textButtonGroupLeft {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.textButtonGroupParent {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.textButtonGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.textButtonGroupLeft {
  width: unset !important;
}

.frameSection {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  text-align: left;
  font-size: 14px;
  color: #475569;
  background-color: #fff;
  font-family: Inter;
  border-radius: 20px;
}

.celesClubPVidiGruzovikhMaIcon {
  width: 536px;
  height: 402px;
  position: relative;
  object-fit: contain;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.theLogoMakerBuiltFor10001 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.theLogoMakerBuiltFor1000Wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 37px 0 0;
}

.stockPhotoBigRigGrayBonneIcon {
  width: 564px;
  position: absolute;
  margin: 0 !important;
  top: 307px;
  right: -157px;
  height: 527px;
  object-fit: contain;
  z-index: 1;
}

.inputText10,
.inputText11 {
  align-self: stretch;
  position: relative;
}

.inputText10 {
  height: 56px;
  line-height: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.inputText11 {
  font-size: 14px;
  line-height: 20px;
}

.celesClubPVidiGruzovikhMaParent,
.inputTextParent3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputTextParent3 {
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  width: 376px;
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: -68px;
  background-color: #cbd5e1;
  overflow: hidden;
  align-items: center;
  padding: 25px 38px 828px 88px;
  box-sizing: border-box;
  gap: 146px;
  max-width: 100%;
  z-index: 1;
  font-size: 18px;
}

.textContent {
  position: relative;
  font-family: Inter;
  color: #0f172a;
  text-align: left;
}

.parentFrame {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.textContent {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.button4 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #f1f5f9;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button4:hover,
.button5:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
}

.inputFieldsFrame {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #0f172a;
  text-align: left;
}

.button5 {
  cursor: pointer;
  border: none;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.text3 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.button6 {
  cursor: pointer;
  border: none;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Brand-600---main, #FA6F32);
}

.button6:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.twoButtonsFrame {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.inputFieldPair {
  box-sizing: border-box;
  max-width: 100%;
}

.buttonContainer {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.inputFieldPair {
  justify-content: flex-end;
  padding: 0 20px;
}

.registerATruckOrEdit4P {
  width: 100%;
  position: relative;
  background-color: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  gap: 16px;
  letter-spacing: normal;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.fileInputLabel>input {
  display: none !important;
}

.fileInputLabel {
  display: flex;
  padding: 0.625rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: var(--Neutral-50, #F8FAFC);
}

.fileName {
  display: flex;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .tagGroup {
    flex-wrap: wrap;
    min-width: 100%;
    margin-left: 0;
  }

  .textButtonGroupParent {
    padding: 1.5rem 1rem;
  }

  .addImage {
    flex-direction: column;
    gap: 0.25rem;
  }

  .textButtonGroup {
    gap: 1rem;
  }

  .docsAddImageParent {
    width: 100%;
  }

  .fileInputLabel {
    width: 100%;
  }

  .parentFrame {
    width: 100%;
  }

  .textButtonGroupLeft {
    display: none;
  }

  .inputText2 {
    display: block;
  }

  .buttonContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .twoButtonsFrame {
    flex-direction: column;
    width: 100%;
  }

  .button5,
  .button6 {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .textButtonParent {
    flex-wrap: wrap;
  }

  .textButtonGroupLeft {
    display: none;
  }

  .userName {
    font-size: 19px;
    line-height: 26px;
  }

  .tagParent {
    margin-left: 0;
  }

  .tagParent {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 500px) {

  .dragAndDrop,
  .textContent,
  .inputFieldsFrame,
  .text3,
  .inputText0 {
    font-size: small;
  }

  .iconoutlinedocumentParent,
  .iconoutlinedocumentGroup,
  .iconoutlinedocumentContainer,
  .iconoutlinedocumentParent1 {
    padding: 10px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.25rem;
}

.title {
  align-self: stretch;
  color: #0F172A;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;
}

.detail {
  align-self: stretch;
  color: #334155;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 0;
}

.radioGroup {
  display: flex;
  align-items: center;
  align-content: center;
  color: #475569;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.radioGroupItem1 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  border: 1px solid #CBD5E1;
}

.radioGroupItem2 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.375rem;
  border-top: 1px solid #CBD5E1;
  border-bottom: 1px solid #CBD5E1;
  background-color: #FFF;
}

.radioGroupItem3 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
  border: 1px solid #CBD5E1;
  background-color: #FFF;
}

.activeItem {
  border: 1px solid #FB814C !important;
  color: #FA6F32 !important;
  background-color: #FEEDE5 !important;
}

.instanceGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1.25rem;
  background-color: #FFF;
  padding: 2rem;
  gap: 2rem;
}

.backButtonParent {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.frameParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.userName1 {
  align-self: stretch;
  color: #0F172A;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin: 0;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}

.iconsolidcheckCircleParent {
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 0.25rem;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  flex: 1 0 0;
  color: #334155;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.image {
  width: 10.4375rem;
  height: 7.8125rem;
  object-fit: cover;
  flex-shrink: 0;
}

.button {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border: none;
  border-radius: 0.5rem;
  background-color: #FA6F32;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}


.button:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}


.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.about {
  color: #334155;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.link {
  color: #FA6F32 !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}


@media (max-width: 625px) {
  .container {
    width: 100%;
    justify-content: flex-start;
    padding: 0 1rem;
    gap: 1.5rem;
  }

  .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .detail {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .instanceGroup {
    padding: 1.5rem 1rem;
    width: 100%;
  }

  .image {
    display: none;
  }
}
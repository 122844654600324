.toggle {
    position: relative;
    border-radius: 20px;
    background-color: #e2e8f0;
    width: 48px;
    height: 28px;
    font-size: 9px;
    color: #fff;
    font-family: "Open Sans";
}

.toggleChecked {
    background-color: #fb814c;
}
.checkbox2 {
    content: unset !important;
}

.checkbox2:checked {
    /*background: #fb814c !important;*/
}



.toggle input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    top: 7%;
    left: 5%;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

    transition: 0.4s;
}

.toggle input:checked[type="checkbox"]::after {
    left: 45%;
}
.on {
    position: absolute;
    top: 9px;
    left: 9px;
    text-transform: uppercase;
    font-weight: 600;
    display: none;
    opacity: 0.8;
}

.adminNavBar,
.tabsDropdown {
  display: none;
}

.adminSideBar {
  display: block;
  position: sticky;
  top: 0;
}


body {
  background-color: #f5f5f5;
  color: #333;
}

.container122 {
  width: 100%;
  overflow: auto;
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  color: #4b5563;
  font-family: Inter;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
}

.rightContent {
  width: 83vw;
}

.rightContent h2 {
  text-align: left;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding: 48px;
  color: #475569;
}

.sidebar {
  width: 22%;
  color: #333;
  padding: 20px;
  background-color: rgb(248, 249, 251);
}

.sidebar ul {
  text-align: left;
  list-style: none;
}

.sidebar ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

.content {
  padding: 20px;
  width: 78%;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 100px;
}

.changePhotoTop {
  float: left;
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profilePhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3c85eb;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 20px;
}

.profileInfo h1 {
  font-size: 24px;
  margin-bottom: 5px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.profileInfo p {
  margin-bottom: 5px;
}

.profileInfo span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.profileForm .formGroup {
  margin-bottom: 15px;
}

.profileForm .formGroup label {
  float: left;
  display: flex;
  margin: 5px;
  font-family: Inter,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.profileForm .formGroup .inputField,
.deactivateReason,
.deactivateReason option,
.inputField {
  font-size: 16px !important;
  width: 30%;
  height: 44px;
  /*padding: 10px;*/
  border-radius: 8px;
  border: 1px solid #ccc;
}

.tabsDropdown,
.tabsDropdown option {
  width: 80%;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.btn {
  padding: 10px 20px;
  background-color: #fa6f32;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.btn:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.saveChanges {
  width: 25%;
}

.actions {
  margin-top: 20px;
}

.action {
  margin-bottom: 20px;
}

.action h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.action p,
.action ul {
  margin-bottom: 10px;
}

.action ul {
  list-style: disc;
  margin-left: 20px;
}

.action .btn {
  display: inline-block;
  margin-top: 10px;
}

.dot {
  width: 2px;
  height: 2px;
  background-color: #050505;
  border-radius: 50%;
  display: inline-block;
  margin: 6px;
}

.inputSVGWraper {
  position: relative;
}

.inputSVGWraper .inputField {
  padding-right: 30px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputSVGWraper svg {
  position: absolute;
  top: 50%;
  transform: translate(-10px, -50%);
  pointer-events: none;
}

.profileInfo span {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.profileInfo p {
  color: #94a3b8;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  float: left;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
}

.inputField:focus,
.deactivateReason:focus {
  border: 1px solid #ccc !important;
}

.btnLight {
  background-color: #f1f5f9;
  color: #0f172a;
}

.btnLight:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
}

.modalButtonLabel {
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.modalButtinContainerText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #475569;
}

.modalButtonRight {
  text-align: left;
}

.deactivateReason {
  width: 100%;
}

.btnRight {
  float: right;
}

.btnDisabled {
  color: #94a3b8;
}

.updatePasswordModal {
  margin-top: 10%;
}

.modalInput,
.passwordLabel {
  width: 40% !important;
}

.passwordLabel {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475569;
}

.profileForm .formGroup {
  display: flex;
  align-items: center;
}

.profileForm .formGroup .col-6 {
  position: relative;
}

.profileForm .formGroup .col-12 {
  padding-right: 30px;
}

.passwordField {
  width: 100% !important;
}

.passwordForm {
  margin-left: 3%;
}

.modalTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #475569;
}

.minChars {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-top: 5px;
  color: #475569;
}

.passwordForm .formGroup {
  margin-right: -10%;
}

.horizontalLine {
  height: 1px;
  width: 98%;
  margin-bottom: 3%;
  background: #d9d9d9;
}

@media (max-width: 1700px) {
  .rightContent {
    width: 78vw;
  }
}

@media (max-width: 1350px) {

  .saveChangesBtn,
  .btn,
  .formGroup .inputField {
    font-size: 13px;
  }

  .addCompanyBtn {
    width: 60% !important;
    font-size: 9px;
  }

  .btn {
    font-size: 12px;
  }

  .rightContent {
    width: 70vw;
  }

  .modalButtonRight {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .formGroup .inputField {
    width: 92% !important;
    margin-left: 15px;
    margin-right:15px;
  }

  .inputSVGWraper svg {
    display: none;
  }

  .modalButtonRight {
    width: 70%;
  }

  .btnRight {
    width: 45% !important;
  }

  .saveChangesBtn {
    width: 92% !important;
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 880px) {
  .btnRight {
    width: 50% !important;
  }

  .btnMobile {
    width: 43% !important;
  }

  .sidebar {
    display: none;
  }

  .content,
  .tabsDropdown {
    width: 90%;
  }

  .tabsDropdown {
    display: block;
  }
}

@media (max-width: 850px) {
  .container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .content {
    margin-bottom: 3%;
  }

  .saveChangesBtn,
  .modalButtonLabel,
  .btn,
  .modalButtonRight {
    width: 92% !important;
    margin-left: 15px;
    margin-right: 15px;  }

  .btnMobile {
    width: 35% !important;
  }

  .modalButtonLabel {
    margin-bottom: 10px;
  }

  .rightContent {
    width: 100vw;
  }
}

@media (max-width: 768px) {
  .adminNavBar {
    display: block;
  }

  .adminSideBar {
    display: none;
  }

  .profileHeader {
    align-items: flex-start;
  }

  .profileInfo {
    text-align: left;
  }

  .profileInfo span {
    font-size: 12px;
  }

  .profilePhoto {
    margin-bottom: 10px;
  }

  .btn {
    font-size: 12px;
  }
}

@media (max-width: 478px) {
  .container {
    width: 90%;
  }

  .input {
    width: 100% !important;
  }

  .btn {
    padding: 4px;
  }

  .content,
  .tabsDropdown {
    /*width: 96%;*/
    height:auto;
    width: 90%;
  }

  .profileForm .formGroup label {
    font-size: 12px;
    width: 100%;

  }
}

@media (max-width: 800px) {
  .adminNavBar {
    display: block;
  }
}
body {
  background-color: #f8fafc !important;
}

.App {
  text-align: center;
}

.form-check-input:checked {
  background-color: unset !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.disabledButton,
.disabledButton > *,
.disabledButton > * > * > * {
  pointer-events: none;
  color: gray;
  text-decoration: line-through;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none !important;
  color: inherit;
}
input {
  color: black !important;
}

input:focus-visible {
  border: none !important;
  outline: none !important;
}

input[type="checkbox"] {
  appearance: none;
  /* border: 1px solid gray !important; */
  border-radius: 16px;
  cursor: pointer;
}

input[type="radio"] {
  appearance: none !important;
  border: 1px solid gray !important;
  border-radius: 16px;
  cursor: pointer;
}

input:checked {
  content: url(/public/generics/check.svg);
  border: 0 !important;
}

input[type="text"] {
  width: 90%;
  font-size: 16px;
  border: 0;
  height: 100%;
  background: transparent;
}

a:hover {
  filter: brightness(90%);
}

* {
  font-family: "Inter", sans-serif !important;
}

a {
  color: inherit !important;
}

input::placeholder,
input::after {
  font-family: "Inter", sans-serif;
}

.carousel-control-next,
.carousel-control-prev {
  height: 10% !important;
  margin: 20% 0 0 0 !important;
  z-index: 99999 !important;
}

.truck_container > div > img {
  height: 10% !important;
  margin: 20% 0 0 0 !important;
}

.hoverButtons {
  transition: background-color 0.3s, filter 0.3s;
  cursor: pointer;
}

.hoverButtons:hover {
  filter: brightness(90%);
}

.hoverDropdown {
  transition: background-color 0.3s ease-in-out;
  z-index: 3;
}

.hoverDropdown:hover {
  background-color: #fedbcc;
  cursor: pointer;
}

#listItems > * {
  transition: background-color 0.3s, filter 0.3s;
  cursor: pointer;
}

#listItems > *:hover {
  filter: brightness(90%);
}

.celesClubPVidiGruzovikhMaParent,
.property11 {
  position: fixed !important;
}

.backButtonParent,
.textButton {
  cursor: pointer !important;
}

.errorBox {
  display: none;
  opacity: 0;

  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease;
  transition: height 0.5s ease;
  width: 100%;
  font-size: 14px;
  color: white;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 22, 22, 0.74);
}

.errorBox.active {
  display: flex;
  height: 8vh;
  opacity: 1;
}

.modal-dialog .modal-content {
  border-radius: 18px;
}

.password-modal .modal-content {
  border-radius: 18px;
}

.password-modal .modal-header {
  width: 94%;
  margin-left: 3%;
}

.password-modal button.btn-close,
.filters-modal button.btn-close {
  border: 1px solid;
  padding: 10px;
  border-radius: 8px;
  background-color: #f1f5f9;
}

.filters-modal .btn-close {
  border: 1px solid grey;
  padding: 13px;
  border-radius: 4px;
  background-color: #23272e08;
  color: black !important;
  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.float-left {
  float: left;
}
.text-theme {
  color: #fa6f32 !important;
}

button.react-international-phone-country-selector-button {
  display: none;
}

.bg-gray {
  background-color: rgba(128, 128, 128, 0.1);
}

.MuiButton-root:hover,
.MuiTypography-root:hover,
.MuiCard-root:hover,
.MuiTextField-root:hover,
.MuiSelect-root:hover,
.MuiListItem-root:hover,
.MuiIconButton-root:hover,
.MuiTab-root:hover {
  background-color: transparent !important; /* or use color if you don't want background change */
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
  background: transparent !important;
}

/* Tab selection */
.MuiTab-root.Mui-selected {
  color: black !important;
}

.MuiTab-root {
  text-transform: capitalize !important;
}

.MuiTab-root:active {
  background-color: #fa6e325b !important;
}
.MuiTab-root.MuiTabs-indicator {
  color: #fa6f32 !important;
  background-color: rgba(
    255,
    165,
    0,
    0.1
  ) !important; /* optional light background for selection */
}

.MuiTabs-indicator {
  background-color: #fa6f32 !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: 100% !important;
  /* width: 400px !important; */
  /* height: inherit !important; */
}
.leaflet-popup-content-wrapper {
  /* height: inherit !important; */
  padding: 0 !important;
  /* height: 270px !important; */
}

.popupScrollable2 > .leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup-close-button {
  background-color: white !important;
  margin: 12px;
  border-radius: 320px;
  width: 32px !important;
  height: 32px !important;
  z-index: 99999 !important;
  box-shadow: 1px 1px 8px 0px #00000038;
}

.leaflet-popup-close-button > span {
  position: absolute;
  color: black;
  font-size: 24px !important;
  height: inherit;
  transform: translate(-50%, 10%);
}

.MuiPaper-root::before {
  opacity: 0 !important;
}

@media (max-width: 992px) {
  .filters-modal .btn-close {
    padding: 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.imageViewer {
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: hidden;
}

.iconsolidx {
  filter: invert(1);
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 20; 
  width: 40px;
  cursor: pointer;
}

.iconsolidx:hover {
  background: deepskyblue;
}

.image {
  max-width: 90%;
  max-height: 90%;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.217);
  border: none;
  color: white;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 20;
}

.leftArrow {
  left: 10px;
}

.rightArrow {
  right: 10px;
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

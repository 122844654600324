.section {
  display: flex;
  justify-content: space-between;
  background-color: #F8FAFC;
  width: 100%;
  gap: 10px;
}

.leadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5% 5% 5% 10%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.eyebrow {
  color: var(--Brand-600---main, #fa6f32);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.heading {
  color: var(--Neutral-900, #0f172a);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}

.supportingText {
  color: var(--Neutral-500, #64748b);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.ctaButton {
  align-self: flex-start;
  border-radius: 8px;
  background: var(--Brand-600---main, #fa6f32);
  padding: 10px 16px;
  font-size: 16px;
  color: var(--Basic-White, #fff);
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.ctaButton:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.featureImageContainer {
  width: 50%;
}

.heroImage {
  width: 100%;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.testimonial {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  justify-content: flex-start;
}

.topBorder {
  background: var(--Neutral-200, #e2e8f0);
  min-height: 1px;
  width: 100%;
}

.testimonialContent {
  display: flex;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;
  justify-content: flex-start;
}

.quote {
  color: var(--Neutral-500, #64748b);
  font-weight: 400;
  line-height: 24px;
}

.testimonialFooter {
  display: flex;
  margin-top: 12px;
  width: 100%;
  align-items: center;
  gap: 12px;
  color: var(--Neutral-700, #334155);
  font-weight: 500;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.avatarImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  border-radius: 20px;
  align-self: stretch;
  min-height: 40px;
  margin: auto 0;
}

.attribution {
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}


.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .section {
    max-width: 100%;
  }

  .heading {
    max-width: 100%;
  }

  .supportingText {
    max-width: 100%;
  }

  .testimonial {
    max-width: 100%;
  }

  .topBorder {
    max-width: 100%;
  }

  .testimonialContent {
    max-width: 100%;
  }

  .quote {
    max-width: 100%;
  }

  .header {
    max-width: 100%;
  }

  .testimonialFooter {
    max-width: 100%;
  }

  .attribution {
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .section {
    flex-direction: column;
    padding: 5% 5%;
  }

  .leadingContent {
    width: 100%;
    padding: 5% 0;
  }

  .featureImageContainer {
    width: 100%;
  }

  .heroImage {
    border-radius: 8px 8px;
  }
}

@media (max-width: 450px) {
  .heading {
    font-size: x-large;
  }
}

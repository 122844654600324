.fieldSelectorWrapper {
  display: flex;
  gap: 3vh;
  padding: 12px;
}

.form {
  font-size: 16px !important;
  align-self: stretch;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid lightgray !important;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  flex:1;
}

.filterButton {
  position: relative;
  display: flex;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 56px;
  overflow: hidden;
  justify-content: center;
  padding: 16px;
  gap: 8px;
  color: #0f172a;
  border: 1px solid lightgray;
}

.formWrapper {
  display: flex;
  flex: 1;
}

.theLogoMakerBuiltFor1000 {
  height: 35px;
  width: 224px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.shapesIcon {
  height: 213px;
  width: 213px;
  position: absolute;
  margin: 0 !important;
  top: -206px;
  right: -207px;
  opacity: 0.2;
  z-index: 1;
}

.frameWrapper,
.theLogoMakerBuiltFor1000Parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.theLogoMakerBuiltFor1000Parent {
  position: relative;
}

.frameWrapper {
  align-self: stretch;
}

.turnYourTruck {
  align-self: stretch;
  height: 56px;
  position: relative;
  line-height: 28px;
  display: inline-block;
}

.frameParent {
  width: 454px;
  border-radius: 10px;
  background-color: rgba(60, 133, 235, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 109px 24px 24px;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
}

.supportingText {
  width: 422px;
  position: relative;
  line-height: 28px;
  color: #6b7280;
  display: none;
  max-width: 100%;
}

.pricingPagestierChild {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #e2e8f0;
  display: none;
}

.checkIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text1 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon2 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text2 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon3 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text3 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon4 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text4 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon5 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.text5 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: calc(100% - 36px);
}

.pricingPagesfeature5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.checkIcon6 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text6 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.pricingPagesfeature6 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #6b7280;
}

.checkIcon7 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text7 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.pricingPagesfeature7 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #6b7280;
}

.checkIcon8 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text8 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.pricingPagesfeature8 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #6b7280;
}

.checkIcon9 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text9 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.pricingPagesfeature9 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #6b7280;
}

.checkIcon10 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text10 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.pricingPagesfeature10 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #6b7280;
}

.checkIcon11 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.text11 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 169px;
}

.features,
.pricingPagesfeature11 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.pricingPagesfeature11 {
  display: none;
  flex-direction: row;
  gap: 12px;
  color: #6b7280;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}

.text12 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.button {
  cursor: pointer;
  border: 0;
  padding: 10px 16px;
  background-color: #fa6f32;
  align-self: stretch;
  height: 50px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}

.button:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.content,
.pricingPagestier {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.content {
  align-self: stretch;
  align-items: flex-start;
  padding: 40px 40px 24px;
  gap: 24px;
  font-size: 16px;
  color: #64748b;
}

.pricingPagestier {
  flex: 1;

  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  align-items: center;
  padding: 24px 24px 24px;
  gap: 25px;
  min-width: 250px;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-family: Inter;
}

@media screen and (max-width: 450px) {

  .pricingPagesfeature10,
  .pricingPagesfeature11,
  .pricingPagesfeature6,
  .pricingPagesfeature7,
  .pricingPagesfeature8,
  .pricingPagesfeature9 {
    flex-wrap: wrap;
  }

  .content {
    padding-top: 26px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}

.processSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3% 5%;
  gap:3rem;
}

.header {
  text-align: center;
  width: 75%;
  padding: 2% 5%;
}

.eyebrow {
  font-size: 16px;
  color: #fa6f32;
  text-transform: uppercase;
  margin-bottom: 8px;
  display: block;
}

.title {
  font-size: 36px;
  color: #0f172a;
  font-weight: 600;
  margin-bottom: 16px;
}

.description {
  font-size: 18px;
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 20px;
}

.link {
  display: inline-flex;
  align-items: center;
  color: #3c85eb;
  font-size: 18px;
  color: #fa6f32 !important;
  font-weight: 600;
  text-decoration: none;
}

.arrowIcon {
  margin-left: 8px;
}

.stepsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2% 5%;
}

.step {
  width: calc(25% - 24px);
  margin-bottom: 24px;
}

.stepIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  gap: 10%;
}

.iconBackground {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  background-color: #fa6f32;
  border-radius: 50%;
}

.stepContent {
  text-align: left;
}

.dashedline {
  border-top: 2px dashed #d6d6d6;
  width: 70%;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}

.stepTitle {
  font-size: 18px;
  color: #0f172a;
  font-weight: 600;
  margin-bottom: 12px;
}

.stepDescription {
  font-size: 16px;
  color: #64748b;
  line-height: 1.5;
}

@media (max-width: 991px) {
  .step {
    width: calc(50% - 24px);
  }
  .header {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .step {
    width: 100%;
  }
  .dashedline {
    display: none;
  }
  .stepsContainer{
    padding:0;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: x-large;
  }
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.iconoutlinebell {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}

.photo,
.text1 {
  position: relative;
}

.photo {
  width: 20px;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}

.button3,
.iconsolidmenu {
  overflow: hidden;
  flex-shrink: 0;
}

.iconsolidmenu {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
}

.button3 {
  width: 44px;
  border-radius: 0 8px 8px 0;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.button3,
.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.alreadyHaveAccount {
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
}

.registerATruckOrEdit3PChild {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;

  display: none;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.text2 {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText {
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButtonParent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.component35Inner {
  position: absolute;
  height: 18.52%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 81.48%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.userName {
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  align-self: stretch;
}

.inputText1 {
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  align-self: stretch;
}

.userNameParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex-shrink: 0;
}

.component35 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.icon1,
.text3 {
  position: relative;
}

.icon1 {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text3 {
  line-height: 20px;
  font-weight: 600;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.inputText2 {
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.inputText3 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.tag {
  display: flex;
  height: 2.75rem;
  padding: 0.375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
  color: #475569;
  text-align: right;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.earnRevenueFromYourIdleFl1 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.tagParent {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.inputWithLabelParentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
}

.inputWithLabelParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}

.inputText4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText5 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput1 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl2 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl3 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl4 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.inputWithLabelGroup,
.tagGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tagGroup {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.inputWithLabelGroup {
  align-self: stretch;
  flex-direction: column;
  gap: 4px;
}

.inputText6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText7 {
  position: absolute;
  left: 12px;
  line-height: 24px;
}

.iconsolidcheveronDown {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.dropdown {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText9 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.iconsolidcheveronDown1 {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.dropdownButton {
  border: none;
}

.dropdown1 {
  position: relative;
  display: flex;
  padding: 0.825rem 0.75rem 0.825rem 0.75rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-400, #94A3B8);
  background: var(--Basic-White, #FFF);
  align-self: stretch;
}

.inputWithLabel3,
.inputWithLabelContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel3 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.inputWithLabelContainer {
  align-self: stretch;
}

.inputWithLabelContainer {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}

.inputText10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText11 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput2 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl5 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag5 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl6 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag6 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.frameDiv,
.tagContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tagContainer {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.frameDiv {
  width: 264px;
  flex-direction: column;
  gap: 4px;
}

.inputText12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText13 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput3 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl7 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag7 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl8 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag8 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.inputWithLabelParent1,
.tagParent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tagParent1 {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.inputWithLabelParent1 {
  width: 264px;
  flex-direction: column;
  gap: 4px;
}

.inputText14 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText15 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput4 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl9 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag9 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl10 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag10 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.inputWithLabelParent2,
.tagParent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tagParent2 {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.inputWithLabelParent2 {
  width: 264px;
  flex-direction: column;
  gap: 4px;
}

.inputText16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText17 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput5 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel7 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl11 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer11 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag11 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl12 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.frameChild {
  height: 0.0625rem;
  align-self: stretch;
  width: 100%;
  background: var(--Neutral-200, #E2E8F0);
}

.reefer12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag12 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.inputWithLabelParent3,
.tagParent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tagParent3 {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.inputWithLabelParent3 {
  width: 264px;
  flex-direction: column;
  gap: 4px;
}

.inputText18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText19 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput6 {
  width: 235px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  display: none;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl13 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer13 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag13 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl14 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer14 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag14 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl15 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer15 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag15 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl16 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
  display: none;
}

.reefer16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag16 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.tagParent4 {
  flex-direction: row;
  gap: 8px;
  text-align: right;
}

.frameContainer,
.inputWithLabelParent4,
.tagParent4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabelParent4 {
  width: 264px;
  flex-direction: column;
  gap: 4px;
}

.frameContainer {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.inputText20 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl17 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer17 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag17 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl18 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextParent,
.tag18,
.tagParent5 {
  flex-direction: row;
  align-items: center;
}

.tag18 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextParent,
.tagParent5 {
  justify-content: flex-start;
}

.tagParent5 {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextParent {
  width: 836px;
  display: none;
  font-size: 16px;
}

.inputText21 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl19 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer19 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag19 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl20 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer20 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag20 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl21 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer21 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextGroup,
.tag21,
.tagParent6 {
  flex-direction: row;
  align-items: center;
}

.tag21 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextGroup,
.tagParent6 {
  justify-content: flex-start;
}

.tagParent6 {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextGroup {
  width: 836px;
  display: none;
  font-size: 16px;
}

.textButtonGroup {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.25rem;
  background: #FFF;
  align-self: stretch;
}

.text4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.button4:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
  cursor: pointer;
}

.text5 {
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button5 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.text6 {
  color: var(--Basic-White, #FFF);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button6 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Brand-600---main, #FA6F32);
}

.button6:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.buttonParent1 {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttonContainer {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.frameParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.privacyPolicy,
.termsOfService {
  color: #fa6f32;
}

.inputText22 {
  width: 600px;
  position: relative;
  line-height: 20px;
  display: none;
  color: #334155;
}

.component35Parent {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: -117px;
  width: 536px;
  height: 402px;
  object-fit: contain;
  display: none;
}

.inputText23,
.inputText24 {
  align-self: stretch;
  position: relative;
}

.inputText23 {
  line-height: 28px;
  font-weight: 600;
}

.inputText24 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextContainer {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 307px;
  left: -31px;
  width: 564px;
  height: 527px;
  object-fit: contain;
}

.theLogoMakerBuiltFor10001 {
  position: absolute;
  top: calc(50% - 575px);
  left: 88px;
  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.celesClubPVidiGruzovikhMaParent {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #cbd5e1;
  width: 376px;
  height: 1200px;
  overflow: hidden;
  font-size: 18px;
  color: #0f172a;
}

.registerATruckOrEdit3P {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #f8fafc;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 1rem;
  letter-spacing: normal;
}

.selectedTag {
  background-color: #feede5;
  border: 1px solid #fa6f32;
  color: #fa6f32;
}

@media (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media (max-width: 1000px) {
  .component35Parent {
    width: 100%;
  }

  .inputWithLabelParentContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
  }

  .buttonContainer {
    width: 100%;
    padding: 1rem;
    flex-direction: column;
  }

  .buttonParent1 {
    width: 100%;
    flex-direction: column;
  }

  .button5,
  .button6 {
    width: 100%;
  }

}
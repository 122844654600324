.unsplashx60ygfg5soyIcon {
  position: absolute;
  top: 34vh;
  right: -10vw;
  width: 924px;
  height: 616px;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}

.inputText {
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.unsplashx60ygfg5soyParent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e9f2f9;
  width: 376px;
  height: 1200px;
  overflow: hidden;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.inputText2,
.text {
  position: relative;
  line-height: 24px;
}

.text {
  font-weight: 600;
}

.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}

.vehicleOwner02Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
  object-fit: scale-down;
}

.stepcircle,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

}

.stepcircle {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: #fa6f32;
}

.instanceChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}

.dot {
  position: relative;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.stepcircle1,
.stepcircleWithText1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepcircle1 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.stepcircleWithText1 {
  justify-content: flex-start;
  gap: 16px;
}

.inputText3,
.userName {
  align-self: stretch;
  position: relative;
}

.userName {
  line-height: 32px;
  width: max-content;
  font-weight: 600;
}

.inputText3 {
  font-size: 14px;
  line-height: 20px;
  color: #334155;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 24px;
  color: #0f172a;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.inputField,
.inputText9 {
  font-size: 16px !important;
  height: 100%;
  width: 100% !important;
  line-height: 24px;
  padding: 10px;
}

.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.inputWithLabel,
.inputWithLabel1,
.inputWithLabelParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel1 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.inputWithLabelParent {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}

.inputText9 {
  line-height: 24px;
  font-size: 16px !important;
}

.iconoutlineeye {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.inputWithLabelWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.basicInput3,
.dropdownIcon {
  position: relative;
  height: 44px;
}

.dropdownIcon {
  border-radius: 8px 0 0 8px;
  width: 79px;
}

.basicInput3 {
  flex: 1;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  margin-left: -1px;
}

.frameContainer,
.phoneNumberInput {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px !important;
}

.phoneNumberInput {
  font-size: 16px;
  color: #0f172a;
}

.phoneNumberInput {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  color: #0f172a;
  width: 100% !important;
}

.phoneNumberInput>div {
  height: 44px;
}

.frameContainer {
  flex: 1;
  gap: 8px;
}

.inputText12 {
  flex: 1;
  position: relative;
  line-height: 16px;
}

.iconsolidcheckCircleParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.frameDiv {
  align-self: stretch;
  gap: 8px;
  font-size: 12px;
  color: #334155;
}

.body,
.frameDiv,
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.body {
  border-radius: 8px;
  background-color: #fff;
  width: 316px;
  overflow: hidden;
  gap: 8px;
  color: #0f172a;
}

.frameGroup,
.frameParent {
  align-self: stretch;
  gap: 16px;
}

.frameParent {
  font-size: 14px;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
  display: none;
}

.button1,
.inputText17 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.button1 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border: none;
  border-radius: 0.5rem;
  background-color: #FA6F32;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.button1:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}


.inputText17 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  width: 139px;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag,
.tagParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.tagParent {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer,
.textButtonGroup {
  align-self: stretch;
  justify-content: flex-start;
}

.inputTextContainer {
  display: none;
  flex-direction: row;
  align-items: center;
}

.textButtonGroup {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  font-size: 16px;
  color: #475569;
}

.termsOfService {
  color: #fa6f32;
}

.inputTextTxtContainer {
  width: 100%;
}

.inputText19 {
  align-self: stretch;
  color: #334155;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 1rem;
}

.backButtonParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #64748B;
  gap: 0.5rem;
}

.backButtonIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.backButtonText {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}


.vehicleOwner02 {
  background-color: #f8fafc;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-family: Inter, sans-serif;
}

.iconoutlineeye {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
}

.iconoutlineeye:hover {
  color: #fa6f32;
}

.signUpStep {
  color: #FA6F32;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}

@media screen and (max-width: 1250px) {
  .body {
    text-align: left;
  }

  .userName {
    width: unset;
  }

}

@media screen and (max-width: 700px) {
  .backButtonParent {
    padding: 0 1rem;
    width: 100%;
  }
  .reefer{
    margin-top: 16px;
  }
  .frameGroup,
  .frameParent {
    align-self: stretch;
    gap:0;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
    width: 100%;
  }

  .textButton {
    display: none;
  }

  .signUpStep {
    display: block;
  }

  .inputWithLabelParent,
  .frameContainer {
    display: block;
  }

  .frameGroup {
    height: unset;
  }

  .stepcircleWithText,
  .stepcircleWithText1 {
    gap: 5px;
    font-size: small;
  }
}
.promoBtn {
    display: flex;
    height: 2.75rem;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background-color: #FA6F32;
    border:none ;
}
.promoBtn:hover {
    cursor: pointer;
    background-color: #e0571a !important;
    transition: background-color 0.3s !important;
}
.promoText {
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    gap: 0.5rem;
    border-radius: 0.5rem;
}
@media (max-width: 540px) {
    .promoBtn {
        align-self: stretch;
    }
}
.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 292px;
  left: 0;
  width: 376px;
  height: 573px;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}

.inputText {
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 201px;
  left: 88px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3c85eb;
  width: 376px;
  height: 1200px;
  overflow: hidden;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.inputText2,
.text {
  position: relative;
  line-height: 24px;
}

.text {
  font-weight: 600;
}

.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}

.lookingForRent07Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.headerText {
  display: flex;
  width: 100%;
  padding: 2rem;
  align-items: center;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
  gap: 0.25rem;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}

.stepcircle,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textButton {
  justify-content: flex-start;
  gap: 8px;
}

.stepcircle {
  border-radius: 16px;
  background-color: #fa6f32;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.instanceChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}

.stepcircle2 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stepcircleWithText2,
.stepcircleWithTextParent,
.textButton1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.stepcircleWithText2 {
  display: flex;
  gap: 16px;
  color: #fa6f32;
}

.stepcircleWithTextParent,
.textButton1 {
  gap: 8px;
}

.stepcircleWithTextParent {
  display: flex;
  color: #0f172a;
}

.textButton1 {
  display: none;
  font-size: 14px;
  color: #64748b;
}

.userName {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.iconParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: #0f172a;
}

.button1,
.inputText3 {
  align-items: center;
  flex-shrink: 0;
}

.button1 {
  display: flex;
  height: 2.75rem;
  width: 100%;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border: none;
  border-radius: 0.5rem;
  background-color: #FA6F32;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.button1:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.inputText3 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  width: 139px;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextContainer,
.tag,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextContainer,
.tagParent {
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  align-self: stretch;
  display: none;
}

.textButtonGroup {
  display: flex;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.25rem;
  background: #FFF;
}

.frameWrapper {
  align-self: stretch;
  position: relative;
  height: 88px;
  font-size: 16px;
  color: #475569;
}

.iconGroup,
.textButton2 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.textButton2 {
  display: none;
  gap: 8px;
  color: #64748b;
}

.iconGroup {
  align-self: stretch;
  display: flex;
  gap: 4px;
  font-size: 16px;
  color: #0f172a;
}

.inputTextTxtContainer {
  width: 100%;
}

.inputField {
  position: relative;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 360px;
}

.k1gmailcom {
  font-weight: 600;
  font-family: Inter, sans-serif;
}

.weHaveSentAnEMailToK1g {
  margin-block-start: 0;
  margin-block-end: 8px;
}

.copyAndPaste {
  margin: 0;
}

.inputText7 {
  display: flex;
  align-items: center;
}

.inputTextWrapper {
  position: relative;
  width: 360px;
  height: 40px;
}

.button2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #0f172a;
}

.button {
  display: flex;
  border: none;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: #F1F5F9;
  color: #0F172A;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  background-color: #e2e8f0;
  height: 2px;
}

.inputText9 {
  font-size: 16px !important;
  position: absolute;
  height: 100%;
  left: 12px;
  line-height: 24px;
}

.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #0f172a;
}

.inputWithLabel {
  width: 382px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.button3 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
}

.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  color: #475569;
}

.button4,
.inputTextParent1 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.button4 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}

.inputTextParent1 {
  justify-content: flex-start;
  color: #475569;
}

.groupChild {
  position: absolute;
  top: calc(50% - 233px);
  left: 416px;
  border-radius: 20px;
  width: 160px;
  height: 126.88px;
}

.frameParent {
  align-self: stretch;
  position: relative;
  height: 594px;
  color: #334155;
}

.backButtonParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.signUpStep {
  color: #FA6F32;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}


@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }

  .textButton {
    width: 100%;
    float: left;
  }

}

@media screen and (max-width: 1000px) {
  .groupChild {
    display: none;
  }

  .inputField,
  .inputText7 {
    width: unset;
  }
}

@media (max-width: 700px) {
  .backButtonParent {
    padding: 0 1rem;
    width: 100%;
  }
  .inputText6{
    font-size: 16px !important;
  }

  .stepcircleWithTextParent {
    display: none;
  }

  .signUpStep {
    display: block;
  }

  .headerText {
    padding: 1.5rem 1rem;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
  }

  .button {
    width: 100%;
  }

  .button3 {
    width: 100%;
  }

  .inputWithLabel {
    width: unset;
  }

  .inputWithLabelParent {
    display: unset;
  }

  .basicInput {
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {

  .stepcircleWithText,
  .stepcircleWithText1,
  .stepcircleWithText2 {
    gap: 3px;
  }

  .userName {
    font-size: 20px;
  }
}
.loginWrapper {
  position: absolute;
  width: 100%;
}

.userName {
  position: absolute;
  top: 14px;
  left: 16px;
  line-height: 20px;
  font-weight: 600;
}
.iconsolidx {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.button {
  position: absolute;
  top: calc(50% - 18px);
  right: 12px;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
}
.frameChild,
.header {
  align-self: stretch;
  position: relative;
}
.header {
  background-color: #fff;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  background-color: #e2e8f0;
}
.text {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.textButton {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}
.inputText,
.userName1 {
  position: relative;
  font-weight: 600;
}
.userName1 {
  align-self: stretch;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
}
.inputText {
  line-height: 20px;
  display: none;
}
.inputText1 {
  width: 100% !important;
  height: 100%;
  padding-left: 16px;
  line-height: 24px;
  font-size: 16px !important;
}
.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 56px;
  font-size: 16px;
  color: #0f172a;
}
.inputWithLabel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.inputText3 {
  width: 100%;
  top: 16px;
  line-height: 24px;
}
.iconoutlineeye {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
}

.iconoutlineeye:hover {
  color: #fa6f32;
}
.basicInput1,
.inputText4 {
  align-self: stretch;
  position: relative;
  font-size: 16px;
}
.basicInput1 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 56px;
  color: #94a3b8;
}
.inputText4 {
  line-height: 24px;
  color: #334155;
}
.inputText4:hover {
  color: #fa6f32;
}

.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 14px;
}
.text1 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button1,
.inputText5 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.button1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}
.inputText5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  width: 139px;
}
.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.inputTextParent,
.tag,
.tagParent {
  flex-direction: row;
  align-items: center;
}
.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}
.inputTextParent,
.tagParent {
  justify-content: flex-start;
}
.tagParent {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}
.inputTextParent {
  align-self: stretch;
  display: none;
}
.dialogWindowNoRegister,
.rectangleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rectangleParent {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 30px 32px;
  gap: 32px;
  font-size: 16px;
}
.dialogWindowNoRegister {
  position: relative;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #475569;
  font-family: Inter;
}

.errorParent {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 10vh;
  width: 20vw;
  background-color: #ff15159e;
  border-radius: 16px;
  border: 0.5px solid lightgray;
  flex-direction: column;
}

.errorParent > * {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  margin-bottom: 0;
}

@media (max-width: 850px) {
  .dialogWindowNoRegister {
    box-shadow: unset;
    background-color: white;
    width: 100%;
  }
  .header {
    background-color: unset;
  }
}

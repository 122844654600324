.modalTitle{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #475569;
}

.formGroup {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 92%;
  align-items: center;
  margin-left: 4%;
}

.formGroup .col-6 {
  position: relative;
}

.formGroup .col-12 {
  padding-right: 30px; 
}

.formGroup label {
  float: left;
  display: flex;
  margin: 5px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.passwordLabel {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475569;
}

.btn {
  padding: 10px 20px;
  background-color: #FA6F32;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.btn:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.btnLight{
  background-color: #F1F5F9;
  color: #0F172A;
}

.btnLight:hover{
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
}

.btnRight{
  float: right; 
}

.horizontalLine{
  height: 1px;
  width: 94%;
  margin-bottom: 10px;
  background: #D9D9D9;
  margin-left: 3%;
}

@media (max-width: 478px) {
  .formGroup label {
    font-size: 12px;
    width: 100%;
  }
}

.navbar {
  width: 100%;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 3;
}

.parentContainer {
  width: 100%;
  padding: 0 13rem;
  background: #F8FAFC;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;

}

.titleContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.title {
  margin: 0;
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.subtitle {
  margin: 0;
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.mainGrid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
}

.dropdown {
  position: relative;
  display: none;
  height: 2.75rem;
  padding: 0.625rem 0.5rem 0.625rem 0.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #94A3B8;
  background-color: #FFF;
  color: #0F172A;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.dropdownList {
  position: absolute;
  top: 3.5rem;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #94A3B8;
  background-color: #FFF;
  width: 100%;
}

.dropdownOption {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.articlesContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.firstArticle {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.articleImage {
  width: 55%;
  height: 15.875rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.articleContentparent {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--white, #FFF);
}

.articleContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1 0 0;
  align-self: stretch;
}

.articleTitle {
  margin: 0;
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.articleDiscription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
}

.articleText {
  align-self: stretch;
  margin: 0;
  overflow: hidden;
  color: var(--Neutral-500, #64748B);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  max-height: calc(4 * 1.5rem);
}

.articleReadTime {
  margin: 0;
  align-self: stretch;
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.articlesGrid {
  display: grid;
  row-gap: 3rem !important;
  column-gap: 1.5rem !important;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background: var(--white, #FFF);
}

.cardImage {
  width: 100%;
  height: 13.8125rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  object-fit: cover;
}

.cardContentParent {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  background: var(--white, #FFF);
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
}

.cardTitle {
  width: 100%;
  margin: 0;
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.discription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.cardText {
  align-self: stretch;
  margin: 0;
  overflow: hidden;
  color: var(--Neutral-500, #64748B);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  max-height: calc(3 * 1.5rem);
}

.readTime {
  align-self: stretch;
  margin: 0;
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}


.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E2E8F0;
  width: 100;
  align-self: stretch;
}

.previousButton {
  display: flex;
  padding: 1rem 0.25rem 0rem 0rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  background: none;
  border: none;
  border-radius: 0.375rem;
  color: inherit;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
}

.buttonIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--Neutral-500, #64748B);
}

.buttonText {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.paginate {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nextButton {
  display: flex;
  padding: 1rem 0rem 0rem 0.25rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  background: none;
  border: none;
  border-radius: 0.375rem;
  color: inherit;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s;
}

.pagination button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  transition: color 0.3s;
}

.pagination button:hover {
  color: #f97316;
}

.pagination button:disabled {
  color: #d1d5db;
  cursor: not-allowed;
}

.pagination .active {
  color: #f97316;
  border-top: 2px solid #f97316;
}


.sidebar {
  display: flex;
  position: sticky;
  top: 7rem;
  z-index: 1;
  width: 100%;
  padding: 2rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.5rem;
  background: #FFF;
}

.searchContainer {
  position: relative;
  display: flex;
  height: 2.5rem;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-400, #94A3B8);
  background: var(--Basic-White, #FFF);
}

.searchInput {
  width: 100% !important;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1.8rem;
}

.searchIcon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.viewAllSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.viewAllTitle {
  margin: 0;
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.linkList {
  display: flex;
  padding-left: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  margin: 0;
}

.link {
  list-style-type: none;
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

@media only screen and (max-width: 1536px) {
  .parentContainer {
    padding: 0 8rem;
  }
}

@media only screen and (max-width: 1280px) {
  .parentContainer {
    padding: 0 4rem;
  }

  .container {
    padding: 2rem 0;
  }

  .mainGrid {
    flex-direction: column-reverse;
  }

  .sidebar {
    position: unset;
  }
}

@media (min-width: 768px) {
  .mainGrid {
    grid-template-columns: 2fr 1fr;
  }

  .articlesGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .parentContainer {
    padding: 1rem;
  }

  .container {
    padding: 0;
  }

  .titleContainer {
    display: none;
  }

  .mainGrid {
    flex-direction: column;
  }

  .firstArticle {
    display: none;
  }

  .dropdown {
    display: flex;
  }

  .sidebar {
    display: none;
  }

  .pagination {
    padding-top: 1rem;
  }

  .paginate {
    display: none;
  }

  .previousButton,
  .nextButton {
    border: 1px solid #D1D5DB !important;
  }

  .buttonIcon {
    display: none;
  }
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 292px;
  left: 545px;
  width: 614px;
  height: 573px;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}

.inputText {
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 201px;
  left: 88px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3c85eb;
  width: 376px;
  height: 1200px;
  overflow: hidden;
  display: none;
  font-size: 18px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.inputText2,
.text {
  position: relative;
  line-height: 24px;
}

.text {
  font-weight: 600;
}

.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  color: #334155;
}

.vehicleOwner07Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}

.textButton {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.icon1 {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}

.userName {
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.iconParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 20px;
}

.inputText3,
.inputText4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.inputText4 {
  display: none;
}

.inputTextContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #334155;
}

.button1 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.button1,
.button2,
.buttonParent {
  display: flex;
  flex-direction: row;
}

.button2 {
  flex: 1;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}

.buttonParent {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.inputField {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 139px;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag,
.tagParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.tagParent {
  flex: 1;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.frameDiv,
.modal,
.tagParent {
  justify-content: flex-start;
}

.frameDiv {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  color: #475569;
}

.modal {
  display: flex;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.25rem;
  background: #FFF;
}

.vehicleOwner07 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media (max-width: 650px) {
  .vehicleOwner07 {
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
  }

  .modal {
    padding: 1.5rem 1rem;
  }

  .buttonParent {
    flex-direction: column;
  }
}
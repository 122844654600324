.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.iconoutlinebell {
  position: relative;
  border-radius: 10px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.errorMesage {
  width: 100%;
  background-color: #fc8e8e;
  border-radius: 16px;
  height: 10vh;
  color: white;
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.errorMesage>span {
  height: auto;
  margin: auto;
  font-size: 17px;
}

.button1 {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}

.photo,
.text1 {
  position: relative;
}

.photo {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2,
.button3 {
  height: 44px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.button2 {
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  gap: 8px;
}

.button3 {
  border-radius: 0 8px 8px 0;
  width: 44px;
  flex-shrink: 0;
  padding: 10px;
}

.button3,
.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
}

.registerATruckOrEdit4PChild {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}

.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.inputText {
  position: relative;
  line-height: 20px;
  color: #334155;
  display: none;
}

.backButtonParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #317fd1;
  width: 149px;
  height: 24px;
}

.rectangleWrapper {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  height: 10px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.inputText1,
.rectangleWrapper,
.userName {
  align-self: stretch;
  position: relative;
}

.userName {
  line-height: 32px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 24px;
  color: #0f172a;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.addImage1 {
  font-weight: 600;
}

.addImage1,
.dragAndDrop {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageParent,
.iconoutlinedocumentParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addImageParent {
  align-self: stretch;
  align-items: flex-start;
}

.iconoutlinedocumentParent {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  align-items: center;
  padding: 32px;
  gap: 4px;
}

.addImage,
.frameContainer,
.inputTextParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.addImage {
  gap: 2%;
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  text-align: center;
  color: #64748b;
}

.frameContainer,
.inputTextParent {
  flex-direction: column;
  gap: 4px;
}

.frameContainer {
  gap: 16px;
}

.inputText4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 139px;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag,
.tagParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.tagParent {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer,
.textButtonGroup {
  align-self: stretch;
  justify-content: flex-start;
}

.inputTextContainer {
  display: none;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.textButtonGroup {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.button4,
.button6 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.button4 {
  display: none;
}

.button6 {
  display: flex;
  cursor: pointer;
  color: #94a3b8;
}

.buttonParent1 {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.buttonContainer {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  justify-content: flex-end;
  padding: 16px 24px;
  gap: 32px;
  font-size: 16px;
  color: #0f172a;
}

.frameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameGroup {
  align-self: stretch;
  gap: 16px;
  color: #475569;
}

.frameParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.unsplashx60ygfg5soyIcon {
  position: absolute;
  top: 34vh;
  right: -10vw;
  width: 924px;
  height: 616px;
  object-fit: cover;
}

.inputText6 {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.inputTextParent1 {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.unsplashx60ygfg5soyParent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e9f2f9;
  width: 376px;
  height: 1200px;
  overflow: hidden;
  font-size: 18px;
  color: #000;
}

.registerATruckOrEdit4P {
  background-color: #f8fafc;
  width: 100%;
  height: 1510px;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter, sans-serif;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}


@media (max-width: 700px) {
  .frameParent {
    padding: 0 1rem;
    width: 100%;
  }

  .buttonParent1,
  .textButtonGroup {
    padding: 1.5rem 1rem;
  }

  .button1 {
    width: 100%;
  }
}
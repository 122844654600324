.text {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 17px 8px 20px;
}
.footerssimpleLinkWrapper,
.linksInner {
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.linksInner {
  height: 24px;
  justify-content: flex-end;
}
.text1 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 18px 8px 20px;
}
.footerssimpleLinkContainer,
.linksChild {
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.linksChild {
  height: 24px;
  justify-content: flex-end;
}
.text2 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 17px 8px 20px;
}
.footerssimpleLinkFrame,
.frameDiv {
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}
.frameDiv {
  height: 24px;
  justify-content: flex-end;
}
.container1,
.links {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.links {
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}
.container1 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 21px;
  box-sizing: border-box;
}
.footerssocialIcon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon1 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon2 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon3 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon4 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.footersocialLinks,
.footerssocialLink4 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialLink4 {
  display: flex;
}
.footersocialLinks {
  display: none;
  gap: 24px;
}
.copyright {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #9ca3af;
  text-align: center;
}
.container,
.footer {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.container {
  width: 1284px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 32px;
  box-sizing: border-box;
  gap: 32px;
}
.footer {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  font-size: 16px;
  color: #6b7280;
  font-family: Inter;
}
@media screen and (max-width: 750px) {
  .links {
    flex-wrap: wrap;
  }
  .container {
    gap: 16px;
  }
}

@media screen and (max-width: 550px) {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .text,
  .text1,
  .text2,
  .copyright {
    font-size: 12px;
  }
  .footerssimpleLink {
    padding: 0px 5px;
  }
}

@media screen and (max-width: 385px) {
  .text,
  .text1,
  .text2,
  .copyright {
    font-size: 12px;
  }
  .footerssimpleLink,
  .footerssimpleLinkWrapper,
  .container1 {
    padding: unset;
    width: 100%;
  }
}

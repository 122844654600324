.subscriptionPlanContainer {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.freeSubscriptionPlan {
    display: flex;
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    background: #FFF;
}
.freeSubscriptionPlanHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.freeSubscriptionPlanHeaderText {
    display: flex;
    height: 3.5rem;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FEEDE5;
    color: #D97706;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.subscriptionPrice {
    display: flex;
    padding: 0.875rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.subscriptionPriceTitle {
    align-self: stretch;
    color: #111827;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}
.subscriptionPriceSubtitle {
    align-self: stretch;
    color: #64748B;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.planDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    height:17rem;
}
.planDetailHeader {
    display: flex;
    padding: 0.625rem 0;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}
.planDetailHeaderText {
    color: #334155;
    flex: 1 0 0;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}
.planDetailsDescription {
    display: flex;
    padding: 0.625rem 0;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}
.checkMark {
    width: 1.25rem;
    height: 1.25rem;
}
.planDescription {
    flex: 1 0 0;
    align-self: stretch;
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

@media (max-width: 1288px) {
    .subscriptionPlanContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
    }
}
@media (max-width: 850px) {
    .subscriptionPlanContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
    }
}
@media (max-width: 540px) {
    .subscriptionPlanContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
    }
}


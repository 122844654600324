@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --desktop-headline-h5-text-xl: Inter;
  --font-open-sans: "Open Sans";

  /* font sizes */
  --body-text-base-font-semibold-size: 16px;
  --font-size-4xs: 9px;
  --body-text-sm-font-normal-size: 14px;
  --desktop-headline-h5-text-xl-size: 20px;
  --body-text-xs-font-semibold-size: 12px;

  /* Colors */
  --neutral-white-secondary-background: #fff;
  --neutral-100: #f1f5f9;
  --neutral-200: #e2e8f0;
  --neutral-900: #0f172a;
  --brand-500: #fb814c;
  --brand-600-main: #fa6f32;
  --color-gainsboro-100: #ddd;
  --color-gainsboro-200: #d9d9d9;
  --neutral-600: #475569;
  --neutral-700: #334155;
  --neutral-500: #64748b;
  --brand-50: #feede5;
  --neutral-400: #94a3b8;
  --color-black: #000;
  --neutral-300: #cbd5e1;
  --color-orange: #ffba0a;
  --color-red: #fe0000;
  --status-rose-600: #e11d48;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-xs: 12px;
  --gap-9xs: 4px;
  --gap-9xl: 28px;
  --gap-7xs: 6px;
  --gap-lg: 18px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-7xs: 6px;
  --padding-5xs: 8px;
  --padding-11xs: 2px;
  --padding-5xl: 24px;
  --padding-45xl: 64px;
  --padding-xs: 12px;
  --padding-4xs: 9px;
  --padding-xl: 20px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-21xl: 40px;
  --br-7xs: 6px;
  --br-3xs: 10px;
  --br-81xl: 100px;
  --br-9xs: 4px;
  --br-31xl: 50px;

  /* Effects */
  --elevation-level-4: 0px 20px 50px rgba(18, 27, 33, 0.2);
  --elevation-level-3: 0px 10px 22px rgba(45, 77, 108, 0.15);
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.disabledButton {
  pointer-events: none;
  color: gray;
  text-decoration: line-through;
}

.featureContainer {
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FFF;
}
.featureHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 0.88rem;
}
.featureTitle {
    display: flex;
    width: 19.375rem;
    height: 3.375rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.featureTitleText {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    color: #0F172A;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.detailFreePlan {
    display: flex;
    padding: 0.25rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
}
.detailFreePlanButton {
    display: flex;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FEEDE5;
    flex: 1 0 0;
    color: #DC4705;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.detailFreePlanPrice {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    height: 5.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.featurePlanPrice {
    color: #DC4705;
}
.featurePlanDuration {
    color: #64748B;
}
.detailPlanBtn {
    display: flex;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #F1F5F9;
    white-space: nowrap;
    color: #0F172A;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    cursor: pointer;
}
.featureRowHeader {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid #E2E8F0;
}

.tableFeature {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: #64748B;
}
.tableCheckMark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    padding: 0.25rem 0.5rem 0.25rem 2rem;
}
.tableFeatureContent {
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.newTag {
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.4375rem;
    border-radius: 1rem;
    background: #FEDBCC;
}
.newTagText {
    color: #DC4705;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}
.commingSoonTag {
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.25rem;
    align-items: center;
    gap: 0.4375rem;
    border-radius: 1rem;
    background: #E2E8F0;
}
.commingSoonTagText {
    color: #64748B;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}
@media (max-width: 1288px) {
    .featureTitle {
        display: flex;
        /*width: 25%;*/
        height: 3.375rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .detailFreePlan {
        padding: 0.125rem 0.25rem;
    }
    .detailFreePlanButton {
        display: flex;
        padding: 0.75rem 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 0.5rem;
        background: #FEEDE5;
        flex: 1 0 0;
        color: #DC4705;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
    }
    .detailFreePlanPrice {
        display: flex;
        flex-direction: column;
        padding: 0.125rem 0.5rem;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        height: 5.25rem;
        align-self: stretch;
        border-radius: 0.5rem;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
    }
    .detailPlanBtn {
        display: flex;
        height: 2rem;
        padding: 0.375rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.375rem;
        align-self: stretch;
        border-radius: 0.5rem;
        background: #F1F5F9;
        white-space: nowrap;
        color: #0F172A;
        font-family: Inter, sans-serif;
        font-size: 0.675rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        cursor: pointer;
    }

    .tableFeature {
        display: flex;
        padding: 0.75rem 1rem;
        align-items: center;
        align-self: stretch;
        flex: 1 0 0;
        font-family: Inter, sans-serif;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        color: #64748B;
    }
    .tableCheckMark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
        flex: 1 0 0;
        align-self: stretch;
        padding: 0.25rem 0.5rem 0.25rem 2rem;
    }
    .tableFeatureContent {
        color: #64748B;
        font-family: Inter, sans-serif;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .newTag {
        display: none;
    }
    .commingSoonTag {
        display: none;
    }
}

@media (max-width: 900px) {
    .featureContainer {
        display: flex;
        overflow-x: auto; /* Enables horizontal scrolling */
        width: 100%;
    }

    .featureHeader {
        display: flex;
        width: max-content; /* Ensures it expands beyond container width */
        min-width: 100%; /* Prevents it from shrinking */
        overflow-x: auto; /* Allow horizontal scrolling inside container */
        white-space: nowrap; /* Prevents wrapping */
    }

    .featureTitle {
        display: flex;
        height: 3.375rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}


.parentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: #FFF;
}

.container {
  display: flex;
  width: 100%;
  padding: 4rem 10rem;
  flex-direction: column;
  align-items: flex-start;
}

.content {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.commercialVehicles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.divider {
  width: 0.0625rem;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--Neutral-300, #CBD5E1);
}

.popularCities {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  flex-shrink: 0;
}

.sectionTitle {
  margin: 0;
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.04375rem;
  text-transform: uppercase;
}

.section {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.subSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.subTitle {
  align-self: stretch;
  margin: 0;
  color: var(--gray-400, #9CA3AF);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.04375rem;
  text-transform: uppercase;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.list li {
  display: flex;
  width: 13.6875rem;
  align-items: center;
  color: var(--gray-500, #6B7280);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.grid {
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1rem;
  column-gap: 2rem;
  color: #4B5563;
}

.grid li {
  display: flex;
  width: 13.6875rem;
  align-items: center;
  color: var(--gray-500, #6B7280);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}


@media only screen and (max-width: 1665px) {
  .container {
    padding: 4rem 8rem;
  }

  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1385px) {
  .container {
    padding: 4rem 6rem;
  }

  .list li,
  .grid li {
    width: 10rem;
  }
}

@media only screen and (max-width: 1111px) {
  .container {
    padding: 4rem 4rem;
  }
}

@media only screen and (max-width: 1050px) {
  .content {
    width: 100%;
    flex-direction: column;
  }

  .divider {
    display: flex;
    width: 100%;
    height: 0.0625rem;
  }

  .list li,
  .grid li {
    width: 13.6875rem;
  }
}

@media only screen and (max-width: 850px) {

  .list li,
  .grid li {
    width: 10.6875rem;
  }
}

@media only screen and (max-width: 705px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 3rem 1rem;
  }
}

@media only screen and (max-width: 440px) {
  .section {
    flex-direction: column;
  }

  .list li,
  .grid li {
    width: 9rem;
  }
}

.layoutContainer {
  height: 100vh;
  width: 100%;
  background-color: #F8FAFC;
}

.navbarContainer {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.contentContainer {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebarContainer {
  height: 100vh;
}

.mainContent {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 5rem;
  padding: 5rem 15rem
}

@media (max-width: 1299px) {
  .sidebarContainer {
    display: none;
  }

  .mainContent {
    padding: 5rem 10rem;
  }
}

@media (max-width: 699px) {
  .mainContent {
    padding: 0;
  }
}

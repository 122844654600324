
.subscriptionHeader {
    display: flex;
    justify-content: flex-start;
    margin: 144px auto;
}
.parentContainer {
    display: flex;
    align-items: flex-start;
}
.parentDiv {
    background-color: #F8FAFC;
    display: flex;
    padding: 3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    flex: 1 0 0;
}
.subscriptionSidebar {
    display: inline-flex;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    flex-direction: column;
    align-items: flex-start;
}
.subscriptionNavbar {
    display: none;
}
.subscriptionContainer {
    display: flex;
    padding: 6rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
}
.subscriptionInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}
.upgradeContainer {
    color: #111827;
    font-family: Inter, sans-serif;
    text-align: center;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;
}
.billingContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
}
.monthyBillContainer {
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.monthlyBillParentContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
}
.annualBill {
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}
.saveContainer {
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.4375rem;
    border-radius: 1rem;
    background-color: #FEDBCC;
}
.save {
    color: #DC4705;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}
.inputLabel {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}
.inputDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #94A3B8;
    background-color: #FFF;
    height: 2.75rem;
    padding: 0.75rem 0 0.75rem 0.75rem;
    align-self: stretch;
}
.inputField{
    font-size: 16px !important;
}
.subscriptionPlanContainer {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}
.freeSubscriptionPlan {
    display: flex;
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    background: #FFF;
}
.freeSubscriptionPlanHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.freeSubscriptionPlanHeaderText {
    display: flex;
    height: 3.5rem;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FEEDE5;
    color: #D97706;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.subscriptionPrice {
    display: flex;
    padding: 0.875rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.subscriptionPriceTitle {
    align-self: stretch;
    color: #111827;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
}
.subscriptionPriceSubtitle {
    align-self: stretch;
    color: #64748B;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}
.planDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    height:17rem;
}
.planDetailHeader {
    display: flex;
    padding: 0.625rem 0;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}
.planDetailHeaderText {
    color: #334155;
    flex: 1 0 0;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}
.planDetailsDescription {
    display: flex;
    padding: 0.625rem 0;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}
.checkMark {
    width: 1.25rem;
    height: 1.25rem;
}
.planDescription {
    flex: 1 0 0;
    align-self: stretch;
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.featureContainer {
    display: flex;
    padding: 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    /*gap: 0.625rem;*/
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FFF;
}
.featureHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 0.88rem;
}
.featureRowHeader {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-top: 1px solid #E2E8F0;
}
.featureTitle {
    display: flex;
    width: 19.375rem;
    height: 3.375rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.featureTitleText {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    color: #0F172A;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.detailFreePlan {
    display: flex;
    padding: 0.25rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
}
.detailFreePlanButton {
    display: flex;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #FEEDE5;
    flex: 1 0 0;
    color: #DC4705;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.detailFreePlanPrice {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0.5rem;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    height: 5.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.featurePlanPrice {
    color: #DC4705;
}
.featurePlanDuration {
    color: #64748B;
}
.detailPlanBtn {
    display: flex;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background: #F1F5F9;
    white-space: nowrap;
    color: #0F172A;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    cursor: pointer;
}
.planDetailsTableContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.planDetailsTableHeader {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}
.planDetailTableColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tableBorder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 0.0625rem;
    background: #E2E8F0;
}
.planDetailTableColumnText {
    display: flex;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.planFreeCheckmarkContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.planFreeCheckmark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.planFreeCheckmarkContent {
    display: flex;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    align-self: stretch;
}
.checkmarkSvg {
    width: 1.25rem;
    height: 1.25rem;
}
.tableCheckMark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    padding: 0.25rem 0.5rem 0.25rem 2rem;
}
.tableFeature {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: #64748B;
}
.tableFeatureContent {
    color: #64748B;
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}
.newTag {
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.4375rem;
    border-radius: 1rem;
    background: #FEDBCC;
}
.newTagText {
    color: #DC4705;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}
.commingSoonTag {
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.25rem;
    align-items: center;
    gap: 0.4375rem;
    border-radius: 1rem;
    background: #E2E8F0;
}
.commingSoonTagText {
    color: #64748B;
    text-align: right;
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
}

.tableContainer {
    overflow-x: scroll;
    display: block;
}

@media (max-width: 1288px){
    .newTag {
        display: none;
    }
    .commingSoonTag {
        display: none;
    }
    .subscriptionPlanContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
    }
    .tableFeature {
        display: flex;
        padding: 0.75rem 1rem;
        align-items: center;
        align-self: stretch;
        flex: 1 0 0;
        font-family: Inter, sans-serif;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        color: #64748B;
    }
    .tableCheckMark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
        flex: 1 0 0;
        align-self: stretch;
        padding: 0.25rem 0.5rem 0.25rem 2rem;
    }
    .tableFeatureContent {
        color: #64748B;
        font-family: Inter, sans-serif;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .detailFreePlanPrice {
        display: flex;
        flex-direction: column;
        padding: 0.125rem 0.5rem;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        height: 5.25rem;
        align-self: stretch;
        border-radius: 0.5rem;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
    }
    .detailFreePlanButton {
        display: flex;
        padding: 0.75rem 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 0.5rem;
        background: #FEEDE5;
        flex: 1 0 0;
        color: #DC4705;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
    }
    .detailPlanBtn {
        display: flex;
        height: 2rem;
        padding: 0.375rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 0.375rem;
        align-self: stretch;
        border-radius: 0.5rem;
        background: #F1F5F9;
        white-space: nowrap;
        color: #0F172A;
        font-family: Inter, sans-serif;
        font-size: 0.675rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        cursor: pointer;
    }
    .featureTitle {
        display: flex;
        width: 25%;
        height: 3.375rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .detailFreePlan {
        padding: 0.125rem 0.25rem;
    }
}
@media (max-width: 1024px){
    .featureContainer {
        display: none;
    }
}
@media (max-width: 800px){
    .subscriptionSidebar {
        display: none;
    }
    .subscriptionNavbar{
        display: block;
    }
}
@media (max-width: 540px){
    .billingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        flex-direction: column;
    }
    .monthlyBillParentContainer {
        display: flex;
        align-items: center;
        gap: 0.625rem;
    }
    .inputLabel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        width:100%
    }
    .inputDiv {
        display: flex;
        flex-direction: column;
        height: 2.75rem;
        padding: 0 0 0 0;
        align-items: center;
        align-self: stretch;
        border-radius: 0.5rem;
        border: 1px solid #94A3B8;
        background: #FFF;
    }
    .upgradeContainer{
        color: #111827;
        font-family: Inter, sans-serif;
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.5rem;
    }
    .subscriptionPlanContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
    }
    .parentDiv {
        background-color: #F8FAFC;
        display: flex;
        padding: 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        flex: 1 0 0;
    }
}


.closeBtn:hover {
    cursor: pointer;
    background-color: #e0571a !important;
    transition: background-color 0.3s !important;
}
.closeBtnText {
    color: #FFF;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    gap: 0.5rem;
}
.modalFooter {
    margin-top: 10px;
    text-align: right;
}
.openBtn {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

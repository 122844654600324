.vehicleTypes {
  padding: 40px 10px;
  text-align: center;
}

.sectionTitle {
  font-size: 36px;
  color: #0f172a;
  font-weight: 600;
  margin-bottom: 16px;
}

.sectionDescription {
  font-size: 18px;
  color: #64748b;
  max-width: 600px;
  margin: 0 auto 48px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.vehicleCard {
  width: calc(25% - 24px);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vehicleImage {
  width: 100%;
  height: auto;
}

.vehicleTitle {
  font-size: 20px;
  color: #0f172a;
  margin: 16px 0 8px;
}

.vehiclePrice {
  font-size: 16px;
  color: #94a3b8;
  margin-bottom: 16px;
}

.cardImage {
  transition: transform 0.3s ease-in-out;
}

.card:hover .cardImage {
  filter: brightness(110%);
}

.card {
  transition: transform 0.4s ease-in-out;

  transform: translateY(0%);
}

.card:hover {
  transition: transform 0.4s ease-in-out;

  transform: translateY(-5%);
}

.cardParent:hover {
  background-color: white !important;
}

.cardContent,
.cardContainer:hover {
  background-color: white !important;
}

.cardParent:hover .cardContent div {
  color: #fa6f32;
}

.showAllCard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f5f9;
  border-radius: 8px;
  cursor: pointer;
}

.showAllButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #3c85eb;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.showAllCard:hover .showAllButton {
  color: #fa6f32;
}

.arrowIcon {
  margin-left: 8px;
}

/* @media (max-width: 991px) {
  .vehicleCard,
  .showAllCard {
    width: calc(50% - 24px);
  }
} */

@media (max-width: 700px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);

  }
}

@media (max-width: 576px) {
  .vehicleCard,
  .showAllCard {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .cardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  .sectionTitle {
    font-size: xx-large;
  }
}

.sidebarContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #E9F2F9;
    height: 100%;
    width: 24rem;
    font-family: Inter;
}

.textContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 2rem;
    width: 100%;
    padding: 0 3rem 0 5.4234rem;
    margin-top: 52%;
    font-family: Inter;
    font-style: normal;
    z-index: 1;
}

.title {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
}

.description {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
}

.image {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 39rem;
    object-fit: cover;
    flex-shrink: 0;
}

.theLogoMakerBuiltFor1000 {
  width: 149.5px;
  position: relative;
  height: 24px;
  object-fit: cover;
  cursor: pointer;
}
.icon {
  position: absolute;
  width: 75%;
  top: calc(50% - 7px);
  right: 4%;
  left: 21%;
  max-width: 100%;
  overflow: hidden;
  height: 14px;
}
.iconoutlinearrowHideChild {
  position: absolute;
  height: 79%;
  width: 8.5%;
  top: 8.5%;
  right: 83%;
  bottom: 12.5%;
  left: 8.5%;
  border-radius: 30px;
  background-color: #94a3b8;
}

.progressBar > span {
  background-color: #fb9365;
}
.iconoutlinearrowHide {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.homeIcon,
.text {
  position: relative;
}
.homeIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text {
  line-height: 20px;
}
.content,
.verticalNavigationitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content {
  gap: 0 12px;
}
.verticalNavigationitem {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinebookOpen {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text1 {
  position: relative;
  line-height: 20px;
}
.content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
}
.iconsolidchevronDown {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.verticalNavigationitem1 {
  align-self: stretch;
  width: 240px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
}
.component28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #475569;
}
.iconoutlinecurrencyDollar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text2 {
  position: relative;
  line-height: 20px;
}
.content2,
.verticalNavigationitem2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content2 {
  gap: 0 12px;
}
.verticalNavigationitem2 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechartSquareBar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text3 {
  position: relative;
  line-height: 20px;
}
.content3,
.verticalNavigationitem3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content3 {
  gap: 0 12px;
}
.verticalNavigationitem3 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechartBar {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text4 {
  position: relative;
  line-height: 20px;
}
.content4,
.verticalNavigationitem4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content4 {
  gap: 0 12px;
}
.verticalNavigationitem4 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinechatAlt2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text5 {
  position: relative;
  line-height: 20px;
}
.content5,
.verticalNavigationitem5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content5 {
  gap: 0 12px;
}
.verticalNavigationitem5 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.navigationChild {
  align-self: stretch;
  position: relative;
  border-radius: 30px;
  background-color: #f1f5f9;
  height: 2px;
}
.iconoutlinechat {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text6 {
  position: relative;
  line-height: 20px;
}
.content6,
.verticalNavigationitem6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content6 {
  gap: 0 12px;
}
.verticalNavigationitem6 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinequestionMarkCir {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text7 {
  position: relative;
  line-height: 20px;
}
.content7,
.verticalNavigationitem7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.content7 {
  gap: 0 12px;
}
.verticalNavigationitem7 {
  align-self: stretch;
  border-radius: 6px;
  padding: 8px 12px 8px 8px;
}
.iconoutlinecog {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text8 {
  position: relative;
  line-height: 20px;
}
.content8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
}
.iconsolidchevronDown1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.verticalNavigationitem8 {
  align-self: stretch;
  width: 240px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  box-sizing: border-box;
}
.component30,
.leadingContent,
.navigation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.component30 {
  flex-direction: row;
  color: #475569;
}
.leadingContent,
.navigation {
  flex-direction: column;
}
.navigation {
  align-self: stretch;
  padding: 0 8px;
  gap: 4px 0;
}
.leadingContent {
  width: 256px;
  padding: 20px 0 16px;
  box-sizing: border-box;
  gap: 20px 0;
}
.iconoutlinelightningBolt {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.text9 {
  position: relative;
  line-height: 20px;
}
.content9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
}
.text10 {
  position: relative;
  line-height: 16px;
  font-weight: 600;
}
.tag,
.verticalNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag {
  border-radius: 16px;
  background-color: #fa6f32;
  height: 28px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 4px 8px;
  box-sizing: border-box;
  text-align: right;
  font-size: 12px;
  color: #fff;
}
.verticalNavigation {
  align-self: stretch;
  border-radius: 6px;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  cursor: pointer;
}
.topBorder {
  align-self: stretch;
  position: relative;
  background-color: #e5e7eb;
  height: 1px;
}
.avatarIcon {
  width: 36px;
  border-radius: 18px;
  height: 36px;
  object-fit: cover;
}
.supportingText,
.title {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.supportingText {
  width: 68px;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  display: none;
}
.avatarWithText,
.text11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatarWithText {
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
}
.iconoutlineselector {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.content10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.footer,
.simpleWithIconsBadgesAnd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer {
  width: 256px;
  align-items: center;
  cursor: pointer;
  color: #374151;
}
.simpleWithIconsBadgesAnd {
  height: 100vh;
  background-color: #fff;
  align-items: flex-start;
}
.text12 {
  position: relative;
  line-height: 32px;
  font-weight: 600;
}
.heyThereThis {
  /* width: 586px; */
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #94a3b8;
  white-space: pre-wrap;
  display: inline-block;
}
.textParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0;
}
.iconoutlinecog1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text13 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button {
  border-radius: 8px;
  background-color: #e2e8f0;
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
}
.text14 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button1 {
  border-radius: 8px;
  background-color: #e2e8f0;
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
}
.iconsolidplus {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text15 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button2 {
  border-radius: 8px;
  background-color: #3c85eb;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #fff;
}
.buttonParent,
.headerDashPage {
  flex-direction: row;
  justify-content: flex-start;
}
.buttonParent {
  display: none;
  align-items: flex-start;
  gap: 0 8px;
  font-size: 14px;
}
.headerDashPage {
  align-self: stretch;
  display: flex;
  align-items: flex-end;
  gap: 0 16px;
}
.creditsUsed {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.creditsUsedWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0;
}
.iconoutlineusers {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.numberCard {
  align-self: stretch;
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 0 16px;
}
.creditsUsed1 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.span {
  color: #94a3b8;
}
.div1 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameChild {
  position: absolute;
  width: 66%;
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
  height: 8px;
  overflow: hidden;
}
.numberCardInner {
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
}
.ofCreditsUsed {
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
}
.numberCard1 {
  align-self: stretch;
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 4px 0;
}
.creditsUsed2 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div2 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameItem,
.frameWrapper {
  height: 8px;
  overflow: hidden;
}
.frameItem {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
}
.frameWrapper {
  width: 158px;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  flex-shrink: 0;
  display: none;
}
.ofCreditsUsed1 {
  width: 111px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
  display: none;
}
.frameContainer,
.numberCardParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  flex: 1;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  flex-direction: column;
  padding: 24px 16px;
  gap: 4px 0;
}
.numberCardParent {
  flex-direction: row;
  gap: 0 8px;
}
.creditsUsed3 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div3 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameInner {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
  height: 8px;
  overflow: hidden;
}
.frameWrapper1 {
  width: 158px;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.ofCreditsUsed2 {
  width: 111px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
  display: none;
}
.frameDiv {
  width: 256px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 16px;
  gap: 4px 0;
}
.div4,
.div5 {
  position: absolute;
  top: 14px;
  left: 20px;
  line-height: 24px;
}
.div5 {
  top: 62px;
  font-size: 14px;
  line-height: 20px;
}
.frameChild1,
.vectorIcon {
  position: absolute;
  top: -24px;
  left: 357px;
  width: 171px;
  height: 160.5px;
  object-fit: contain;
  opacity: 0.3;
}
.frameChild1 {
  top: 12px;
  left: 388px;
}
.parent {
  align-self: stretch;
  width: 35%;
  position: relative;
  border-radius: 8px;
  background-color: #3c85eb;
  border: 1px solid #3c85eb;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 16px;
  color: #fff;
}
.instanceParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 8px;
}
.div6,
.div7 {
  position: absolute;
  top: 14px;
  left: 20px;
  line-height: 24px;
}
.div7 {
  top: 62px;
  font-size: 14px;
  line-height: 20px;
  color: #3c85eb;
}
.frameChild2,
.frameChild3 {
  position: absolute;
  top: -24px;
  left: 493.6px;
  width: 171px;
  height: 160.5px;
  object-fit: contain;
  opacity: 0.3;
}
.frameChild3 {
  top: 12px;
  left: 524.6px;
}
.group {
  align-self: stretch;
  width: 520px;
  position: relative;
  border-radius: 8px;
  background-color: #f8fafc;
  border: 1px solid #3c85eb;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsed4 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.creditsUsedParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 4px;
}
.div8 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #1e293b;
}
.frameChild4 {
  position: absolute;
  width: calc(100% - 44px);
  top: 0;
  right: 44px;
  left: 0;
  background-color: #fb9365;
  height: 8px;
  overflow: hidden;
}
.frameWrapper2 {
  width: 158px;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.ofCreditsUsed3 {
  width: 111px;
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: #94a3b8;
  display: none;
}
.frameParent2 {
  width: 256px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 4px 0;
  font-size: 14px;
  color: #64748b;
}
.frameParent,
.frameParent1,
.frameParent2 {
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent1 {
  width: 784px;
  display: none;
  flex-direction: row;
  gap: 0 8px;
  font-size: 16px;
  color: #000;
}
.frameParent {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  gap: 8px 0;
  font-size: 14px;
  color: #64748b;
}
.completeTheRegistration {
  align-self: stretch;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.heyThereThis1 {
  width: 586px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #94a3b8;
  white-space: pre-wrap;
  display: inline-block;
}
.completeTheRegistrationBefoParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px 0;
}
.rectangleDiv {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.div9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.frameChild5 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fb9365;
  width: 16%;
  height: 8px;
  overflow: hidden;
}
.frameWrapper3 {
  flex: 1;
  position: relative;
  border-radius: 10px;
  background-color: #feede5;
  height: 8px;
  overflow: hidden;
}
.container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 16px;
  font-size: 16px;
  color: #1e293b;
}
.iconsolidcheck {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.stepcircleWithNumbers {
  width: 32px;
  border-radius: 16px;
  background-color: #fa6f32;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.details,
.title1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.title1 {
  font-weight: 600;
}
.details {
  color: #64748b;
}

.title1,
.title3,
.details1 {
  text-align: left;
}

.content11,
.stepcircleWithNumbersWith {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.stepcircleWithNumbersWith {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.text16 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 40px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #475569;
}
.stepcircleWithNumbersWithParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 16px;
  color: #0f172a;
}
.dot,
.title2 {
  position: relative;
}
.title2 {
  line-height: 20px;
  font-weight: 600;
}
.dot {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  display: none;
}
.stepcircleWithNumbers1 {
  width: 32px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title3 {
  font-weight: 600;
}
.details1,
.title3 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.content12,
.stepcircleWithNumbersWith1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.content12 {
  flex-direction: column;
  justify-content: center;
  color: #64748b;
}
.stepcircleWithNumbersWith1 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
}
.icon1,
.text17 {
  position: relative;
}
.text17 {
  line-height: 20px;
  font-weight: 600;
}
.icon1 {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button4,
.stepcircleWithNumbersWithGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #475569;
}
.stepcircleWithNumbersWithGroup {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 16px;
}
.dot1,
.title4 {
  position: relative;
}
.title4 {
  line-height: 20px;
  font-weight: 600;
}
.dot1 {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  display: none;
}
.stepcircleWithNumbers2 {
  width: 32px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title5 {
  font-weight: 600;
}
.details2,
.title5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.content13,
.stepcircleWithNumbersWith2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.content13 {
  flex-direction: column;
  justify-content: center;
  color: #64748b;
}
.stepcircleWithNumbersWith2 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.text18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconsolidarrowSmRight {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button5,
.stepcircleWithNumbersWithContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button5 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #475569;
}
.stepcircleWithNumbersWithContainer {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.dot2,
.title6 {
  position: relative;
}
.title6 {
  line-height: 20px;
  font-weight: 600;
}
.dot2 {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  display: none;
}
.stepcircleWithNumbers3 {
  width: 32px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title7 {
  font-weight: 600;
}
.details3,
.title7 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.content14,
.stepcircleWithNumbersWith3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.content14 {
  flex-direction: column;
  justify-content: center;
  color: #64748b;
}
.stepcircleWithNumbersWith3 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.text19 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconsolidarrowSmRight1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button6,
.stepcircleWithNumbersWithParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button6 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #475569;
}
.stepcircleWithNumbersWithParent1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.dot3,
.title8 {
  position: relative;
}
.title8 {
  line-height: 20px;
  font-weight: 600;
}
.dot3 {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  display: none;
}
.stepcircleWithNumbers4 {
  width: 32px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title9 {
  font-weight: 600;
}
.details4,
.title9 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.content15,
.stepcircleWithNumbersWith4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.content15 {
  flex-direction: column;
  justify-content: center;
  color: #64748b;
}
.stepcircleWithNumbersWith4 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.text20 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button7 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  color: #475569;
}
.stepcircleWithNumbersWithParent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.dot4,
.title10 {
  position: relative;
}
.title10 {
  line-height: 20px;
  font-weight: 600;
}
.dot4 {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  display: none;
}
.stepcircleWithNumbers5 {
  width: 32px;
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title11 {
  font-weight: 600;
}
.details5,
.title11 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.content16,
.stepcircleWithNumbersWith5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.content16 {
  flex-direction: column;
  justify-content: center;
  color: #64748b;
}
.stepcircleWithNumbersWith5 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.text21 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconsolidarrowSmRight2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.button8,
.stepcircleWithNumbersWithParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button8 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 0 6px;
  color: #475569;
}
.stepcircleWithNumbersWithParent3 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 0 16px;
  align-items: center;
}
.frameParent3,
.frameParent4,
.headerDashPageParent,
.mainPage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent4 {
  align-self: stretch;
  flex-direction: column;
  gap: 24px 0;
  font-size: 14px;
  color: #94a3b8;
}
.frameParent3,
.headerDashPageParent,
.mainPage {
  overflow: hidden;
}
.frameParent3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  padding: 32px 24px;
  gap: 32px 0;
  font-size: 20px;
}
.headerDashPageParent,
.mainPage {
  background-color: #f8fafc;
}
.headerDashPageParent {
  flex: 1;
  flex-direction: column;
  padding: 48px 48px;
  gap: 16px 0;
  font-size: 24px;
  color: #0f172a;
}
.mainPage {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow: auto;
  font-size: 14px;
  color: #4b5563;
  font-family: Inter;
}

.adminNavBar {
  display: none;
}

.adminSideBar {
  display: block;
  position: sticky;
  top: 0;
}

@media (max-width: 1050px) {
  .headerDashPageParent {
    padding: 44px 44px;
  }
  .frameParent,
  .div4,
  .div5 {
    font-size: small;
  }
  .completeTheRegistration {
    font-size: large;
  }
  .frameParent4 {
    font-size: small;
  }
}

@media (max-width: 800px) {
  .frameParent4 {
    font-size: x-small;
  }
  .adminNavBar {
    display: block;
  }
  .adminSideBar {
    display: none;
  }
}

@media (max-width: 540px) {
  .headerDashPageParent,
  .numberCard,
  .numberCard1,
  .frameContainer,
  .frameDiv {
    padding: 8px;
  }
  .frameParent {
    padding: 10px;
  }
  .frameDiv {
    width: unset;
  }
  .frameParent,
  .div4,
  .div5 {
    font-size: xx-small;
  }
  .div4,
  .div5,
  .details1,
  .title3 {
    line-height: inherit;
  }
  .heyThereThis {
    font-size: small;
  }
}

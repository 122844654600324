.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 2px 0px;
  margin: 0px 16px 0px 24px;
  font-size: 16px;
}

.modalTitle {
  font-size: 16px;
}

.closeButton {
  width: 20px;
  height: auto;
  transform: scale(1.1);
  transition: transform 0.3s;
}

.closeButton:hover {
  cursor: pointer;
  filter: brightness(4.9);
  transform: scale(1.2);
}

.modalBody {
  padding-left: 24px;
  height: 80vh;
  overflow: auto;
}

.inputText {
  position: absolute;
  height: 100%;
  left: 12px;
  line-height: 24px;
}
.basicInput {
  flex: 1;
  position: relative;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.iconoutlinesearch {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.inputLeftContent {
  position: absolute;
  height: 100%;
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput1 {
  flex: 1;
  position: relative;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  overflow: hidden;
  margin-left: -1px;
}
.basicInputParent {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 220px);
  width: 439px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  z-index: 0;
}
.iconoutlinebell {
  position: relative;
  border-radius: 10px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}
.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}
.photo,
.text1 {
  position: relative;
}
.photo {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}
.button2 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}
.button3,
.buttonGroup {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}
.button3 {
  border-radius: 0 8px 8px 0;
  width: 44px;
  height: 44px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 2;
  font-size: 12px;
  color: #334155;
}
.frameChild {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 152px;
  border-radius: 40px;
  background-color: #fe0000;
  border: 1px solid #f1f5f9;
  box-sizing: border-box;
  width: 9px;
  height: 9px;
  z-index: 3;
}
.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #fff;
}
.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}
.frameParent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.alreadyHaveAccount,
.component12 {
  position: absolute;
  top: 0;
  left: 64px;
  width: 1472px;
  height: 84px;
  font-size: 16px;
}
.component12 {
  top: 1413px;
  height: 80px;
}
.iconsolidchevronLeft {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.button10,
.button8,
.button9 {
  position: absolute;
  top: 32px;
  left: 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  height: 56px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
}
.button10,
.button9 {
  top: 0;
  left: 0;
  border-radius: 8px 8px 0 0;
}
.button10 {
  top: 56px;
  border-radius: 0 0 8px 8px;
}
.groupDiv,
.image76Icon {
  position: absolute;
  top: 32px;
  left: 681px;
  width: 56px;
  height: 112px;
}
.image76Icon {
  top: 733px;
  left: 0;
  width: 801px;
  height: 26px;
  object-fit: cover;
}
.component13,
.component14 {
  position: absolute;
  overflow: hidden;
}
.component13 {
  top: 421px;
  left: 316px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
}
.component14 {
  top: 556px;
  left: 175px;
}
.component14,
.component15,
.component16 {
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
}
.component15 {
  position: absolute;
  top: 556px;
  left: 499px;
  overflow: hidden;
}
.component16 {
  top: 308px;
  left: 510px;
}
.button11,
.buttonParent1,
.component16 {
  position: absolute;
  overflow: hidden;
}
.buttonParent1 {
  top: 179px;
  left: 814px;
  width: 786px;
  height: 759px;
  background-image: url(/public/generics/frame-2394@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 16px;
  color: #000;
}
.button11 {
  top: 203px;
  left: 687px;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 6px;
  color: #0f172a;
}
.image75Icon {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.headline {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.accordion,
.image75Parent {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.image75Parent {
  flex: 1;
  justify-content: flex-start;
}
.accordion {
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  width: 200px;
  justify-content: center;
  padding: 16px;
}
.frameInner {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  width: 3px;
}
.accordion1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  width: 200px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.headline6 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
}
.accordionParent {
  position: absolute;
  top: 1494px;
  left: 0;
  background-color: #f1f5f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 64px;
  gap: 16px;
  font-size: 16px;
  color: #0f172a;
}
.rectangleDiv {
 display: none;
}
.parentRectangleDiv {
  align-self: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;

}
.typeTrucksIcon {
  position: relative;
  width: 66px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.typeTrucksParent {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.frameChild1 {
  align-self: stretch;
  position: relative;
  background-color: #0f172a;
  height: 2px;
}
.frameContainer {
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
}
.accordionframe2434 {
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  color: #0f172a;
}
.frameChild2 {
  align-self: stretch;
  position: relative;
  background-color: #cbd5e1;
  width: 2px;
}
.image75Icon9 {
  position: relative;
  width: 24px;
  height: 24px;
  object-fit: cover;
  opacity: 0.5;
}
.frameChild3 {
  align-self: stretch;
  position: relative;
  background-color: #64748b;
  height: 1px;
  display: none;
}
.image75Parent7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.accordion9,
.accordionframe2434Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.accordion9 {
  height: 45px;
  align-items: flex-start;
}
.accordionframe2434Parent {
  background-color: #fff;
  width: 1440px;
  align-items: center;
  padding: 24px 64px;
  box-sizing: border-box;
  gap: 48px;
}
.button12,
.rectangleParent {
  position: absolute;
  display: flex;
}
.rectangleParent {
  top: 84px;
  left: 0;
  width: 1600px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.button12 {
  top: 100px;
  left: 1441px;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 56px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
  color: #0f172a;
}
.ellipseDiv,
.frameChild14 {
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  width: 8px;
  height: 8px;
}
.frameChild14 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  object-fit: cover;
}
.premium {
  position: relative;
  line-height: 140%;
}
.badgesOnCard {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent2 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #ddd;
  height: 157px;
}
.vecicleCardrectangle1271Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle1271 {
  position: relative;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.materialSymbolsstarIcon {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.materialSymbolsstarParent {
  gap: 4px;
}
.frameWrapper,
.materialSymbolsstarParent {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconoutlinetruck {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  object-fit: cover;
  display: none;
}
.k,
.mileage {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage {
  left: 38px;
}
.iconoutlinetruckParent,
.pointMapIcon {
  position: relative;
  width: 90px;
  height: 20px;
}
.pointMapIcon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.milesAway {
  position: relative;
  line-height: 20px;
}
.toledoOhio43612Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #64748b;
}
.span {
  font-weight: 600;
}
.component31Inner,
.dayWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.dayWrapper {
  flex-shrink: 0;
  align-items: flex-start;
}
.component31Inner {
  align-self: stretch;
  align-items: center;
}
.component31,
.vecicleCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component31 {
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.vecicleCard {
  flex: 1;
  align-items: center;
  gap: 12px;
}
.vecicleCardGroup,
.vecicleCardParent {
  position: absolute;
  top: 263px;
  left: 64px;
  width: 722px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  color: #fff;
}
.vecicleCardGroup {
  top: 615px;
}
.resultsFound {
  position: absolute;
  top: 211px;
  left: 66px;
  line-height: 150%;
  color: #000;
}
.alreadyHaveAccountParent,
.mapPageFilterChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 2075px;
  height: 1598px;
}
.mapPageFilterChild {
  background-color: #0f172a;
  width: 1600px;
  height: 3682px;
  opacity: 0.3;
}
.userName {
  position: absolute;
  top: 14px;
  left: 32px;
  line-height: 20px;
  font-weight: 600;
}
.button13 {
  position: absolute;
  top: calc(50% - 18px);
  right: 12px;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
}
.header {
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 14px;
  color: #475569;
}
.frameChild29 {
  position: relative;
  background-color: #d9d9d9;
  height: 1px;
}
.userName1,
.userName2 {
  display: inline-block;
}
.userName1 {
  line-height: 28px;
  font-weight: 600;
}
.userName2 {
  font-size: 16px;
  line-height: 24px;
  color: #64748b;
}
.userNameParent {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.image80Icon {
  position: relative;
  width: 806px;
  height: 109px;
  object-fit: cover;
  display: none;
}
.inputText6 {
  position: relative;
  line-height: 24px;
  color: #94a3b8;
}
.inputLeftContent2 {
  position: absolute;
  height: 100%;
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.inputWithPrefixessuffixes {
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #64748b;
}
.inputWithLabel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.div8 {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 3%;
}
.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;
}
.frameParent6,
.frameWrapper4 {
  justify-content: center;
}
.frameWrapper4 {
  align-self: stretch;
  font-size: 14px;
  color: #475569;
}
.frameParent6 {
  gap: 32px;
  font-size: 20px;
}
.inputText11 {
  margin-left: 0 !important;
  width: 200px;
  top: 0;
  left: 0;
  line-height: 24px;
  align-items: center;
  text-align: left;
  float: left;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

.earnRevenueFromYourIdleFl {
  width: 32px;
  height: 32px;
  object-fit: cover;
  display: none;
}
.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 550;
  font-size: 14px;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl3 {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.inputTextParent,
.tag3,
.tagParent {
  align-items: center;
}
.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: none;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}
.tagParent {
  /*flex: 1;*/
  flex-shrink: 0;
  gap: 8px;
  text-align: right;
  font-size: 14px;
  color: #475569;
}
.inputTextParent {
  gap: 24px;
}
.frameChild30 {
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.rectangleDiv2 {
  display:flex;
  align-self: stretch;
  position: relative;
  background-color:#E2E8F0;
  height: 0.0625rem;
}
.inputText12 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 139px;
  flex-shrink: 0;
}
.inputTextGroup,
.tag4,
.tagGroup {
  align-items: center;
}
.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.tagGroup {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}
.inputTextGroup {
  display: none;
  color: #475569;
}
.userName3 {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
}
.iconsolidx1 {
  position: relative;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.tag11,
.tag9 {
  border-radius: 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 8px 8px;
  box-sizing: border-box;
  gap: 4px;
}
.tag9 {
  background-color: #feede5;
  border: 1px solid #fa6f32;
  flex-direction: row;
  align-items: center;
}
.tag11 {
  background-color: #f1f5f9;
  color: #475569;
  padding: 6px 16px 6px 16px;
}
.inputTextParent1,
.tag11,
.tagParent1 {
  align-items: center;
  /*justify-content: center;*/
  text-align: center;
}
.tagParent1 {
  font-size: 14px;
  color: #fa6f32;
}
.inputTextParent1 {
  /*gap:0.5rem;*/
  /*gap: 24px;*/
}
.basicInputContainer,
.frameParent8,
.inputTextParent1 {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}
.basicInputContainer {
  width: 271px;
  flex-direction: row;
  align-items: flex-start;
}
.frameParent8 {
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #94a3b8;
}
.frameParent8,
.tag27,
.tagParent7,
.tagParent8 {
  align-items: center;
}
.tagParent7 {
  flex-shrink: 0;
  display: none;
  justify-content: flex-start;
  gap: 8px;
}
.tag27,
.tagParent8 {
  height: 44px;
}
.tag27 {
  border-radius: 8px;
  background-color: #feede5;
  border: 1px solid #fa6f32;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  color: #fa6f32;
}
.tagParent8 {
  gap: 8px;
}
.frameParent7,
.inputTextParent7 {
  flex-shrink: 0;
}
.frameParent7 {
  flex-direction: column;
  gap: 8px;
  text-align: right;
  font-size: 14px;
  color: #475569;
}
.inputTextParent7 {
  flex-direction: row;
  gap: 24px;
}
.inputText39 {
  position: absolute;
  width: 100%;
  top: -50%;
  left: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.userNameGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
}
.inputText40 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}
.checkbox1 {
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.checkbox,
.checkbox6 {
  border-radius: 6px;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox {
  display: flex;
}
.checkbox6 {
  display: none;
}
.checkboxParent {
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 16px;
  color: #0f172a;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}
.inputTextParent11 {
  flex: 1;
  flex-direction: column;
  gap: 12px;
}
.frameParent11,
.inputTextParent11,
.userNameContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent11 {
  align-self: stretch;
  flex-shrink: 0;
  flex-direction: row;
  gap: 24px;
  font-size: 14px;
  color: #475569;
}
.userNameContainer {
  flex-direction: column;
  gap: 16px;
  font-size: 20px;
}
.inputText44,
.userName5 {
  position: relative;
  font-weight: 600;
}
.userName5 {
  font-size: 20px;
  line-height: 28px;
  color: #0f172a;
  display: inline-block;
}
.inputText44 {
  align-self: stretch;
  line-height: 24px;
}
.inputText45 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.inputTextParent15 {
  height: 68px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.toggleOnIcon {
  position: absolute;
  height: 100%;
  right: 2px;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.on {
  position: absolute;
  top: 9px;
  left: 9px;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
  opacity: 0.8;
}
.toggle {
  position: relative;
  border-radius: 40px;
  background-color: #fb814c;
  width: 48px;
  height: 28px;
  font-size: 9px;
  color: #fff;
  font-family: "Open Sans";
}
.frameParent12 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.userNameParent1 {
  color: #475569;
}
.frameChild33 {
  align-self: stretch;
  position: relative;
  background-color: #d9d9d9;
  height: 1px;
}
.rectangleGroup {
  gap: 32px;
}
.button14,
.button15,
.button16 {
  top: 15px;
  border-radius: 8px;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}
.button14 {
  left: 708px;
  background-color: #fa6f32;
  color: #fff;
}
.button15,
.button16 {
  background-color: #f1f5f9;
}
.button15 {
  gap: 8px;
}
.footer {
  height: 70px;
}
.dialogWindow,
.footer,
.mapPageFilter {
  background-color: #fff;
  overflow: hidden;
}
.dialogWindow {
  position: absolute;
  top: 5vh;
  border-radius: 20px;
  box-shadow: 0 20px 50px rgba(18, 27, 33, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #0f172a;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0%);
}
.mapPageFilter {
  position: absolute;
  z-index: 10000;
  background-color: #0f172a7a;
  width: 100%;
  height: 2263px;
  text-align: left;
  font-size: 14px;
  color: #94a3b8;
  font-family: Inter;
}

/* Inputs */

.check-box > .checkbox1 {
  appearance: unset !important;
  border: unset !important;
}

.checobox1:checked {
  background-color: transparent;
}

.toggle input[type="checkbox"] {
  position: relative;
  width: inherit;
  height: inherit;
  background: #ccc;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
}

.toggle input:checked[type="checkbox"] {
  background: white;
}

.toggle input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 3em;
  height: inherit;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}

.toggle input:checked[type="checkbox"]::after {
  left: 50%;
}

input[type="checkbox"] {
  appearance: none !important; /* Hide the default checkbox */
}

input[type="radio"] {
  appearance: none !important; /* Hide the default checkbox */
}

input[type="checkbox"]:checked + label {
  background-color: red !important;
  /* Add any other styles you want for the checked state */
}

.checkbox1 {
  border-radius: 6px !important;
}

.checkbox1:hover {
  cursor: pointer;
}

.checkbox2 {
  content: unset !important;
}

.checkbox2:checked {
  background: #fb814c !important;
}

input[type="checkbox"]:checked + label {
  border: 1px solid your-checked-border-color;
  color: your-checked-text-color;
}

.tagParent > *,
.tagParent8 > *,
.tagParent1 > * {
  transition: background-color 0.3s, filter 0.3s !important;
  cursor: pointer !important;
}

.tagParent > *:hover,
.tagParent8 > *:hover,
.tagParent1 > *:hover {
  filter: brightness(90%) !important;
}

input[type="number"],
input[type="text"] {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #64748b;
  font-family: Inter;
}

.dollarSign {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: medium;
  color: gray;
}

.moneyField {
  padding-left: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

.tagParent8 {
  margin-top: -16px;
}

@media (max-width: 1000px) {
  .basicInputParent {
    width: 300px;
  }
}

@media (max-width: 992px) {
  .inputText11 {
    width: 65px;
  }
  .basicInputContainer {
    width: 200px;
  }
  .checkboxParent {
    font-size: small;
  }
  .frameChild30 {
    display: none;
  }
  .rectangleDiv2 {
    display: none;
  }
  .rectangleDiv {
    display:flex;
    align-self: stretch;
    position: relative;
    background-color: #D9D9D9;
    height: 0.0625rem;
  }
  .parentRectangleDiv {
    align-self: stretch;
    margin-top: 2rem;
    margin-bottom: 2rem;

  }
}

@media (max-width: 992px) {
  .inputTextParent1 {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .toggle input[type="checkbox"]::after {
    width: 2em;
    top: -1px;
  }

  .tag11 {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .inputTextParent7 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inputTextParent7 > div {
    display: flex;
    justify-content: center;
  }

  .tagParent8 {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: relative;
  }

  .tagParent {
    flex-wrap: wrap;
  }

  .tag11 {
    /*height: auto;*/
    /*width: 100%;*/
    height: 45px;
    padding: 10px 20px 10px 10px;
  }

  .inputTextParent1 > div {
    display: flex;
    height: auto;
    /*flex-direction: column;*/
    /*justify-content: center;*/
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .inputText11 {
    width: unset;
    /*text-align: center;*/
    /*margin-left: 0 !important;*/

    color: #0F172A;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  .frameParent11 {
    flex-wrap: wrap;
  }

  .iconsolidx1 {
    right: 0;
    position: relative;
    border-radius: 10px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }

  .reefer {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .reefer {
    font-size: 14px;
  }
  .tagParent {
    flex-wrap: wrap;
  }
  .tag11 {
    padding: 10px 30px 10px 10px;
    height: 45px;
  }

}

.gapContainer {
  display:flex;
  gap:0.5rem;
  margin-bottom:0.625rem;
}
.parentGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
  gap: 10px; /* Adjust gap as needed */
}

/* For screens up to 992px: 3 columns */
@media (max-width: 992px) {
  .parentGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* For screens up to 450px: 2 columns */
@media (max-width: 450px) {
  .parentGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 292px;
  left: 0;
  width: 376px;
  height: 573px;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}

.inputText {
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 201px;
  left: 88px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #3c85eb;
  width: 376px;
  height: 100%;
  overflow: hidden;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  padding: 10px 16px;
  box-sizing: border-box;
  justify-content: center;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}

.lookingForRent04Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}

.stepcircle,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textButton {
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.stepcircle {
  border-radius: 16px;
  background-color: #fa6f32;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.componentChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}

.stepcircle1,
.stepcircleWithText1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepcircle1 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.stepcircleWithText1 {
  justify-content: flex-start;
  gap: 16px;
  color: #fa6f32;
}

.dot {
  position: relative;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.stepcircle2 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stepcircleWithText2,
.stepcircleWithTextParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.stepcircleWithText2 {
  gap: 16px;
  color: #64748b;
}

.stepcircleWithTextParent {
  gap: 8px;
}

.inputText3,
.userName {
  align-self: stretch;
  position: relative;
  text-align: left;
}

.userName {
  line-height: 32px;
  width: max-content;
  font-weight: 600;
}

.inputText3 {
  font-size: 14px;
  line-height: 20px;
  color: #334155;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 24px;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.inputField {

  z-index: 10;
  /* position: absolute; */
  width: 90%;
  height: 100%;
  /* left: 12px; */
  line-height: 24px;
}

.iconsolidcheveronDown {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}

.dropdown {
  font-size: 16px !important;
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  color: #94a3b8;
}

.inputWithLabel {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.frameWrapper,
.inputWithLabel,
.inputWithLabel1,
.inputWithLabelParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel1 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.frameWrapper,
.inputWithLabelParent {
  align-self: stretch;
}

.inputWithLabelParent {
  flex-direction: row;
  gap: 8px;
}

.frameWrapper {
  flex-direction: column;
  font-size: 14px;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
  display: none;
}

.checkbox1 {
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.textDescription {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 24px;
  column-gap: 10px;
}

.checkbox {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #0f172a;
}

.button1,
.inputText8 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.button1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 16px;
  box-sizing: border-box;
  justify-content: center;
  color: #94a3b8;
}

.inputText8 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  width: 139px;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  font-size:16px !important;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag,
.tagParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  padding: 6px 16px;
  justify-content: center;
  gap: 4px;
}

.tagParent {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer,
.textButtonGroup {
  align-self: stretch;
  justify-content: flex-start;
}

.inputTextContainer {
  display: none;
  flex-direction: row;
  align-items: center;
}

.textButtonGroup {
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  gap: 32px;
  font-size: 16px;
  color: #475569;
}

.inputText13 {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 24px;
}

.basicInput1 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #64748b;
}

.checkboxIcon {
  position: relative;
  border-radius: 6px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.textDescription1 {
  position: relative;
  line-height: 24px;
  cursor: pointer;
}

.button2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.backButtonParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.lookingForRent04 {
  background-color: #f8fafc;
  width: 100%;
  height: 1200px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #fff;
  font-family: Inter, sans-serif;
}

.signUpStep {
  color: #FA6F32;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}

@media (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }

  .textButton {
    width: 100%;
    float: left;
  }

  .userName {
    width: unset;
  }
}

@media (max-width: 700px) {
  .backButtonParent {
    padding: 0 1rem;
  }

  .inputField {
    font-size: 16px !important;
  }
  .stepcircleWithTextParent {
    display: none;
  }

  .signUpStep {
    display: block;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
  }

}

@media (max-width: 500px) {
  .inputWithLabelParent {
    display: unset;
  }

  .inputField {

    z-index: 1;
  }

  .inputText2 {
    display: none;
  }

  .stepcircleWithText,
  .stepcircleWithText1,
  .stepcircleWithText2 {
    gap: 3px;
  }

  .userName {
    font-size: 20px;
  }
}

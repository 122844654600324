.photosContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.singleImage {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.twoImages {
  display: flex;
  width: 100%;
  height: 50vh;
  border-radius: 10px;
}

.imageHalf {
  width: 50%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.imageHalfForTwo {
  margin-right: 5px;
}

.threeImages {
  display: flex;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  border-radius: 10px;
}

.imageLeft {
  width: 50%;
  height: 100%;
  object-fit: cover;
  margin-right: 5px;
}

.imageRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.imageQuarter {
  width: 100%;
  object-fit: cover;
}

.imageQuarterForThree {
  margin-bottom: 5px;
  height: 25vh;
}

.imageOverlayContainer {
  position: relative;
  width: 100%;
  height: 50%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1;
}

.frameChild,
.photosChild {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.inRent {
  color: #fa6f32;
}
.frameItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 232.5px;
  object-fit: cover;
}
.photos1 {
  position: absolute;
  top: 102.5px;
  left: 125px;
  line-height: 28px;
  font-weight: 600;
}
.rectangleGroup {
  width: 350px;
  position: relative;
  height: 232.5px;
}
.photos,
.rectangleParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.rectangleParent {
  width: 350px;
  flex-direction: column;
}
.photos {
  border-radius: 8px;
  height: 477px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.peterbilt579 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle1271 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #64748b;
}
.peterbilt579Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 20px;
}
.rent {
  position: relative;
  line-height: 150%;
}
.component21 {
  border-radius: 4px;
  background-color: #fa6f32;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
}
.div,
.rent1 {
  position: relative;
}
.div {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #64748b;
}
.rent1 {
  line-height: 150%;
}
.component22 {
  border-radius: 4px;
  background-color: #fa6f32;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
}
.div1,
.sale {
  position: relative;
}
.div1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #64748b;
}
.sale {
  line-height: 150%;
}
.component19,
.component21Parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.component19 {
  border-radius: 4px;
  background-color: #14ae5c;
  align-items: flex-start;
  padding: 2px 6px;
}
.component21Parent {
  align-items: flex-end;
  gap: 4px;
  color: #fff;
}
.heyThere {
  white-space: pre-wrap;
}
.heyThere,
.whetherYoureMoving {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.soHitMe {
  margin: 0;
}
.heyThereThisContainer,
.updatedTueJune {
  position: relative;
  line-height: 20px;
  color: #000;
}
.heyThereThisContainer {
  align-self: stretch;
}
.updatedTueJune {
  width: 586px;
  display: inline-block;
}
.frameInner {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.about {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.earnRevenueFromYourIdleFl {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.bodyType {
  text-transform: capitalize;
}
.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl1 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag1 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl2 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag2,
.tagParent {
  display: flex;
  align-items: flex-start;
}
.tag2 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.tagParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.earnRevenueFromYourIdleFl3 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag3 {
  width: 247px;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl4 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.frameGroup,
.tag4,
.tagGroup {
  display: flex;
  align-items: flex-start;
}
.tag4 {
  width: 247px;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.frameGroup,
.tagGroup {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.frameGroup {
  flex-direction: column;
  text-align: right;
  color: #475569;
}
.inputText {
  width: 48px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}
.checkboxIcon {
  width: 20px;
  position: relative;
  border-radius: 6px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.5;
}
.textDescription {
  position: relative;
  line-height: 24px;
}
.checkbox {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox2 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription1 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox4 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription2 {
  position: relative;

  line-height: 24px;
}
.checkbox3 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox6 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription3 {
  position: relative;
  line-height: 24px;
}
.checkbox5 {
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkboxParent,
.inputTextParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.checkboxParent {
  font-size: 16px;
  color: #0f172a;
}
.inputTextParent {
  flex: 1;
}
.inputText1 {
  width: 48px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}
.checkbox8 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription4 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox7 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox10 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription5 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox9 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox12 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription6 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox11 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox14 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription7 {
  position: relative;
  line-height: 24px;
}
.checkbox13 {
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkboxGroup,
.inputTextGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.checkboxGroup {
  font-size: 16px;
  color: #0f172a;
}
.inputTextGroup {
  flex: 1;
}
.inputText2 {
  width: 48px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}
.checkbox16 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription8 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox15 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox18 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription9 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox17 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox20 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription10 {
  position: relative;
  text-decoration: line-through;
  line-height: 24px;
}
.checkbox19 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkbox22 {
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription11 {
  position: relative;
  line-height: 24px;
}
.checkbox21 {
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.checkboxContainer {
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  color: #0f172a;
}
.checkboxContainer,
.frameContainer,
.inputTextContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputTextContainer {
  flex: 1;
  flex-direction: column;
  gap: 12px;
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
  color: #475569;
}
.rectangleDiv {
  align-self: stretch;
  background-color: #e2e8f0;
  height: 1px;
}
.general,
.rectangleDiv,
.text {
  position: relative;
}
.general {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.text {
  line-height: 20px;
}
.tables {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text1 {
  position: relative;
  line-height: 20px;
}
.tables1 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text2 {
  position: relative;
  line-height: 20px;
}
.tables2 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text3 {
  position: relative;
  line-height: 20px;
}
.tables3 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text4 {
  position: relative;
  line-height: 20px;
}
.tables4 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text5 {
  position: relative;
  line-height: 20px;
}
.tables5,
.tablesParent {
  display: flex;
  justify-content: flex-start;
}
.tables5 {
  align-self: stretch;
  background-color: #f8fafc;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.tablesParent {
  flex-direction: column;
  align-items: flex-start;
}
.text6 {
  position: relative;
  line-height: 20px;
}
.tables6 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text7 {
  position: relative;
  line-height: 20px;
}
.tables7 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text8 {
  position: relative;
  line-height: 20px;
}
.tables8 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text9 {
  position: relative;
  line-height: 20px;
}
.tables9 {
  align-self: stretch;
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text10 {
  position: relative;
  line-height: 20px;
}
.tables10 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.text11 {
  position: relative;
  line-height: 20px;
}
.frameDiv,
.tables11,
.tablesGroup {
  display: flex;
  justify-content: flex-start;
}
.tables11 {
  align-self: stretch;
  background-color: #f8fafc;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.frameDiv,
.tablesGroup {
  align-items: flex-start;
}
.tablesGroup {
  flex: 1;
  flex-direction: column;
}
.frameDiv {
  align-self: stretch;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  flex-direction: row;
}
.chassis {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.text12 {
  position: relative;
  line-height: 20px;
}
.tables12,
.tablesWrapper {
  display: flex;
  justify-content: flex-start;
}
.tables12 {
  align-self: stretch;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
}
.tablesWrapper {
  width: 135px;
  flex-direction: column;
  align-items: flex-start;
}
.text13 {
  position: relative;
  line-height: 20px;
}
.tables13 {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 20px;
}
.frameParent,
.frameParent1,
.tablesContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tablesContainer {
  flex: 1;
  flex-direction: column;
}
.frameParent,
.frameParent1 {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
}
.frameParent1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #e2e8f0;
  flex-direction: row;
}
.frameParent {
  background-color: #fff;
  flex-direction: column;
  padding: 32px 24px;
  gap: 32px;
}
.usageRestrictions {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.earnRevenueFromYourIdleFl5 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer5 {
  position: relative;
  text-decoration: line-through;
  line-height: 20px;
  font-weight: 600;
}
.tag5 {
  width: 261px;
  border-radius: 8px;
  background-color: #feede5;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl6 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer6 {
  position: relative;
  text-decoration: line-through;
  line-height: 20px;
  font-weight: 600;
}
.tag6 {
  width: 261px;
  border-radius: 8px;
  background-color: #ecfdf5;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.tagContainer,
.usageRestrictionsParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tagContainer {
  width: 800px;
  flex-direction: row;
  gap: 8px;
  text-align: right;
  font-size: 14px;
  color: #475569;
}
.usageRestrictionsParent {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 24px;
  gap: 32px;
  font-size: 20px;
}
.rates {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.earnRevenueFromYourIdleFl7 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag7 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl8 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag8 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.earnRevenueFromYourIdleFl9 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}
.reefer9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tag9 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
  text-align: left;
}
.frameWrapper,
.ratesParent,
.tagParent1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.tagParent1 {
  flex-direction: row;
  gap: 8px;
}
.frameWrapper,
.ratesParent {
  flex-direction: column;
}
.frameWrapper {
  text-align: right;
  font-size: 14px;
  color: #475569;
}
.ratesParent {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  gap: 32px;
  font-size: 20px;
}
.calculator,
.inputText3 {
  position: relative;
  font-weight: 600;
}
.calculator {
  font-size: 20px;
  line-height: 28px;
  color: #0f172a;
}
.inputText3 {
  line-height: 20px;
}
.iconoutlinecurrencyDollar {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText4 {
  position: relative;
  line-height: 24px;
}

.datePicker {
  display: none;
}
.inputLeftContent {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.inputText5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.inputText6 {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 24px;
}
.iconsolidchevronDown {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.basicInput1 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.inputText7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecurrencyDollar1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText8 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent1 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput2 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.inputText9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecurrencyDollar2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText10 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent2 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput3 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel3,
.inputWithLabelGroup {
  display: flex;
  justify-content: flex-start;
}
.inputWithLabel3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.inputWithLabelGroup {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.allCalculationsAre {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.text14 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button {
  width: 183px;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
}
.allCalculationsArePresumedParent,
.calculatorParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.allCalculationsArePresumedParent {
  gap: 12px;
  color: #64748b;
}
.calculatorParent {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  gap: 32px;
  color: #475569;
}
.ownerAbout {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.at {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 11px);
  line-height: 24px;
  font-weight: 600;
}
.atWrapper {
  width: 50px;
  position: relative;
  border-radius: 40px;
  background-color: #2dd4bf;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}
.div2,
.owner {
  position: relative;
  line-height: 20px;
  color: #000;
}
.associatedWithRoybam {
  position: relative;
  text-decoration: underline;
  line-height: 20px;
}
.iconoutlinequestionMarkCir {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.associatedWithRoybamParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  color: #1e293b;
}
.rivipCrownLineIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium {
  position: relative;
  line-height: 150%;
}
.component20,
.frameParent2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.component20 {
  border-radius: 4px;
  background-color: #ffba0a;
  align-items: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent2 {
  align-items: center;
  gap: 12px;
  color: #fff;
}
.businessHours10am,
.tpineLeasing {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarIcon,
.materialSymbolsstarIcon1,
.materialSymbolsstarIcon2,
.materialSymbolsstarIcon3,
.materialSymbolsstarIcon4 {
  width: 17px;
  position: relative;
  height: 17px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.div3 {
  position: relative;
  line-height: 24px;
}
.frameParent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.reviews8Ratings {
  position: relative;
  line-height: 24px;
  color: #0f172a;
}
.frameParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 16px;
}
.businessHours10am6pmParent,
.ownerAboutParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.businessHours10am6pmParent {
  gap: 8px;
  color: #000;
}
.ownerAboutParent {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  gap: 24px;
}
.map {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.heyThere1 {
  white-space: pre-wrap;
}
.heyThere1,
.whetherYoureMoving1 {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.soHitMe1 {
  margin: 0;
}
.heyThereThisContainer1,
.theLocationIs {
  position: relative;
  line-height: 20px;
  color: #000;
  display: none;
}
.theLocationIs {
  color: #64748b;
  display: inline-block;
}
.frameChild1,
.rectangleIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 390px;
}
.rectangleIcon {
  object-fit: cover;
}
.frameChild1 {
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
}
.iconoutlinemap {
  width: 40px;
  position: relative;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.californiaLosAngeles,
.vehicleLocation {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.californiaLosAngeles {
  font-size: 24px;
  line-height: 32px;
  color: #e2e8f0;
}
.vehicleLocationParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.text15 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
  color: #0f172a;
}
.iconoutlinemapParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.mapParent,
.rectangleContainer {
  align-self: stretch;
  overflow: hidden;
}
.rectangleContainer {
  position: relative;
  border-radius: 8px;
  height: 390px;
  flex-shrink: 0;
  text-align: center;
  color: #cbd5e1;
}
.mapParent {
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  gap: 12px;
}
.calendar {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.august2020,
.chooseAConvenient {
  line-height: 20px;
  display: inline-block;
}
.chooseAConvenient {
  width: 586px;
  position: relative;
  color: #000;
}
.august2020 {
  position: absolute;
  width: calc(100% - 80px);
  top: calc(50% - 9px);
  left: 40px;
  font-weight: 600;
  height: 18px;
}
.pxCarrotLeft,
.pxCarrotRight {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.pxCarrotRight {
  right: 0;
  display: none;
}
.pxCarrotLeft {
  left: 0;
}
.calendarelementsdatesmonth {
  width: 316px;
  position: relative;
  height: 30px;
}
.autoAddedFrame {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 316px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.calendarDaysChild {
  top: 170px;
  left: 92px;
  border-radius: 20px 0 0 20px;
  background-color: #feede5;
  border: 1px solid #fb9365;
  width: 227px;
  height: 40px;
}
.calendarDaysChild,
.calendarDaysItem,
.justDayChild {
  position: absolute;
  box-sizing: border-box;
}
.calendarDaysItem {
  top: 216px;
  left: -8px;
  border-radius: 0 20px 20px 0;
  background-color: #feede5;
  border: 1px solid #fb9365;
  width: 186px;
  height: 40px;
}
.justDayChild {
  top: 7px;
  left: 9px;
  border-radius: 6px;
  background-color: rgba(0, 133, 255, 0.1);
  border: 1px solid rgba(0, 133, 255, 0.2);
  width: 30px;
  height: 35px;
  display: none;
}
.day {
  top: calc(50% - 11px);
  left: calc(50% - 5px);
  line-height: 20px;
}
.day,
.justDay,
.mon {
  position: absolute;
}
.justDay {
  top: calc(50% - 96px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
  display: none;
  text-align: left;
  color: #46627c;
}
.mon {
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 158px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.tue {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf1 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 112px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.wed {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf2 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 66px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.thu {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf3 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 20px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.fri {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf4 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 26px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.sat {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf5 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 72px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.sun {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf6 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 118px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.bg,
.day1 {
  position: absolute;
}
.bg {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day1 {
  top: calc(50% - 11px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdayempty {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.bg1,
.day2 {
  position: absolute;
}
.bg1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day2 {
  top: calc(50% - 11px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdayempty1 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day3 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 3px);
  line-height: 20px;
}
.calendarelementsdaydefault {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.currentDay {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdayselected {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day4 {
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 140%;
}
.day4,
.day5,
.oday {
  position: absolute;
}
.oday {
  top: calc(50% - 96px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
  font-family: "Open Sans";
}
.day5 {
  top: calc(50% - 10px);
  left: calc(50% - 5px);
  line-height: 20px;
}
.chosenDay {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% + 72px);
  border-radius: 24px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day6 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault1 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day7 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault2 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day8 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault3 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day9 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault4 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day10 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault5 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day11 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault6 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day12 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault7 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day13 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault8 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day14 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault9 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day15 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault10 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day16 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault11 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day17 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault12 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day18 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault13 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day19 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault14 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day20 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault15 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day21 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault16 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day22 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault17 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day23 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault18 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.day24 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault19 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 66px);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day25 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault20 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day26 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault21 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day27 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault22 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.bg2,
.day28 {
  position: absolute;
}
.bg2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day28 {
  top: calc(50% - 10px);
  left: calc(50% - 3px);
  line-height: 20px;
}
.calendarelementsdayempty2 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
}
.day29 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault23 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day30 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault24 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day31 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault25 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day32 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault26 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 20px);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day33 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault27 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
}
.bg3,
.day34 {
  position: absolute;
}
.bg3 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day34 {
  top: calc(50% - 10px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdayempty3 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.calendarDays {
  position: absolute;
  top: 60px;
  left: calc(50% - 157.5px);
  width: 316px;
  height: 256px;
  overflow: hidden;
}
.calendarDateRange {
  flex: 1;
  position: relative;
  border-radius: 8px;
  height: 336px;
}
.august20201 {
  position: absolute;
  width: calc(100% - 80px);
  top: calc(50% - 9px);
  left: 40px;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  height: 18px;
}
.pxCarrotLeft1,
.pxCarrotRight1 {
  position: absolute;
  top: 5px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.pxCarrotRight1 {
  right: 0;
}
.pxCarrotLeft1 {
  left: 0;
  display: none;
}
.calendarelementsdatesmonth1 {
  width: 316px;
  position: relative;
  height: 30px;
}
.autoAddedFrame1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.justDayItem {
  position: absolute;
  top: 7px;
  left: 9px;
  border-radius: 6px;
  background-color: rgba(0, 133, 255, 0.1);
  border: 1px solid rgba(0, 133, 255, 0.2);
  box-sizing: border-box;
  width: 30px;
  height: 35px;
  display: none;
}
.day35 {
  top: calc(50% - 11px);
  left: calc(50% - 5px);
  line-height: 20px;
}
.day35,
.justDay1,
.mon1 {
  position: absolute;
}
.justDay1 {
  top: calc(50% - 96px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
  text-align: left;
}
.mon1 {
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf7 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 158px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.tue1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf8 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 112px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.wed1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf9 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 66px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.thu1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf10 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% - 20px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.fri1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf11 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 26px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.sat1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf12 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 72px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.sun1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
}
.calendarelementsdatesdayOf13 {
  position: absolute;
  top: calc(50% - 128px);
  left: calc(50% + 118px);
  width: 40px;
  height: 20px;
  color: #64748b;
}
.bg4,
.day36 {
  position: absolute;
}
.bg4 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day36 {
  top: calc(50% - 11px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdayempty4 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
  color: #94a3b8;
}
.bg5,
.day37 {
  position: absolute;
}
.bg5 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day37 {
  top: calc(50% - 11px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdayempty5 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
  color: #94a3b8;
}
.day38 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 3px);
  line-height: 20px;
}
.calendarelementsdaydefault28 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
  color: #94a3b8;
}
.currentDay1 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdayselected1 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
  color: #94a3b8;
}
.day39 {
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 140%;
}
.day39,
.day40,
.oday1 {
  position: absolute;
}
.oday1 {
  top: calc(50% - 96px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
  font-family: "Open Sans";
}
.day40 {
  top: calc(50% - 10px);
  left: calc(50% - 5px);
  line-height: 20px;
}
.chosenDay1 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% + 72px);
  border-radius: 24px;
  background-color: #feede5;
  border: 1px solid #fb9365;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  color: #fa6f32;
}
.day41 {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault29 {
  position: absolute;
  top: calc(50% - 96px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.day42 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault30 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.day43 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault31 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
}
.day44 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault32 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
}
.day45 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault33 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
}
.day46 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdaydefault34 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
.day47 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault35 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
}
.day48 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault36 {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
}
.day49 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault37 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.day50 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault38 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
}
.day51 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault39 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
}
.day52 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault40 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
}
.day53 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault41 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
.day54 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault42 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
}
.day55 {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault43 {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
}
.day56 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault44 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.day57 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault45 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
}
.day58 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 7px);
  line-height: 20px;
}
.calendarelementsdaydefault46 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
}
.day59 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault47 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
}
.day60 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault48 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
.day61 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault49 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
}
.day62 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault50 {
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
}
.bg6,
.day63 {
  position: absolute;
}
.bg6 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day63 {
  top: calc(50% - 10px);
  left: calc(50% - 3px);
  line-height: 20px;
}
.calendarelementsdayempty6 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 72px);
  width: 40px;
  height: 40px;
}
.day64 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault51 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 158px);
  width: 40px;
  height: 40px;
}
.day65 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault52 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 112px);
  width: 40px;
  height: 40px;
}
.day66 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault53 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 66px);
  width: 40px;
  height: 40px;
}
.day67 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 9px);
  line-height: 20px;
}
.calendarelementsdaydefault54 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% - 20px);
  width: 40px;
  height: 40px;
}
.day68 {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 8px);
  line-height: 20px;
}
.calendarelementsdaydefault55 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 26px);
  width: 40px;
  height: 40px;
}
.bg7,
.day69 {
  position: absolute;
}
.bg7 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.day69 {
  top: calc(50% - 10px);
  left: calc(50% - 4px);
  line-height: 20px;
}
.calendarelementsdayempty7 {
  position: absolute;
  top: calc(50% + 88px);
  left: calc(50% + 118px);
  width: 40px;
  height: 40px;
}
.calendarDays1 {
  width: 316px;
  position: relative;
  height: 256px;
  overflow: hidden;
  flex-shrink: 0;
}
.calendarMonth {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
}
.calendarDateRangeParent,
.calendarParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.calendarDateRangeParent {
  flex-direction: row;
  gap: 4px;
  text-align: center;
}
.calendarParent {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 24px;
  gap: 24px;
}
.tipsAboutThe {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.instanceChild {
  width: 251px;
  position: relative;
  background-color: #d9d9d9;
  height: 141px;
  display: none;
}
.div4 {
  font-weight: 600;
  color: #1e293b;
}
.div4,
.leaveTheTruck,
.minOfReading {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.readMore {
  width: 250.7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fa6f32;
  display: none;
}
.rectangleParent1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.instanceItem {
  width: 251px;
  position: relative;
  background-color: #d9d9d9;
  height: 141px;
  display: none;
}
.div5 {
  font-weight: 600;
  color: #1e293b;
}
.div5,
.leaveTheTruck1,
.minOfReading1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.readMore1 {
  width: 250.7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fa6f32;
  display: none;
}
.rectangleParent2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.instanceInner {
  width: 251px;
  position: relative;
  background-color: #d9d9d9;
  height: 141px;
  display: none;
}
.div6 {
  font-weight: 600;
  color: #1e293b;
}
.div6,
.leaveTheTruck2,
.minOfReading2 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.readMore2 {
  width: 250.7px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fa6f32;
  display: none;
}
.instanceGroup,
.rectangleParent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleParent3 {
  flex: 1;
  flex-direction: column;
  gap: 16px;
}
.instanceGroup {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
  font-size: 16px;
  color: #64748b;
}
.photosParent,
.tipsAboutTheRulesOfTakingParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tipsAboutTheRulesOfTakingParent {
  align-self: stretch;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  gap: 24px;
  font-size: 20px;
}
.photosParent {
  width: 47vw;
  position: relative;
  gap: 32px;
  color: #0f172a;
  overflow: hidden;
  border-radius: 10px;
}
.tab1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab1Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild {
  align-self: stretch;
  position: relative;
  background-color: #fb814c;
  height: 3px;
}
.desktopTab {
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fa6f32;
}
.tab2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab2Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabItem {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab1 {
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab3Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabInner {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab2 {
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab4Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild1 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab3 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab5Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild2 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab4 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab6Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild3 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab5 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab7Wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild4 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab6 {
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabs,
.underLine {
  align-self: stretch;
}
.tabs {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.underLine {
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.tabExampleLightDesktop {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.usd025ratePer,
.usd81daily {
  width: 302px;
  position: relative;
  display: inline-block;
}
.usd81daily {
  line-height: 28px;
  font-weight: 600;
}
.usd025ratePer {
  font-size: 14px;
  line-height: 20px;
}
.usd81dailyParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 20px;
  color: #0f172a;
  margin-bottom: 16px;
}
.frameChild2 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.inputText11 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecalendar {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText12 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent3 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput4 {
  align-self: stretch;
  position: relative !important;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8 !important;
  box-sizing: border-box !important;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel4 {
  position: relative;
  flex: 1;
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputWithLabel4 > div > div,
.inputWithLabel5 > div > div {
  padding-right: 0 !important;
}
.inputText13 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecalendar1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText14 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent4 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput5 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel5,
.inputWithLabelContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputWithLabel5 {
  position: relative;
  flex: 1;
  height: 68px;
  flex-direction: column;
  gap: 4px;
}
.inputWithLabelContainer {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
}
.inputText15 {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 24px;
}
.iconsolidchevronDown1 {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.basicInput6,
.checkbox24 {
  position: relative;
  box-sizing: border-box;
}
.basicInput6 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.checkbox24 {
  width: 20px;
  border-radius: 6px;
  border: 1px solid #64748b;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription12 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.checkbox23,
.frameParent6 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.checkbox23 {
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #0f172a;
}
.frameParent6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.frameChild3 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text16 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button2,
.iconsolidphone {
  overflow: hidden;
  flex-shrink: 0;
}
.button2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}
.iconsolidphone {
  width: 20px;
  position: relative;
  height: 20px;
}
.text17 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
  color: #0f172a;
}
.buttonParent,
.tabExampleLightDesktopParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonParent {
  gap: 16px;
  font-size: 16px;
  color: #94a3b8;
}
.tabExampleLightDesktopParent {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #fff;
  padding: 16px 24px 32px;
  gap: 24px;
}
.text18 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button4 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #fff;
}
.day70,
.frameChild4 {
  align-self: stretch;
  position: relative;
}
.day70 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
.frameChild4 {
  background-color: #e2e8f0;
  height: 1px;
}
.inputText16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecalendar2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText17 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent5 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput7 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel6 {
  flex: 1;
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.inputText18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlinecalendar3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText19 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent6 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput8 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel7,
.inputWithLabelParent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputWithLabel7 {
  flex: 1;
  height: 68px;
  flex-direction: column;
  gap: 4px;
}
.inputWithLabelParent1 {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
}
.inputText20 {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 24px;
}
.iconsolidchevronDown2 {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
.basicInput9,
.checkbox26 {
  position: relative;
  box-sizing: border-box;
}
.basicInput9 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  height: 44px;
  color: #94a3b8;
}
.checkbox26 {
  width: 20px;
  border-radius: 6px;
  border: 1px solid #64748b;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.termsCondotions {
  text-decoration: underline;
}
.textDescription13 {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.checkbox25,
.frameParent7 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.checkbox25 {
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #0f172a;
}
.frameParent7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: #475569;
}
.frameChild5 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.text19 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button5,
.iconsolidphone1 {
  overflow: hidden;
  flex-shrink: 0;
}
.button5 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}
.iconsolidphone1 {
  width: 20px;
  position: relative;
  height: 20px;
}
.text20 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button6,
.buttonGroup {
  align-self: stretch;
  display: flex;
}
.button6 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
  color: #0f172a;
}
.buttonGroup {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #fff;
}
.frameChild6 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 131.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline1 {
  position: absolute;
  top: 0;
  left: 183.7px;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 86.3px;
}
.groupDiv {
  flex: 1;
  position: relative;
  height: 24px;
}
.iconsolidchevronDown3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameWrapper1,
.groupParent {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.groupParent {
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.frameWrapper1 {
  border-radius: 8px !important;
  background-color: #f8fafc !important;
  border: 1px solid #cbd5e1 !important;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 16px !important;
}
.headline2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 131.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline3 {
  position: absolute;
  top: 0;
  left: 183.7px;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  width: 86.3px;
}
.frameParent9 {
  flex: 1;
  position: relative;
  height: 24px;
}
.iconsolidcheveronUp {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.groupContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.frameChild7 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.frameParent11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  color: #0f172a;
}
.subtext,
.subtext1 {
  position: relative;
  line-height: 20px;
}
.subtextParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.subtext2,
.subtext3 {
  position: relative;
  line-height: 20px;
}
.frameParent10,
.subtextGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameParent10 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
  color: #475569;
}
.frameChild8 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline6 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.frameParent13 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  color: #0f172a;
}
.subtext4,
.subtext5 {
  position: relative;
  line-height: 20px;
}
.subtextContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.subtext6,
.subtext7 {
  position: relative;
  line-height: 20px;
}
.frameParent12,
.subtextParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameParent12 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
  color: #475569;
}
.frameChild9 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline8 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir5 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineParent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.frameParent15 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.subtext8 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475569;
}
.frameParent14 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.frameChild10 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline10 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconoutlinequestionMarkCir6 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.headlineParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.headline11 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.frameParent17 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  color: #0f172a;
}
.subtext10,
.subtext9 {
  position: relative;
  line-height: 20px;
}
.subtextParent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.subtext11,
.subtext12 {
  position: relative;
  line-height: 20px;
}
.subtextParent3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.subtext13,
.subtext14 {
  position: relative;
  line-height: 20px;
}
.frameParent16,
.subtextParent4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frameParent16 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
  color: #475569;
}
.frameChild11 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.headline12,
.headline13 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.headlineParent4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.iconsolidcheveronUp1 {
  width: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 20px;
  display: none;
}
.subtext15 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.iconoutlinequestionMarkCir7 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.subtextParent5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.subtext16 {
  position: relative;
  line-height: 20px;
}
.accordion,
.dayParent,
.frameParent18,
.frameParent8 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.accordion {
  border-radius: 8px;
  border: 1px solid #cbd5e1;
  flex-direction: row;
  padding: 16px;
  gap: 16px;
  font-size: 14px;
  color: #475569;
}
.dayParent,
.frameParent18,
.frameParent8 {
  flex-direction: column;
}
.frameParent18 {
  gap: 4px;
}
.dayParent,
.frameParent8 {
  border-radius: 8px;
}
.frameParent8 {
  background-color: #f8fafc;
  border: 1px solid #cbd5e1;
  padding: 16px;
  gap: 16px;
}
.dayParent {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  gap: 24px;
  font-size: 16px;
  color: #0f172a;
}
.informationCircleIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.title {
  flex: 1;
}
.text21,
.title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.alertstextLink,
.component2,
.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.alertstextLink {
  align-items: center;
  color: #1d4ed8;
}
.component2,
.content {
  gap: 12px;
}
.content {
  flex: 1;
  align-items: center;
}
.component2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #eff6ff;
  align-items: flex-start;
  padding: 16px;
  color: #1e40af;
}
.iconoutlineflag {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.reportAnAd {
  position: relative;
  text-decoration: underline;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlineflagParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #64748b;
}
.frameParent5 {
  position: fixed;
  width: 26vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  transition: top 0.4s ease-in-out;
  top: 9.5rem;
  z-index: 10;
}

.iAcceptCheckbox > input {
  width: 20px;
  height: 20px;
  margin-top: 0;
}

.accordionParent > div {
  display: flex;
  justify-content: space-between;
}

.frameParent5.scrolled {
  top: 3vh;
}

.tabItem {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: black !important;
}

.mdicardsHeartOutlineIcon {
  position: relative;

  width: 24px;
  height: 24px;
  overflow: hidden;
  z-index: 999;
}
.tabItemWrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild5 {
  align-self: stretch;
  position: relative;
  background-color: #fb814c;
  height: 3px;
}
.desktopTab7 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fa6f32;
}
.tabItem1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tabItemContainer {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild6 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab8 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabItem2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tabItemFrame {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild7 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab9 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabItem3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tabItemWrapper1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild8 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab10 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabItem4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tabItemWrapper2 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild9 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab11 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tabItem5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tabItemWrapper3 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild10 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab12 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab71 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.tab7Container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.desktopTabChild11 {
  align-self: stretch;
  position: relative;
  height: 3px;
}
.desktopTab13,
.tabs1 {
  overflow: hidden;
  align-items: center;
}
.desktopTab13 {
  height: 44px;
  display: none;
  flex-direction: column;
  justify-content: center;
}
.tabs1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
}
.underLine1 {
  width: 865px;
  flex: 1;
  position: relative;
  background-color: #e2e8f0;
  display: none;
}
.tabExampleLightDesktop1 {
  align-self: stretch;
  width: 865px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconoutlineprinter {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text22 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button7 {
  border-radius: 8px;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 6px;
}
.iconoutlineshare {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.accordionChild {
  display: flex;
  justify-content: space-between;
  padding-right: 20%;
}

.accordionChild > span:nth-child(2) {
  width: 60px;
}
.text23 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button8 {
  border-radius: 8px;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 6px;
}
.iconoutlineheart {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text24 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.button9,
.buttonContainer {
  display: flex;
  flex-direction: row;
}
.button9 {
  border-radius: 8px;
  height: 36px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 6px;
}
.buttonContainer {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.tabExampleLightDesktopGroup {
  position: relative;
  background-color: #f8fafc;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12%;
  box-sizing: border-box;
  overflow: scroll;
}
.text25 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
}
.text26 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
}
.text27 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink2,
.links {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footerssimpleLink2 {
  justify-content: center;
  padding: 8px 20px;
}
.links {
  position: absolute;
  top: 0;
  left: calc(50% - 231.5px);
  height: 24px;
  justify-content: flex-start;
}
.container1,
.footerssocialIcon {
  width: 1216px;
  position: relative;
  height: 24px;
}
.footerssocialIcon {
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon1 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon3 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon4 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footersocialLinks,
.footerssocialLink4 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialLink4 {
  display: flex;
}
.footersocialLinks {
  display: none;
  gap: 24px;
}
.copyright {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #9ca3af;
  text-align: center;
}
.container {
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 32px;
  box-sizing: border-box;
  gap: 32px;
}
.footer,
.instanceParent {
  position: relative;

  /* width: 1600px; */
}

.footer {
  top: 4864px;
  left: calc(50% - 800px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: #6b7280;
}
.instanceParent {
  padding: 0 12%;
  background-color: #f8fafc;
}
.inputText21 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}
.basicInput10 {
  flex: 1;
  position: relative;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
}
.iconoutlinesearch {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText22 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent7 {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput11 {
  flex: 1;
  position: relative;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  overflow: hidden;
  margin-left: -1px;
}
.basicInputParent {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 220px);
  width: 439px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.text28 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button10 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  z-index: 0;
}
.iconoutlinebell {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button11 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}
.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}
.photo,
.text29 {
  position: relative;
}
.photo {
  width: 20px;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text29 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}
.button12 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}
.iconsolidmenu {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button13,
.buttonParent2 {
  display: flex;
  flex-direction: row;
}
.button13 {
  width: 44px;
  border-radius: 0 8px 8px 0;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.buttonParent2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 2;
  font-size: 12px;
  color: #334155;
}
.frameChild12 {
  width: 9px;
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 152px;
  border-radius: 40px;
  background-color: #fe0000;
  border: 1px solid #f1f5f9;
  box-sizing: border-box;
  height: 9px;
  z-index: 3;
}
.buttonParent1 {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #fff;
}
.theLogoMakerBuiltFor1000 {
  position: absolute;
  top: calc(50% - 17px);
  left: 0;
  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}
.frameParent19 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.alreadyHaveAccount {
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  font-size: 16px;
  color: #94a3b8;
}
.icon,
.text30 {
  position: relative;
}
.icon {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text30 {
  line-height: 20px;
  font-weight: 600;
}
.textButton {
  position: absolute;
  top: 108px;
  left: 189px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}
.text31,
.toastMessage {
  position: relative;
  line-height: 24px;
}
.text31 {
  line-height: 20px;
  font-weight: 600;
}
.button14 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 14px;
}
.productPage,
.toast {
  background-color: #fff;
}
.toast {
  position: fixed;
  top: 840px;
  left: 45%;
  box-shadow: 0 6px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 16px;
  font-size: 16px;
  color: #0f172a;
}
.productPage {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #475569;
  margin-bottom: 10rem;
  font-family: Inter;
}

.mainContainer {
  position: relative;
  top: 2vh;
  gap: 5%;
  margin: 0 12vw;
  display: flex;
  justify-content: flex-start;
}
.infoContainerTabs {
  min-width: none !important;
  min-height: unset !important;
  max-height: 35px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-transform: capitalize !important ;
  min-width: fit-content !important;
}

.button:hover,
.button1:hover,
.button2:hover,
.button3:hover,
.button4:hover,
.button5:hover,
.button6:hover,
.button7:hover,
.button8:hover,
.button9:hover,
.button10:hover,
.button11:hover,
.button12:hover,
.button13:hover,
.button14:hover {
  filter: brightness(90%);
  cursor: pointer;
}
.tag:hover,
.tag1:hover,
.tag2:hover,
.tag3:hover,
.tag4:hover,
.tag5:hover,
.tag6:hover,
.tag7:hover,
.tag8:hover,
.tag9:hover,
.tag10:hover,
.tag11:hover,
.tag12:hover,
.tag13:hover,
.tag14:hover,
.tag15:hover {
  filter: brightness(90%);
  cursor: pointer;
}

.form-check-input {
  margin-top: 0 !important;
  height: 20px;
  width: 20px;
}

.frameWrapper1 > div {
  gap: 29% !important;
  flex-direction: row !important;
  margin: auto;
}

.MuiAccordion-gutters::before {
  background-color: unset !important;
}

.spanTitle {
  display: flex;
  /* padding-right: 1%; */
}
.spanContainer {
  display: flex;
}

#panel1-content > div {
  padding: unset !important;
}

.gridContainer {
  width: 100%;
}

.gridContainer > .tagParent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.datePickerCalendar {
  transform: translate(-20%, 25%) !important;
  z-index: 2 !important;
}

.tagParent > * {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25%;
  padding: 10px; /* Adjust padding */
}

#datePicker {
  display: none !important;
}

.topNavButton {
  color: #605f5f;
  padding: 5px;
  cursor: pointer;
  width: 100%;
  display: block;
}

@media screen and (max-width: 1280px) {
  .mainContainer {
    margin: 0px;
    left: 2vw;
  }
  .rectangleParent {
    width: 250px;
  }
  .photos1 {
    left: 78px;
  }
  .iconoutlinemapParent {
    left: 150px;
  }
  .photosParent {
    width: 60vw;
  }
  .frameParent5 {
    width: 30vw;
  }
  .tabExampleLightDesktopGroup {
    padding: 0 5%;
  }
  .iconoutlinemapParent {
    left: 8vw;
  }
}

@media screen and (max-width: 1200px) {
  .photosParent {
    width: 55vw;
  }
  .frameParent5 {
    width: 35vw;
  }
  .tag5,
  .tag6 {
    width: 20vw;
  }
}

@media screen and (max-width: 1000px) {
  .calendarDateRangeParent {
    display: block;
  }
  .iconoutlinemapParent {
    left: 2vw;
  }
}

@media screen and (max-width: 900px) {
  .iconoutlinemapParent {
    left: 4vw;
  }

  .frameParent5 {
    position: unset;
  }

  .frameContainer {
    align-items: center;
  }

  .instanceGroup {
    flex-direction: column;
  }

  .drawer {
    position: fixed;
    bottom: 8vh;
    left: 42vw;
  }
  .mainContainer {
    /* flex-direction: column; */
    display: block;
    left: 0;
  }
  .photosParent,
  .frameParent5 {
    width: 90vw;
    margin-left: 5vw;
  }
}

@media screen and (max-width: 850px) {
  .tag5,
  .tag6 {
    width: 40vw;
  }
  .iconoutlinemapParent {
    left: 16vw;
  }
  .productPage{
    margin-bottom: 3rem;
  }
  .tabExampleLightDesktopParent {
    box-shadow: none;
    padding: 0;
  }
}

@media screen and (max-width: 800px) {
  .photos {
    height: 300px;
  }
  .rectangleGroup {
    height: 150px;
  }
  .photos1 {
    top: 75px;
  }
  .button {
    width: 100%;
  }
  .tagContainer {
    display: flex;
    width: unset;
  }
  .tag5,
  .tag6 {
    margin-top: 8px;
  }
  .iconoutlinemapParent {
    left: 70px;
  }
  .californiaLosAngeles {
    font-size: large;
  }
  .button1 {
    font-size: 11px;
  }
}

@media screen and (max-width: 600px) {
  /* .iconoutlinemapParent {
    left: 4vw;
  }

  .frameParent5 {
    position: relative;
  }

  .frameContainer {
    align-items: center;
  }

  .instanceGroup {
    flex-direction: column;
  }

  .drawer {
    position: fixed;
    bottom: 8vh;
    left: 42vw;
  } */
}

@media screen and (max-width: 500px) {
  .rectangleParent {
    width: 150px;
  }
  .photos1 {
    top: 65px;
    left: 30px;
  }
  .instanceParent,
  .frameParent,
  .usageRestrictionsParent,
  .ratesParent,
  .calculatorParent {
    padding: 8px 3%;
  }
  .textDescription,
  .textDescription2,
  .heyThere,
  .whetherYoureMoving,
  .soHitMe,
  .businessHours10am,
  .tpineLeasing,
  .reviews8Ratings,
  .leaveTheTruck,
  .div4,
  .minOfReading,
  .title {
    font-size: small;
  }
  .usageRestrictionsParent {
    width: 100%;
  }
  .iconoutlinemapParent {
    left: 0px;
  }
  .frameParent2 {
    font-size: xx-small;
  }
  .iconoutlinemapParent {
    left: 0;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #3C85EB;
  height: 100%;
  width: 24rem;
  font-family: Inter;
}

.textContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
  width: 100%;
  padding: 0 3rem 0 5.4234rem;
  margin-top: 5rem;
  color: white;
  font-family: Inter;
  font-style: normal;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.image {
  width: 20.3125rem;
  height: 18.51931rem;
  flex-shrink: 0;
  margin-bottom: 2rem;
}

.unsplashx60ygfg5soyIcon {
  position: absolute;
  top: 34vh;
  right: -10vw;
  width: 924px;
  height: 616px;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}

.inputText {
  line-height: 28px;
  font-weight: 600;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.inputTextParent {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: 419px;
  width: 536px;
  height: 402px;
  object-fit: cover;
  display: none;
}

.unsplashx60ygfg5soyParent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e9f2f9;
  width: 376px;
  height: 1200px;
  overflow: hidden;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.inputText2,
.text {
  position: relative;
  line-height: 24px;
}

.text {
  font-weight: 600;
}

.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #0f172a;
}

.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}

.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}

.vehicleOwner08Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}

.stepcircle,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textButton {
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.stepcircle {
  border-radius: 16px;
  background-color: #fa6f32;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.instanceChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}

.stepcircle1,
.stepcircleWithText1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepcircle1 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  justify-content: center;
}

.stepcircleWithText1 {
  justify-content: flex-start;
  gap: 16px;
  color: #fa6f32;
}

.dot {
  position: relative;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.stepcircle2 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #cbd5e1;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stepcircleWithText2,
.stepcircleWithTextParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.stepcircleWithText2 {
  gap: 16px;
  color: #64748b;
}

.stepcircleWithTextParent {
  gap: 8px;
}

.inputText3,
.userName {
  align-self: stretch;
  position: relative;
}

.userName {
  line-height: 32px;
  width: max-content;
  font-weight: 600;
}

.inputText3 {
  font-size: 14px;
  line-height: 20px;
  color: #334155;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 24px;
}

.textButton1 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.inputField {
  font-size: 16px !important;
  position: absolute;
  left: 12px;
  line-height: 24px;
}

.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #64748b;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.25rem;
  flex: 1 0 0;
}

.inputText7 {
  position: absolute;
  top: 10px;
  left: 12px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inputWithLabelParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.inputText9 {
  position: absolute;
  height: 100%;
  left: 12px;
  line-height: 24px;
}

.basicInput2 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}

.inputTextWrapper {
  flex-direction: row;
  align-items: center;
}

.frameParent,
.inputTextWrapper,
.inputWithLabel3,
.inputWithLabelGroup {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.inputWithLabel3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.frameParent,
.inputWithLabelGroup {
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.frameParent {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-size: 14px;
}

.frameChild {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
  display: none;
}

.button1,
.inputText12 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.button1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}

.inputText12 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  width: 139px;
}

.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.reefer {
  font-size: 16px !important;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextContainer,
.tag,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextContainer,
.tagParent {
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  align-self: stretch;
  display: none;
}

.textButtonGroup {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background-color: #FFF;
}

.backButtonParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 37.5rem;
  height: 100%;
  font-family: Inter;
  font-style: normal;
  gap: 2rem;
}

.signUpStep {
  color: #FA6F32;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  display: none;
}


@media screen and (max-width: 1250px) {
  .unsplashx60ygfg5soyParent {
    display: none;
  }

  .userName {
    width: unset;
  }

  .backButton {
    float: left;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .backButtonParent {
    padding: 0 1rem;
    width: 100%;
  }

  .stepcircleWithTextParent {
    display: none;
  }

  .signUpStep {
    display: block;
  }

  .textButtonGroup {
    padding: 1.5rem 1rem;
  }

  .inputWithLabelParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
  }

}
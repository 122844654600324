@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.notFoundWrapper {
  background-color: #ff4e009e;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notFoundWrapper h1,
.notFoundWrapper p,
.notFoundWrapper a {
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
}

.button {
  width: 25%;
  margin: 0 auto;
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #efefef;
  padding: 0.5em;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
}

.button:hover {
  background-color: #fa6f32;
  color: #fff;
}

.generalp {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

.generalh1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
}

.featureList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}

.featureItem {
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: flex-start;
}

.featureListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  /*padding: 2% 5%;*/
}

.featureBar {
  transition: background-color 0.5s;
  /* Smooth transition for background color */
  align-self: stretch;
  width: 4px;
  height: 157px;
  margin: auto 0;
  background-color: #e2e8f0;
  /* Default background */
  border-radius: 2px;
}

.featureBarActive {
  background-color: #fa6f32;
  /* Active background color */
  animation: backgroundColorCycle 3s linear infinite;
}

@keyframes backgroundColorCycle {
  0% {
    background-color: #e2e8f0;
    /* Default color */
  }

  20% {
    background-color: #fa6f32;
    /* Active color */
  }

  50% {
    background-color:  #fa6f32;
    /* Default color */
  }

  100% {
    background-color: #fa6f32;
    /* Default color */
  }
}

.featureImageContainer {
  display: flex;
  width: 100%;
  border-radius: 8px;
  /*padding: 2% 5%;*/
}

.featureContent {
  align-self: stretch;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  width: 371px;
  margin: auto 0;
}

.featureTitle {
  color:  #0f172a;
  font-size: 18px;
  font-weight: 600;
  line-height: 2;
}

.featureDescription {
  z-index: 1;
  position: relative;
  color: #64748b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}

.container2 {
  padding: 5% 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  width: 100%;
}

.eyebrowAndHeading {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.eyebrowAndHeading>* {
  margin: auto;
}

.eyebrow {
  color:  #fa6f32;
  font-size: 16px;
  font-weight: 400;
}

.heading {
  color: #0f172a;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin-top: 8px;
}

.supportingText {
  color: #64748b;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  padding-top: 1rem;
}

.featureImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  border-radius:8px;
  transition: opacity 0.4s ease-in;
  /* Smooth transition for opacity */
}

/* Fade-In effect */
.fadeIn {
  opacity: 1 !important;
  /* Image will fade in */
}

/* Fade-Out effect */
.fadeOut {
  opacity: 0.5 !important;
  /* Image will fade out */
}

@media (max-width: 991px) {
  .heading {
    max-width: 100%;
  }

  .eyebrow {
    max-width: 100%;
  }

  .eyebrowAndHeading {
    max-width: 100%;
  }

  .featureList {
    max-width: 100%;
  }
}

@media (max-width: 960px) {
  .featureList {
    display: flex;
    flex-direction: column;
    gap:3rem;
  }
}

@media (max-width: 650px) {

  .container2 {
    overflow: hidden;
  }

  .featureContent {
    width: unset;
  }
}

@media (max-width: 400px) {
  .heading {
    font-size: xx-large;
  }
}

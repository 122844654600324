.ellipseDiv,
.frameChild,
.frameInner,
.frameItem {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.ellipseDiv,
.frameInner,
.frameItem {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium {
  position: relative;
  line-height: 140%;
}
.badgesOnCard {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameDiv {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt579 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle1271 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio43612 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12711 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent {
  gap: 4px;
}
.frameWrapper,
.materialSymbolsstarParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle1272 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k,
.mileage {
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage {
  left: 38px;
}
.iconoutlinetruckParent {
  display: flex;
  position: relative;
  height: 20px;
  gap: 5%;
}
.vecicleCardrectangle1273Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon,
.vecicleCardrectangle1273 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle1273 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway {
  position: relative;
  line-height: 20px;
}
.pointMapParent,
.toledoOhio43612Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day1,
.span {
  font-weight: 600;
}
.day {
  position: relative;
  line-height: 20px;
}
.component31Inner,
.dayWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner {
  align-self: stretch;
  align-items: center;
}
.component31,
.vecicleCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component31 {
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.vecicleCard {
  flex: 1;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.frameChild1,
.frameChild2,
.frameChild3,
.frameChild4 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild2,
.frameChild3,
.frameChild4 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseGroup {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon1 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon1 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium1 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard1 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent1 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5791 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12712 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor1 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 16px;
}
.toledoOhio436121 {
  position: relative;
  line-height: 20px;
}
.rectangleDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12713 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon1 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div1 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarGroup {
  gap: 4px;
}
.frameWrapper1,
.materialSymbolsstarGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12721 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck1 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k1,
.mileage1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage1 {
  left: 38px;
}
.iconoutlinetruckGroup {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon1,
.vecicleCardrectangle12731 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12731 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon1 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway1 {
  position: relative;
  line-height: 20px;
}
.pointMapGroup,
.toledoOhio43612Group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Group {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day3,
.span1 {
  font-weight: 600;
}
.day2 {
  position: relative;
  line-height: 20px;
}
.component31Child,
.dayContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Child {
  align-self: stretch;
  align-items: center;
}
.component311 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.component311,
.vecicleCard1,
.vecicleCardParent {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.vecicleCardParent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}
.frameChild5,
.frameChild6,
.frameChild7,
.frameChild8 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild6,
.frameChild7,
.frameChild8 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseContainer {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon2 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon2 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium2 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard2 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent2 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5792 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12714 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor2 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 16px;
}
.toledoOhio436122 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12715 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon2 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div2 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarContainer {
  gap: 4px;
}
.frameWrapper2,
.materialSymbolsstarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12722 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck2 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k2,
.mileage2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage2 {
  left: 38px;
}
.iconoutlinetruckContainer {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Inner {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon2,
.vecicleCardrectangle12732 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12732 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon2 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway2 {
  position: relative;
  line-height: 20px;
}
.pointMapContainer,
.toledoOhio43612Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Container {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day5,
.span2 {
  font-weight: 600;
}
.day4 {
  position: relative;
  line-height: 20px;
}
.component31Inner1,
.dayFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner1 {
  align-self: stretch;
  align-items: center;
}
.component312,
.vecicleCard2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component312 {
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.vecicleCard2 {
  flex: 1;
  align-items: center;
  gap: 12px;
  margin-bottom: 3%;
}
.frameChild10,
.frameChild11,
.frameChild12,
.frameChild9 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild10,
.frameChild11,
.frameChild12 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent1 {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon3 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon3 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium3 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard3 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent3 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  object-fit: cover;
}
.peterbilt5793 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12716 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor3 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio436123 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12717 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon3 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div3 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent1 {
  gap: 4px;
}
.frameWrapper3,
.materialSymbolsstarParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12723 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck3 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k3,
.mileage3 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage3 {
  left: 38px;
}
.groupDiv {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Child1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon3,
.vecicleCardrectangle12733 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12733 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon3 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway3 {
  position: relative;
  line-height: 20px;
}
.pointMapParent1,
.toledoOhio43612Parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent1 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day7,
.span3 {
  font-weight: 600;
}
.day6 {
  position: relative;
  line-height: 20px;
}
.component31Inner2,
.dayWrapper1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner2 {
  align-self: stretch;
  align-items: center;
}
.component313 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.component313,
.vecicleCard3,
.vecicleCardGroup {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard3 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.vecicleCardGroup {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}
.frameChild13,
.frameChild14,
.frameChild15,
.frameChild16 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild14,
.frameChild15,
.frameChild16 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent2 {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon4 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium4 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard4 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent4 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5794 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12718 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor4 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio436124 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child6 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12719 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon4 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div4 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent2 {
  gap: 4px;
}
.frameWrapper4,
.materialSymbolsstarParent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12724 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck4 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k4,
.mileage4 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage4 {
  left: 38px;
}
.iconoutlinetruckParent1 {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Child2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon4,
.vecicleCardrectangle12734 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12734 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon4 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway4 {
  position: relative;
  line-height: 20px;
}
.pointMapParent2,
.toledoOhio43612Parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent2 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day9,
.span4 {
  font-weight: 600;
}
.day8 {
  position: relative;
  line-height: 20px;
}
.component31Inner3,
.dayWrapper2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner3 {
  align-self: stretch;
  align-items: center;
}
.component314,
.vecicleCard4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component314 {
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.vecicleCard4 {
  flex: 1;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.frameChild17,
.frameChild18,
.frameChild19,
.frameChild20 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild18,
.frameChild19,
.frameChild20 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent3 {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon5 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon5 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium5 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard5 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent5 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5795 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127110 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor5 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio436125 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child8 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127111 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon5 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div5 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent3 {
  gap: 4px;
}
.frameWrapper5,
.materialSymbolsstarParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12725 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck5 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k5,
.mileage5 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage5 {
  left: 38px;
}
.iconoutlinetruckParent2 {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Child3 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon5,
.vecicleCardrectangle12735 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12735 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon5 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway5 {
  position: relative;
  line-height: 20px;
}
.pointMapParent3,
.toledoOhio43612Parent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent3 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day11,
.span5 {
  font-weight: 600;
}
.day10 {
  position: relative;
  line-height: 20px;
}
.component31Inner4,
.dayWrapper3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner4 {
  align-self: stretch;
  align-items: center;
}
.component315 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.component315,
.vecicleCard5,
.vecicleCardContainer {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard5 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.vecicleCardContainer {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}
.frameChild21,
.frameChild22,
.frameChild23,
.frameChild24 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild22,
.frameChild23,
.frameChild24 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent4 {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon6 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon6 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium6 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard6 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent6 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5796 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child9 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127112 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor6 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio436126 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child10 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127113 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon6 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div6 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent4 {
  gap: 4px;
}
.frameWrapper6,
.materialSymbolsstarParent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12726 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck6 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k6,
.mileage6 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage6 {
  left: 38px;
}
.iconoutlinetruckParent3 {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Child4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon6,
.vecicleCardrectangle12736 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12736 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon6 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway6 {
  position: relative;
  line-height: 20px;
}
.pointMapParent4,
.toledoOhio43612Parent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent4 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day13,
.span6 {
  font-weight: 600;
}
.day12 {
  position: relative;
  line-height: 20px;
}
.component31Inner5,
.dayWrapper4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner5 {
  align-self: stretch;
  align-items: center;
}
.component316,
.vecicleCard6 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.component316 {
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.vecicleCard6 {
  flex: 1;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.frameChild25,
.frameChild26,
.frameChild27,
.frameChild28 {
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  height: 8px;
}
.frameChild26,
.frameChild27,
.frameChild28 {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent5 {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 19.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon7 {
  position: absolute;
  top: 12px;
  right: 18px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.rivipCrownLineIcon7 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.premium7 {
  position: relative;
  line-height: 140%;
}
.badgesOnCard7 {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent7 {
  width: 367px;
  position: relative;
  border-radius: 8px;
  height: 205px;
  background-image: url(/public/generics/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.peterbilt5797 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.vecicleCardrectangle1271Child11 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127114 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor7 {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.toledoOhio436127 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child12 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127115 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarIcon7 {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.div7 {
  position: relative;
  line-height: 20px;
}
.materialSymbolsstarParent5 {
  gap: 4px;
}
.frameWrapper7,
.materialSymbolsstarParent5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12727 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconoutlinetruck7 {
  position: absolute;
  top: 2px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k7,
.mileage7 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}
.mileage7 {
  left: 38px;
}
.iconoutlinetruckParent4 {
  width: 90px;
  position: relative;
  height: 20px;
}
.vecicleCardrectangle1273Child5 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.pointMapIcon7,
.vecicleCardrectangle12737 {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.vecicleCardrectangle12737 {
  width: 6px;
  border-radius: 100px;
  height: 6px;
}
.pointMapIcon7 {
  width: 16px;
  height: 16px;
  display: none;
}
.milesAway7 {
  position: relative;
  line-height: 20px;
}
.pointMapParent5,
.toledoOhio43612Parent5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.toledoOhio43612Parent5 {
  align-self: stretch;
  flex-wrap: wrap;
  gap: 6px;
  color: #64748b;
}
.day15,
.span7 {
  font-weight: 600;
}
.day14 {
  position: relative;
  line-height: 20px;
}
.component31Inner6,
.dayWrapper5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner6 {
  align-self: stretch;
  align-items: center;
}
.component317 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000;
}
.component317,
.vecicleCard7,
.vecicleCardParent1 {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard7 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.vecicleCardParent1 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}
.resultsFound {
  width: 108px;
  position: relative;
  line-height: 150%;
  color: #000;
  display: none;
}
.topBorder {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: #e2e8f0;
  height: 1px;
}
.topBorder1 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.arrowNarrowLeft {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.previous {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content,
.paginationstepLink {
  display: flex;
  justify-content: flex-start;
}
.content {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 16px 4px 0 0;
  gap: 12px;
}
.paginationstepLink {
  flex-direction: column;
  align-items: flex-start;
}
.topBorder2 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content1,
.paginationnumberLink {
  display: flex;
  align-items: flex-start;
}
.content1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.paginationnumberLink {
  flex-direction: column;
  justify-content: flex-start;
}
.topBorder3 {
  align-self: stretch;
  position: relative;
  background-color: #fb814c;
  height: 2px;
}
.number1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content2,
.paginationnumberLink1 {
  display: flex;
  align-items: flex-start;
}
.content2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.paginationnumberLink1 {
  flex-direction: column;
  justify-content: flex-start;
  color: #fa6f32;
}
.topBorder4 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content3,
.paginationnumberLink2 {
  display: flex;
  align-items: flex-start;
}
.content3 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.paginationnumberLink2 {
  flex-direction: column;
  justify-content: flex-start;
}
.topBorder5 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number3 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content4,
.ellipsis {
  display: flex;
  align-items: flex-start;
}
.content4 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.ellipsis {
  flex-direction: column;
  justify-content: flex-start;
}
.topBorder6 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number4 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content5,
.paginationnumberLink3 {
  display: flex;
  align-items: flex-start;
}
.content5 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.paginationnumberLink3 {
  flex-direction: column;
  justify-content: flex-start;
}
.topBorder7 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number5 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content6,
.paginationnumberLink4 {
  display: flex;
  align-items: flex-start;
}
.content6 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 16px 16px 0;
}
.paginationnumberLink4 {
  flex-direction: column;
  justify-content: flex-start;
}
.topBorder8 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.number6 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 16px 0;
}
.numberLinks,
.paginationnumberLink5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.numberLinks {
  flex-direction: row;
  text-align: center;
}
.topBorder9 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.next {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.arrowNarrowRight {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.content8,
.links,
.paginationstepLink1 {
  display: flex;
  justify-content: flex-start;
}
.content8 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: 16px 0 0 4px;
  gap: 12px;
}
.links,
.paginationstepLink1 {
  flex-direction: column;
  align-items: flex-start;
}
.links {
  /* position: absolute; */
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
}
.centeredPageNumbersdesktop {
  align-self: stretch;
  position: relative;
  height: 38px;
  color: #64748b;
}
.frameContainer {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  gap: 32px;
}

.frameContainer > div > div{
  width: 36vw;
}

.reviews {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}
.iconsolidstar {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsolidstarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div8,
.div9 {
  position: relative;
  line-height: 20px;
}
.frameParent9 {
  gap: 4px;
}
.frameParent9,
.frameWrapper8,
.reviewsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameWrapper8 {
  font-size: 14px;
  color: #64748b;
}
.reviewsParent {
  gap: 8px;
  font-size: 20px;
}
.text {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iconoutlinestar,
.iconsolidstar1,
.iconsolidstar2,
.iconsolidstar3,
.iconsolidstar4 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsolidstarParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div10 {
  position: relative;
  line-height: 20px;
}
.frameParent11 {
  gap: 4px;
}
.frameParent11,
.frameWrapper9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child6 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12728 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.div11 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child13 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127116 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.johnSmith {
  position: relative;
  line-height: 20px;
}
.frameParent10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #64748b;
}
.text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.textParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.text2,
.topBorder10 {
  align-self: stretch;
  position: relative;
}
.topBorder10 {
  background-color: #e2e8f0;
  height: 1px;
}
.text2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iconoutlinestar1,
.iconsolidstar5,
.iconsolidstar6,
.iconsolidstar7,
.iconsolidstar8 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsolidstarGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div12 {
  position: relative;
  line-height: 20px;
}
.frameParent13 {
  gap: 4px;
}
.frameParent13,
.frameWrapper10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child7 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle12729 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.div13 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child14 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127117 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.johnSmith1 {
  position: relative;
  line-height: 20px;
}
.frameParent12 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #64748b;
}
.text3 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.textGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.text4,
.topBorder11 {
  align-self: stretch;
  position: relative;
}
.topBorder11 {
  background-color: #e2e8f0;
  height: 1px;
}
.text4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.iconoutlinestar2,
.iconsolidstar10,
.iconsolidstar11,
.iconsolidstar12,
.iconsolidstar9 {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.iconsolidstarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div14 {
  position: relative;
  line-height: 20px;
}
.frameParent15 {
  gap: 4px;
}
.frameParent15,
.frameWrapper11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vecicleCardrectangle1272Child8 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127210 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.div15 {
  position: relative;
  line-height: 20px;
}
.vecicleCardrectangle1271Child15 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle127118 {
  width: 6px;
  position: relative;
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.johnSmith2 {
  position: relative;
  line-height: 20px;
}
.frameParent14 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #64748b;
}
.text5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.frameGroup,
.frameParent8,
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textContainer {
  align-self: stretch;
  gap: 8px;
}
.frameGroup,
.frameParent8 {
  gap: 32px;
}
.frameParent8 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  padding: 32px 24px;
  color: #0f172a;
}
.frameGroup {
  width: 806px;
}
.avatarIcon {
  width: 120px;
  border-radius: 8px;
  height: 120px;
  object-fit: cover;
}
.k1Company,
.usd025ratePer {
  width: 302px;
  position: relative;
}
.k1Company {
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
}
.usd025ratePer {
  font-size: 14px;
  line-height: 20px;
  display: none;
}
.k1CompanyParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  font-size: 20px;
}
.frameChild29 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.am10pm,
.workHours {
  position: relative;
  line-height: 20px;
}
.am10pm {
  color: #64748b;
}
.workHoursParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.companyEmail,
.k1gmailcom {
  position: relative;
  line-height: 20px;
}
.k1gmailcom {
  color: #64748b;
}
.companyEmailParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.alabama,
.companysStateHeadquartes {
  position: relative;
  line-height: 20px;
}
.alabama {
  color: #64748b;
}
.companysStateHeadquartesParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.frameChild30 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.iconsolidphone {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text6 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.avatarParent,
.button {
  align-self: stretch;
  border-radius: 8px;
  display: flex;
}
.button {
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
  font-size: 16px;
}
.avatarParent {
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  gap: 16px;
}
.informationCircleIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.title {
  flex: 1;
}
.text7,
.title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.alertstextLink,
.component2,
.content9 {
  flex-direction: row;
  justify-content: flex-start;
}
.alertstextLink {
  display: flex;
  align-items: center;
  color: #1d4ed8;
}
.component2,
.content9 {
  gap: 12px;
}
.content9 {
  flex: 1;
  display: flex;
  align-items: center;
}
.component2 {
  width: 390px;
  border-radius: 8px;
  background-color: #eff6ff;
  display: none;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
  color: #1e40af;
}
.iconoutlineflag {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.reportAnCompany {
  position: relative;
  text-decoration: underline;
  line-height: 20px;
  font-weight: 600;
}
.iconoutlineflagParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #64748b;
}
.frameParent16 {
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: #0f172a;
}
.companyPageInner,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  gap: 24px;
}
.companyPageInner {
  position: relative;
  left: 0;
  background-color: #f8fafc;
  justify-content: center;
  padding-top: 3vh;
  padding-bottom: 5vh;
  width: 100%;
  box-sizing: border-box;
}
.inputText {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}
.basicInput {
  flex: 1;
  position: relative;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
}
.iconoutlinesearch {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputText1 {
  position: relative;
  line-height: 24px;
}
.inputLeftContent {
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.basicInput1 {
  flex: 1;
  position: relative;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  overflow: hidden;
  margin-left: -1px;
}
.basicInputParent {
  position: absolute;
  top: calc(50% - 22px);
  left: calc(50% - 220px);
  width: 439px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.text8 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button1 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  z-index: 0;
}
.iconoutlinebell {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}
.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}
.photo,
.text9 {
  position: relative;
}
.photo {
  width: 20px;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text9 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}
.button3 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}
.iconsolidmenu {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button4,
.buttonGroup {
  display: flex;
  flex-direction: row;
}
.button4 {
  width: 44px;
  border-radius: 0 8px 8px 0;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 2;
  font-size: 12px;
  color: #334155;
}
.frameChild31 {
  width: 9px;
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 152px;
  border-radius: 40px;
  background-color: #fe0000;
  border: 1px solid #f1f5f9;
  box-sizing: border-box;
  height: 9px;
  z-index: 3;
}
.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #fff;
}
.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}
.frameParent17 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.alreadyHaveAccount {
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  font-size: 16px;
  color: #94a3b8;
}
.icon,
.text10 {
  position: relative;
}
.icon {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text10 {
  line-height: 20px;
  font-weight: 600;
}
.textButton {
  position: absolute;
  top: 108px;
  left: 189px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}
.text11 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
}
.text12 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
}
.text13 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink2,
.links1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footerssimpleLink2 {
  justify-content: center;
  padding: 8px 20px;
}
.links1 {
  position: absolute;
  top: 0;
  left: calc(50% - 231.5px);
  height: 24px;
  justify-content: flex-start;
}
.container1,
.footerssocialIcon {
  width: 1216px;
  position: relative;
  height: 24px;
}
.footerssocialIcon {
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon1 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon3 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon4 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footersocialLinks,
.footerssocialLink4 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialLink4 {
  display: flex;
}
.footersocialLinks {
  display: none;
  gap: 24px;
}
.copyright {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #9ca3af;
  text-align: center;
}
.container,
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.container {
  width: 1280px;
  padding: 48px 32px;
  box-sizing: border-box;
  gap: 32px;
}
.footer {
  position: relative;

  left: calc(50% - 800px);
  background-color: #fff;
  width: 1600px;
  font-size: 16px;
  color: #6b7280;
}
.companyPage {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Inter;
}

.phoneNumberDiv {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

@media (max-width: 1500px) {
  .frameContainer > div > div {
    width: calc(50vw + (1500px - 100vw) / 50);
  }
}

@media (max-width: 1350px) {
  .frameContainer > div > div {
    width: calc(57vw + (1350px - 100vw) / 100);
  }
}

@media (max-width: 1250px) {
  .frameContainer > div > div{
    width: 62vw;
  }
  .frameParent{
    margin: 20px;
  }
  .frameGroup {
    width: unset;
  }
}

@media (max-width: 1050px) {
  .frameContainer > div > div{
    width: unset;
  }
}

@media (max-width: 1000px) {
  .basicInputParent {
    width: 300px;
  }
}

@media (max-width: 850px) {
  .frameParent {
    display: block;
    width: unset;
  }
  .frameParent16 {
    width: 100%;
    margin-top: 20px;
  }
  .frameParent16 div {
    width: 100%;
  }
  .iconoutlineflagParent{
    margin-left: 36%;
  }
}

@media (max-width: 600px) {
  .footer .container {
    margin-bottom: 80px;
  }
  .frameParent{
    margin: 5px;
  }
  .avatarParent{
    height: 500px;
  }
  /* .text11 {
    font-size: 12px;
  } */
}

.parent{
padding: 20px;
}

.wrapper {
  position: relative;
  margin-top: 20px;
}

.phoneInput {
  background: white;
  border: 1px solid #94A3B8;
  border-radius: 8px;
  align-items: center;
  /*padding:0rem !important;*/
  /*border:none;*/
}

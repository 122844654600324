.registrationNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  margin: 0 5rem;
  background-color: transparent;
}

.logo {
  width: 8.58263rem;
  height: 2rem;
}

.authPrompt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.authPromptText {
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.loginButton {
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  border: none;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: #F1F5F9;
  color: #0F172A;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.hamburger {
  display: none;
}

@media (max-width: 1299px) {
  .registrationNavbar {
    margin: 0 2rem;
  }

}

@media (max-width: 625px) {
  .registrationNavbar {
    margin: 0;
    background: #F8FAFC;
  }

  .authPromptText {
    display: none;
  }

  .loginButton {
    display: none;
  }

  .hamburger {
    display: flex;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    border: none;
    border-radius: 0.5rem;
    background: #F1F5F9;
  }
}

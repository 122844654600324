.text-Neutral-50 {
  color: #F8FAFC;
}

.text-Neutral-100 {
  color: #F1F5F9;
}

.text-Neutral-200 {
  color: #E2E8F0;
}

.text-Neutral-300 {
  color: #CBD5E1;
}

.text-Neutral-400 {
  color: #94A3B8;
}

.text-Neutral-500 {
  color: #64748B;
}

.text-Neutral-600 {
  color: #475569;
}

.text-Neutral-700 {
  color: #334155;
}

.text-Neutral-800 {
  color: #1E293B;
}

.text-Neutral-900 {
  color: #0F172A;
}

.bg-Neutral-50 {
  background-color: #F8FAFC;
}

.bg-Neutral-100 {
  background-color: #F1F5F9;
}

.bg-Neutral-200 {
  background-color: #E2E8F0;
}

.bg-Neutral-300 {
  background-color: #CBD5E1;
}

.bg-Neutral-400 {
  background-color: #94A3B8;
}

.bg-Neutral-500 {
  background-color: #64748B;
}

.bg-Neutral-600 {
  background-color: #475569;
}

.bg-Neutral-700 {
  background-color: #334155;
}

.bg-Neutral-800 {
  background-color: #1E293B;
}

.bg-Neutral-900 {
  background-color: #0F172A;
}

.text-Brand-50 {
  color: #FEEDE5;
}

.text-Brand-100 {
  color: #FEDBCC;
}

.text-Brand-200 {
  color: #FDC9B2;
}

.text-Brand-300 {
  color: #FCA57F;
}

.text-Brand-400 {
  color: #FB9365;
}

.text-Brand-500 {
  color: #FB814C;
}

.text-Brand-600 {
  color: #FA6F32;
}

.text-Brand-700 {
  color: #DC4705;
}

.text-Brand-800 {
  color: #B73B04;
}

.text-Brand-900 {
  color: #6E2303;
}

.bg-Brand-50 {
  background-color: #FEEDE5;
}

.bg-Brand-100 {
  background-color: #FEDBCC;
}

.bg-Brand-200 {
  background-color: #FDC9B2;
}

.bg-Brand-300 {
  background-color: #FCA57F;
}

.bg-Brand-400 {
  background-color: #FB9365;
}

.bg-Brand-500 {
  background-color: #FB814C;
}

.bg-Brand-600 {
  background-color: #FA6F32;
}

.bg-Brand-700 {
  background-color: #DC4705;
}

.bg-Brand-800 {
  background-color: #B73B04;
}

.bg-Brand-900 {
  background-color: #6E2303;
}

.text-Scale-50 {
  color: #EEF2FF;
}

.text-Scale-100 {
  color: #E0E7FF;
}

.text-Scale-200 {
  color: #C7D2FE;
}

.text-Scale-300 {
  color: #A5B4FC;
}

.text-Scale-400 {
  color: #818CF8;
}

.text-Scale-500 {
  color: #6366F1;
}

.text-Scale-600 {
  color: #4F46E5;
}

.text-Scale-700 {
  color: #4338CA;
}

.text-Scale-800 {
  color: #3730A3;
}

.text-Scale-900 {
  color: #312E81;
}

.bg-Scale-50 {
  background-color: #EEF2FF;
}

.bg-Scale-100 {
  background-color: #E0E7FF;
}

.bg-Scale-200 {
  background-color: #C7D2FE;
}

.bg-Scale-300 {
  background-color: #A5B4FC;
}

.bg-Scale-400 {
  background-color: #818CF8;
}

.bg-Scale-500 {
  background-color: #6366F1;
}

.bg-Scale-600 {
  background-color: #4F46E5;
}

.bg-Scale-700 {
  background-color: #4338CA;
}

.bg-Scale-800 {
  background-color: #3730A3;
}

.bg-Scale-900 {
  background-color: #312E81;
}

.text-Green-50 {
  color: #ECFDF5;
}

.text-Green-100 {
  color: #D1FAE5;
}

.text-Green-200 {
  color: #A7F3D0;
}

.text-Green-300 {
  color: #6EE7B7;
}

.text-Green-400 {
  color: #34D399;
}

.text-Green-500 {
  color: #10B981;
}

.text-Green-600 {
  color: #059669;
}

.text-Green-700 {
  color: #047857;
}

.text-Green-800 {
  color: #065F46;
}

.text-Green-900 {
  color: #064E3B;
}

.bg-Green-50 {
  background-color: #ECFDF5;
}

.bg-Green-100 {
  background-color: #D1FAE5;
}

.bg-Green-200 {
  background-color: #A7F3D0;
}

.bg-Green-300 {
  background-color: #6EE7B7;
}

.bg-Green-400 {
  background-color: #34D399;
}

.bg-Green-500 {
  background-color: #10B981;
}

.bg-Green-600 {
  background-color: #059669;
}

.bg-Green-700 {
  background-color: #047857;
}

.bg-Green-800 {
  background-color: #065F46;
}

.bg-Green-900 {
  background-color: #064E3B;
}

.text-Amber-100 {
  color: #FEF3C7;
}

.text-Amber-400 {
  color: #F59E0B;
}

.text-Amber-600 {
  color: #D97706;
}

.bg-Amber-100 {
  background-color: #FEF3C7;
}

.bg-Amber-400 {
  background-color: #F59E0B;
}

.bg-Amber-600 {
  background-color: #D97706;
}

.text-Rose-50 {
  color: #FFF1F2;
}

.text-Rose-400 {
  color: #FB7185;
}

.text-Rose-600 {
  color: #E11D48;
}

.bg-Rose-50 {
  background-color: #FFF1F2;
}

.bg-Rose-400 {
  background-color: #FB7185;
}

.bg-Rose-600 {
  background-color: #E11D48;
}

.text-Teal-50 {
  color: #F0FDFA;
}

.text-Teal-400 {
  color: #2DD4BF;
}

.text-Teal-600 {
  color: #0D9488;
}

.bg-Teal-50 {
  background-color: #F0FDFA;
}

.bg-Teal-400 {
  background-color: #2DD4BF;
}

.bg-Teal-600 {
  background-color: #0D9488;
}

.registerATruckOrEdit5PChild {
  width: 1600px;
  height: 1200px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  max-width: 100%;
}

.theLogoMakerBuiltFor1000 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.text {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  height: 44px;
  border-radius: 8px;
  background-color: #fa6f32;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.iconoutlinebell {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.avatarFace04 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  line-height: 16px;
  font-weight: 600;
  z-index: 1;
}

.buttonB,
.photo,
.vt {
  position: relative;
}

.photo {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 3px 2px 2px;
}

.buttonB {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  gap: 8px;
}

.iconsolidmenu {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button3,
.frameC,
.frameD {
  display: flex;
  flex-direction: row;
}

.button3 {
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.frameC,
.frameD {
  align-items: flex-start;
  justify-content: flex-start;
}

.frameD {
  border-radius: 8px;
  background-color: #f1f5f9;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.frameC {
  gap: 12px;
}

.alreadyHaveAccount,
.frameB {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.alreadyHaveAccount {
  flex: 1;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}

.frameB {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 85px;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.inputFieldText {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText {
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.frameH {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #317fd1;
  width: 149px;
  height: 24px;
}

.frameGInner {
  align-self: stretch;
  height: 10px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.frameG {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.frameF {
  width: 1016px;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  color: #64748b;
}


.userName {
  margin: 0;
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  align-self: stretch;
}

.inputText1 {
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  align-self: stretch;

}

.userNameParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.frameInputWithLabel {
  width: 1016px;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 24px;
}

.icon1,
.text1 {
  position: relative;
}

.icon1 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  line-height: 20px;
  font-weight: 600;
}

.textButton1 {
  width: 118px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.inputText2 {
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.iconoutlinedocument {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.addImage {
  font-weight: 600;
}

.addImage,
.clickToSelect {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageButton,
.frameInputWithLabelInputT {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.frameInputWithLabelInputT {
  align-self: stretch;
  align-items: flex-start;
}

.addImageButton {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  align-items: center;
  padding: 32px 31px;
  gap: 4px;
  max-width: 100%;
}

.addImageButtonWrapper,
.frameTextInput {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.addImageButtonWrapper {
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.frameTextInput {
  flex-direction: column;
  gap: 4px;
}

.inputText3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText4 {
  width: 95px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  position: relative;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
  display: flex;
  align-items: center;
}

.basicInput,
.inputWithLabel {
  display: flex;
  justify-content: flex-start;
}

.basicInput {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
}

.inputWithLabel {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 106px;
}

.inputText5 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText6 {
  width: 95px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  position: relative;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
  display: flex;
  align-items: center;
}

.basicInput1,
.inputWithLabel1 {
  display: flex;
  justify-content: flex-start;
}

.basicInput1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
}

.inputWithLabel1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 106px;
}

.frameLabelInputBasicInput,
.frameTextInputParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameLabelInputBasicInput {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.frameTextInputParent {
  flex-direction: column;
  gap: 16px;

  max-width: 100%;
  flex-shrink: 0;
}

.inputFieldInputWithLabel {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #e2e8f0;
}

.inputText7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument1 {
  width: 24px;
  height: 24px;
  position: relative;

  overflow: hidden;
  flex-shrink: 0;
}

.addImage1 {
  font-weight: 600;
}

.addImage1,
.clickToSelect1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.nAThesenodesappeartobenested2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.nAThesenodesappeartobenested1 {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 31px;
  gap: 4px;
  max-width: 100%;
}

.nAThesenodesappeartobenested,
.nAThesenodesappeartobenestedWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.nAThesenodesappeartobenestedWrapper {
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.nAThesenodesappeartobenested {
  flex-direction: column;
  gap: 4px;
}

.inputText8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText9 {
  width: 95px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  position: relative;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
  display: flex;
  align-items: center;
}

.basicInput2,
.inputWithLabel2 {
  display: flex;
  justify-content: flex-start;
}

.basicInput2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
}

.inputWithLabel2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 106px;
}

.inputText10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.basicInput3,
.inputText11 {
  display: flex;
  align-items: center;
}

.inputText11 {
  width: 95px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  position: relative;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
}

.basicInput3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 12px;
}

.inputFieldInputWithLabel2,
.inputWithLabel3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel3 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 106px;
}

.inputFieldInputWithLabel2 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.inputText12 {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  align-self: stretch;
}

.inputFieldInputWithLabel1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  max-width: 100%;
  flex-shrink: 0;
}

.inputFieldInputWithLabel3 {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #e2e8f0;
}

.inputText13 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.iconoutlinedocument2 {
  width: 24px;
  height: 24px;
  position: relative;

  overflow: hidden;
  flex-shrink: 0;
}

.addImage2 {
  font-weight: 600;
}

.addImage2,
.clickToSelect2 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.addImageParent,
.iconoutlinedocumentParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.addImageParent {
  align-self: stretch;
  align-items: flex-start;
}

.iconoutlinedocumentParent {
  flex: 1;
  border-radius: 8px;
  border: 1px dashed #64748b;
  box-sizing: border-box;
  align-items: center;
  padding: 32px 31px;
  gap: 4px;
  max-width: 100%;
}

.frameWrapper,
.inputTextParent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.frameWrapper {
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  color: #64748b;
}

.inputTextParent {
  flex-direction: column;
  gap: 4px;
}

.inputText14,
.inputText15 {
  display: flex;
  align-items: center;
}

.inputText14 {
  height: 20px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText15 {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
}

.basicInput4 {
  align-self: stretch;
  height: 44px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
}

.inputWithLabel4 {
  width: 264px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText17 {
  position: relative;
  line-height: 24px;
}

.basicInput5,
.inputWithLabel5 {
  display: flex;
  justify-content: flex-start;
}

.basicInput5 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel5 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  max-width: 100%;
}

.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 100%;
}

.inputText18 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #64748b;
}

.inputFieldInputWithLabel4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  flex-shrink: 0;
}

.inputText19 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl1 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextGroup,
.tag1,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextGroup,
.tagParent {
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 212px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextGroup {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}

.inputText20 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl2 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl3 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl4 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextContainer,
.tag4,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.inputTextContainer,
.tagGroup {
  justify-content: flex-start;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 454px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}

.fileUploadFrame {
  display: flex;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.nATheseframesseemtoberepeati {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.celesClubPVidiGruzovikhMaIcon {
  width: 536px;
  height: 402px;
  position: relative;
  object-fit: contain;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.theLogoMakerBuiltFor10001 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.frameSettingsFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 37px 0 0;
}

.stockPhotoBigRigGrayBonneIcon {
  width: 564px;
  position: absolute;
  margin: 0 !important;
  top: 307px;
  right: -157px;
  height: 527px;
  object-fit: contain;
  z-index: 1;
}

.inputText21,
.inputText22 {
  align-self: stretch;
  position: relative;
}

.inputText21 {
  height: 56px;
  line-height: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.inputText22 {
  font-size: 14px;
  line-height: 20px;
}

.celesClubPVidiGruzovikhMaParent,
.frameDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.frameDiv {
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  width: 376px;
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: -68px;
  background-color: #cbd5e1;
  overflow: hidden;
  align-items: center;
  padding: 25px 38px 828px 88px;
  box-sizing: border-box;
  gap: 146px;
  max-width: 100%;
  z-index: 1;
  font-size: 18px;
}

.textButtonText {
  position: relative;
  font-family: Inter;
  color: #0f172a;
  text-align: left;
}

.frameA {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.textButtonText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.button4 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #f1f5f9;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button4:hover,
.button5:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
}

.inputWithLabel6 {
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button5 {
  cursor: pointer;
  border: 0;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.text2 {
  color: var(--Basic-White, #FFF);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button6 {
  cursor: pointer;
  border: 0;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Brand-600---main, #FA6F32);
}

.button6:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.buttonFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.addImageButtonFrame {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttonFrame {
  box-sizing: border-box;
  max-width: 100%;
}

.buttonParent {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.buttonFrame {
  justify-content: flex-end;
  padding: 0 20px;
}

.registerATruckOrEdit5P {
  width: 100%;
  border-radius: 20px;
  position: relative;
  background-color: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1rem;
  gap: 16px;
  letter-spacing: normal;
}

.dragAndDrop,
.fileName {
  display: flex;
  width: 100%;
  justify-content: center;
}


@media screen and (max-width: 1000px) {
  .frameA {
    width: 100%;
  }

  .fileUploadFrame {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
  }

  .buttonParent {
    flex-direction: column;
    padding: 1rem;
  }

  .addImageButtonFrame {
    flex-direction: column;
    width: 100%;
  }

  .button5,
  .button6 {
    width: 100%;
  }

}

@media screen and (max-width: 750px) {
  .tagGroup {
    flex-wrap: wrap;
    min-width: 100%;
    margin-left: 0;
  }

}

@media screen and (max-width: 450px) {
  .tagParent {
    margin-left: 0;
  }

  .tagParent {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media screen and (max-width: 500px) {

  .frameTextInputParent,
  .inputFieldInputWithLabel1 {
    min-height: unset;
  }

  .addImageButton,
  .addImageButton,
  .iconoutlinedocumentParent {
    padding: 10px;
  }

  .textButtonText,
  input::placeholder,
  input {
    font-size: small;
  }
}
body {
  background-color: #f8fafc;
}

.celesClubPVidiGruzovikhMaIcon {
  width: 536px;
  height: 402px;
  position: relative;
  object-fit: contain;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.theLogoMakerBuiltFor1000 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.inputFrameA {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 37px 0 0;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  width: 20rem;
  top: 500px;
  left: 30px;
  height: 18rem;
  object-fit: cover;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
  font-family: Inter,sans-serif;
  color: #0f172a;
  text-align: left;
}

.inputText {
  height: 56px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.inputText1 {
  font-size: 14px;
  line-height: 20px;
}

.celesClubPVidiGruzovikhMaParent,
.inputTextParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputTextParent {
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  z-index: 1;
  width: 24rem;
  margin: 0 !important;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
  overflow: hidden;
  align-items: center;
  padding: 25px 38px 828px 88px;
  box-sizing: border-box;
  gap: 146px;
  max-width: 100%;
}

.theLogoMakerBuiltFor10001 {
  /* visibility: hidden; */
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.text {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.button {
  height: 44px;
  border-radius: 8px;
  background-color: #fa6f32;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.iconoutlinebell {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.avatarFace04 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.photo,
.vt {
  position: relative;
}

.vt {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: Inter;
  color: #334155;
  text-align: left;
  z-index: 1;
}

.photo {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 3px 2px 2px;
}

.componentFrame {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #0f172a;
  text-align: left;
}

.button2 {
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  gap: 8px;
}

.iconsolidmenu {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button3 {
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.notificationIcon,
.vTFrame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.vTFrame {
  border-radius: 8px;
  background-color: #f1f5f9;
}

.notificationIcon {
  gap: 12px;
}

.alreadyHaveAccount,
.frameC {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.alreadyHaveAccount {
  flex: 1;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  gap: 20px;
  z-index: 2;
}

.frameC {
  background-color: #f8fafc;
  width: 93%;
  margin: auto;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 41px;
}

.registerATruckOrEdit1PChild {
  width: 1600px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  max-width: 100%;
  z-index: 2;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.text1 {
  color:  #64748B;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText2 {
  color:  #334155;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textFrame,
.textFrameWrapper {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.textFrame {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.textFrameWrapper {
  flex-direction: column;
  justify-content: flex-start;
}

.userName {
  color:  #0F172A;
  font-family: Inter,sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  align-self: stretch;
}

.inputText3 {
  color:  #334155;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  align-self: stretch;
}

.userNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex-shrink: 0;
}

.component35 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.component35Wrapper {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.icon1,
.text2 {
  position: relative;
}

.icon1 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #64748b;
  text-align: left;
}

.textButton1 {
  width: 118px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.inputText4 {
  color: #475569;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.inputText5 {
  width: 100% !important;
  height: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #94A3B8;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.basicInput {
  font-size: 16px !important;
  display: flex;
  justify-content: flex-start;
}

.basicInput {
  display: flex;
  height: 2.75rem;
  padding: 0.125rem 0.75rem 0 0.75rem;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid  #94A3B8;
  background: #FFF;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
  flex: 1 0 0;
}

.inputText6,
.inputText7 {
  position: relative;
  font-family: Inter,sans-serif;
  text-align: left;
}

.inputText6 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #475569;
}

.inputText7 {
  font-size: 16px;
  line-height: 24px;
  color: #94a3b8;
}

.iconsolidcheveronDown {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  white-space: nowrap;
}

.inputWithLabel1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel1 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 169px;
}

.inputWithLabelParent {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.yourNumberWill {
  color:  #475569;
  font-family: Inter,sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.inputField {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.inputText8,
.inputText9 {
  font-family: Inter,sans-serif;
  position: relative;
  text-align: left;
}

.inputText8 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #475569;
}

.inputText9 {
  width: 139px;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  line-height: 24px;
  color: #94a3b8;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}

.basicInput1,
.inputWithLabel2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.basicInput1 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
}

.inputWithLabel2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.inputText10 {
  width: 139px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: right;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl1 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer1 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: right;
}

.inputTextGroup,
.tag1,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextGroup,
.tagParent {
  justify-content: flex-start;
  max-width: 100%;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 212px;
  z-index: 2;
  margin-left: -2px;
}

.inputTextGroup {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
}

.inputText11 {
  width: 139px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl2 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer2 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: right;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl3 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #475569;
  text-align: right;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl4 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475569;
  text-align: right;
}

.inputTextContainer,
.tag4,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.inputTextContainer,
.tagGroup {
  justify-content: flex-start;
  max-width: 100%;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 259px;
  z-index: 2;
  margin-left: -2px;
}

.inputTextContainer {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
}

.registrationFrame {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.registrationFrameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 37.5rem;
  gap: 1rem;
}

.textButton2 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter,sans-serif;
  color: #0f172a;
  text-align: left;
}

.button4 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #f1f5f9;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button4:hover,
.button5:hover {
  background-color: #d9dbe0 !important;
  transition: background-color 0.3s !important;
}

.buttonHide {
  color: #94A3B8;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button5 {
  cursor: pointer;
  border: none;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background:  #F1F5F9;
}

.text3 {
  color: #FFF;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button6 {
  cursor: pointer;
  border: none;
  display: flex;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background:  #FA6F32;
}

.button6:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.buttonText {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttonParent {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.registerATruckOrEdit1P {
  margin: 0;
  background-color: #f8fafc;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0;
  box-sizing: border-box;
  position: relative;
  padding: 1rem;
  gap: 2rem;
  letter-spacing: normal;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media screen and (max-width: 600px) {
  .registrationFrame {
    padding: 1.5rem 1rem;
  }

  .component35 {
    gap: 16px;
  }

  .tagGroup {
    flex-wrap: wrap;
  }

  .textButton2,
  .text3 {
    font-size: small;
  }
}

@media screen and (max-width: 450px) {
  .textFrame {
    flex-wrap: wrap;
  }

  .userName {
    font-size: 19px;
    line-height: 26px;
  }

  .tagParent {
    flex-wrap: wrap;
  }

  .tagGroup,
  .tagParent {
    margin-left: 0;
  }

  .buttonParent {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1000px) {
  .component35Wrapper {
    width: 100%;
  }

  .registrationFrameWrapper {
    width: 100%;
  }

  .registrationFrame {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
  }

  .inputWithLabelParent {
    flex-direction: column;
    gap: 1.5rem;
  }

  .buttonParent {
    padding: 1rem;
  }

  .buttonText {
    flex-direction: column;
    width: 100%;
    gap: 0.625rem;
  }

  .button5,
  .button6 {
    width: 100%;
  }

}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }

}
.unsplashx60ygfg5soyIcon {
  position: absolute;
  top: 34vh;
  right: -10vw;
  width: 924px;
  height: 616px;
  object-fit: cover;
}
.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
}
.inputText {
  line-height: 28px;
  font-weight: 600;
}
.inputText1 {
  font-size: 14px;
  line-height: 20px;
}
.inputTextParent {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.unsplashx60ygfg5soyParent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e9f2f9;
  width: 376px;
  height: 1200px;
  overflow: hidden;
}
.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}
.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button,
.inputTextGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #0f172a;
}
.inputTextGroup {
  position: absolute;
  top: 20px;
  right: 0;
  justify-content: flex-start;
  gap: 12px;
}
.alreadyHaveAccount {
  z-index: 0;
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
  font-size: 16px;
  color: #334155;
}
.vehicleOwner05Child {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}
.icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 16px;
  flex-shrink: 0;
}
.stepcircle,
.textButton {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.textButton {
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}
.stepcircle {
  border-radius: 16px;
  background-color: #fa6f32;
  width: 24px;
  height: 24px;
  justify-content: center;
}
.title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.stepcircleWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.instanceChild {
  position: relative;
  background-color: #cbd5e1;
  width: 32px;
  height: 2px;
}
.stepcircle2 {
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #fa6f32;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.stepcircleWithText2,
.stepcircleWithTextParent,
.textButton1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.stepcircleWithText2 {
  display: flex;
  gap: 16px;
  color: #fa6f32;
}
.stepcircleWithTextParent,
.textButton1 {
  gap: 8px;
}
.stepcircleWithTextParent {
  display: flex;
  color: #0f172a;
}
.textButton1 {
  display: none;
  font-size: 14px;
  color: #64748b;
}
.userName {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.iconParent,
.inputText3 {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}
.iconParent {
  top: 0;
  width: 536px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
}
.inputText3 {
  top: 56px;
  font-size: 14px;
  line-height: 20px;
  color: #334155;
  width: 360px;
}
.groupChild {
  position: absolute;
  top: calc(50% - 46.5px);
  left: 384px;
  border-radius: 20px;
  width: 160px;
  height: 126.88px;
}
.frameParent {
  position: relative;
  width: 544px;
  height: 157px;
  color: #0f172a;
}
.frameChild {
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  background-color: #e2e8f0;
  height: 2px;
}
.radiobuttonIcon {
  position: relative;
  border-radius: 16px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.textDescription {
  position: relative;
  line-height: 24px;
}
.radiobutton {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.radiobutton2 {
  position: relative;
  border-radius: 16px;
  border: 1px solid #64748b;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.radiobuttonParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  color: #0f172a;
}
.button1,
.button2 {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}
.button2 {
  background-color: #f1f5f9;
  color: #94a3b8;
}
.inputTextContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #334155;
}
.inputText6 {
  position: absolute;

  left: 12px;
  line-height: 24px;
}
.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #64748b;
}
.inputWithLabel {
  width: 382px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.button3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
}
.button4,
.inputText7 {
  align-items: center;
  flex-shrink: 0;
}
.button4 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}
.inputText7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  width: 139px;
}
.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.frameDiv,
.tag,
.tagParent {
  flex-direction: row;
  align-items: center;
}
.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}
.frameDiv,
.tagParent {
  justify-content: flex-start;
}
.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}
.frameDiv {
  align-self: stretch;
  display: none;
}
.textButtonGroup {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  background-color: #fff;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px;
  box-sizing: border-box;
  gap: 32px;
}
.frameWrapper {
  align-self: stretch;
  position: relative;
  height: 633px;
  font-size: 16px;
}
.iconGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: #0f172a;
}
.frameContainer {
  align-self: stretch;
  position: relative;
  height: 88px;
  cursor: pointer;
  font-size: 16px;
}
.backButtonParent {
  position: absolute;
  top: 149px;
  left: calc(50% - 196px);
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  font-size: 14px;
  color: #475569;
}
.vehicleOwner05 {
  background-color: #f8fafc;
  width: 100%;
  height: 1285px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  color: #000;
  font-family: Inter, sans-serif;
}

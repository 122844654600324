.navbar {
  width: 100%;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 3;
}

.header {
  display: flex;
  height: 34.25rem;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.headerContent {
  display: flex;
  width: 48.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  color: #FFF;
  font-family: Inter;
  font-style: normal;

}

.title {
  align-self: stretch;
  margin: 0;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3rem;
}

.subTitle {
  align-self: stretch;
  margin: 0;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.container {
  width: 100%;

  background-color: #FFF;
}

.infoWrapper {
  display: flex;
  width: 50%;
  padding: 3rem 0rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.5rem;
}

.infoDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.infoTitle {
  margin: 0;
  color: #0F172A;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.description {
  align-self: stretch;
  margin: 0;
  text-align: justify;
  color: #475569;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.highlightLink {
  color: #FA6F32 !important;
  font-family: Inter !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto !important;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.quoteParent {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
}

.verticalLine {
  width: 0.25rem;
  align-self: stretch;
  background: #CBD5E1;
}

.quote {
  flex: 1 0 0;
  margin: 0;
  color: #0F172A;
  text-align: justify;
  font-family: Inter;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5rem;
}

.tipsList {
  align-self: stretch;
  text-align: justify;
  margin: 0;
  padding: 0 1.3rem;
  color: #475569;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.infoCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.infoImageParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.infoImage {
  width: 100%;
  height: 25.9375rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.infoText {
  color: #475569;
  margin: 0;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.infoDiscription {
  align-self: stretch;
  margin: 0;
  text-align: justify;
  color: #475569;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.button {
  border: none;
  display: flex;
  width: 12.75rem;
  height: 2.75rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #FA6F32;
  color: #FFF;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.informationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10rem;
  width: 100%;
  gap: 0.625rem;
  align-self: stretch;
  background-color: #F8FAFC;
}

.informationSection {
  display: flex;
  padding: 3rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
  border-radius: 1.25rem;
}

.sectionTitle {
  color: #0F172A;
  margin: 0;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.informationGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
}

.cardImage {
  display: flex;
  width: 100%;
  height: 15rem;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.cardTitle {
  align-self: stretch;
  margin: 0;
  color: #1E293B;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.cardText {
  align-self: stretch;
  margin: 0;
  overflow: hidden;
  color: #64748B;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  max-height: calc(3 * 1.5rem);
}

.readTime {
  align-self: stretch;
  margin: 0;
  color: #64748B;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}


@media only screen and (max-width: 1500px) {

  .informationContainer {
    padding: 0 6rem;
  }

  .infoWrapper {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {

  .infoWrapper {
    width: 80%;
  }

  .informationGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1040px) {
  .informationContainer {
    padding: 0 4rem;
  }

  .informationSection {
    padding: 2rem 0;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    height: 30rem;
  }

  .informationGrid {
    grid-template-columns: repeat(1, 1fr);
  }

}

@media only screen and (max-width: 640px) {
  .header {
    height: 30rem;
  }
  .title {
    font-size: 2.25rem;
  }

  .subTitle {
    font-size: 1rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 3rem 1rem;
  }

  .infoWrapper {
    width: 100%;
    padding: 0;
    gap: 2rem;
  }

  .infoImage {
    height: 12rem;
  }

  .informationContainer {
    padding: 0;
  }

  .informationSection {
    padding: 2rem 1rem;
    background-color: #FFF;
  }
}

.registerATruckOrEdit2PChild {
  width: 1600px;
  height: 1200px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  max-width: 100%;
}

.theLogoMakerBuiltFor1000 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.text {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  height: 44px;
  border-radius: 8px;
  background-color: #fa6f32;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  white-space: nowrap;
}

.iconoutlinebell {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.avatarFace04 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  line-height: 16px;
  font-weight: 600;
  z-index: 1;
}

.photo,
.text1,
.vt {
  position: relative;
}

.photo {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 3px 2px 2px;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  gap: 8px;
}

.iconsolidmenu {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.button3 {
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  gap: 12px;
}

.alreadyHaveAccount,
.alreadyHaveAccountWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.alreadyHaveAccount {
  flex: 1;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
}

.alreadyHaveAccountWrapper {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 85px;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.inputPrimary {
  color: var(--Neutral-500, #64748B);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.textButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputText {
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.component35Inner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.textButtonParent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.component35Inner {
  flex-direction: column;
  justify-content: flex-start;
}


.userName {
  align-self: stretch;
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin: 0;
}

.inputText1 {
  align-self: stretch;
  color: var(--Neutral-700, #334155);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.userNameParent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex-shrink: 0;
}

.component35 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.component35Wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.icon1,
.text2 {
  position: relative;
}

.icon1 {
  height: 20px;
  width: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text2 {
  line-height: 20px;
  font-weight: 600;
}

.textButton1 {
  width: 118px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #64748b;
}

.inputText2 {
  color: var(--Neutral-600, #475569);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.inputText3 {
  position: absolute;
  top: calc(50% - 12px);
  left: 12px;
  line-height: 24px;
}

.basicInput1 {
  align-self: stretch;
  height: 44px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  display: none;
  white-space: nowrap;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
}

.earnRevenueFromYourIdleFl {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag {
  display: flex;
  width: 10.4375rem;
  height: 6.25rem;
  padding: 0.375rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.earnRevenueFromYourIdleFl1 {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.earnRevenueFromYourIdleFl1_selected>div>svg>path {
  fill: #fa6f32 !important;
  stroke: #fa6f32 !important;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag1 {
  height: 100px;
  width: 168px;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl2 {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  height: 100px;
  width: 167px;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl3 {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  height: 100px;
  width: 167px;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl4 {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag4 {
  height: 100px;
  width: 168px;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}

.earnRevenueFromYourIdleFl5 {
  width: 32px;
  height: 32px;
  position: relative;
  object-fit: contain;
}

.reefer5 {
  height: 20px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
}

.tag5 {
  height: 100px;
  width: 217px;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  box-sizing: border-box;
  gap: 4px;
}
.tagParentContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.tagParent {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.inputWithLabelParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.25rem;
  align-self: stretch;
}

.inputText4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText5 {
  position: relative;
  line-height: 24px;
}

.iconsolidcheveronDown {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel1,
.inputWithLabelWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel1 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  max-width: 100%;
}

.inputWithLabelWrapper {
  flex-direction: row;
  padding: 0 0 16px;
  box-sizing: border-box;
}

.inputWithLabelWrapper {
  align-self: stretch;
  max-width: 100%;
}

.frameChild {
  height: 0.0625rem;
  align-self: stretch;
  width: 100%;
  background: var(--Neutral-200, #E2E8F0);
}

.basicInputInner {
  height: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 16px;
  box-sizing: border-box;
}

.inputText6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText7 {
  font-size: 16px !important;
  background-color: transparent;
  width: 100% !important;
  height: 100%;
}

.inputWithLabel2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.basicInput2 {
  position: relative;
  display: flex;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-400, #94A3B8);
}

.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
}

.inputWithLabel2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.inputText8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText9 {
  position: relative;
  line-height: 24px;
}

.iconsolidcheveronDown1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown1 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 106px;
}

.inputText10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText11 {
  position: relative;
  line-height: 24px;
}

.basicInput3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px;
  white-space: nowrap;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel4 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 106px;
}

.inputWithLabelContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.inputText12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText13 {
  position: relative;
  line-height: 24px;
}

.iconsolidcheveronDown2 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  white-space: nowrap;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 106px;
}

.inputText14 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText15 {
  position: relative;
  line-height: 24px;
}

.iconsolidcheveronDown3 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  white-space: nowrap;
  font-size: 16px;
  color: #94a3b8;
}

.frameDiv,
.inputWithLabel6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel6 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 106px;
}

.frameDiv {
  align-self: stretch;
}

.frameDiv {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.inputWithLabelGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.basicInputChild,
.frameItem {
  align-self: stretch;
  max-width: 100%;
}

.frameItem {
  flex: 1;
  position: relative;
  background-color: #e2e8f0;
}

.basicInputChild {
  height: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 16px;
  box-sizing: border-box;
}

.inputText16 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText17 {
  position: relative;
  line-height: 24px;
}

.iconsolidcheveronDown4 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.dropdown4,
.inputWithLabel7 {
  align-self: stretch;
  display: flex;
}

.dropdown4 {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 9px 10px 11px;
  gap: 20px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel7 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.inputText18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputText19,
.inputText20 {
  position: relative;
  line-height: 24px;
}

.inputText19 {
  display: none;
}

.inputText20 {
  width: 100%;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  color: #64748b;
  text-align: left;
  display: flex;
  align-items: center;
  min-width: 11px;
}

.inputLeftContent {
  width: 41px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.inputWithPrefixessuffixes {
  align-self: stretch;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  font-size: 16px;
  color: #94a3b8;
}

.inputWithLabel8,
.inputWithLabelParent1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel8 {
  align-self: stretch;
  gap: 4px;
}

.inputWithLabelParent1 {
  flex: 1;
  gap: 8px;
  min-width: 104px;
}

.inputText21 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.basicInput4,
.inputText22 {
  display: flex;
  align-items: center;
}

.inputText22 {
  width: 41px;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  position: relative;
  line-height: 24px;
  color: #94a3b8;
  text-align: left;
}

.basicInput4 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 12px;
}

.frameGroup,
.inputWithLabel9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputWithLabel9 {
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 104px;
}

.frameGroup {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 8px 0 0;
  gap: 8px;
}

.inputText23 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl6 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer6 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag6 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl7 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer7 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextParent,
.tag7,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag7 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextParent,
.tagGroup {
  justify-content: flex-start;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 212px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextParent {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}

.inputText24 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 1;
}

.earnRevenueFromYourIdleFl8 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer8 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag8 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl9 {
  margin: 0;
  height: 32px;
  width: 32px;
  position: relative;
}

.reefer9 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag9 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.earnRevenueFromYourIdleFl10 {
  height: 32px;
  width: 32px;
  position: relative;
  object-fit: contain;
}

.reefer10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag10 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
  white-space: nowrap;
}

.inputTextGroup,
.tagContainer {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tagContainer {
  flex: 1;
  display: flex;
  gap: 8px;
  min-width: 454px;
  max-width: 130%;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextGroup {
  align-self: stretch;
  display: none;
  flex-wrap: wrap;
  row-gap: 20px;
  max-width: 100%;
  font-size: 16px;
}


.basicInput {
  display: flex;
  padding: 2rem;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 1.25rem;
  background: #FFF;
}

.inputLabelWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.celesClubPVidiGruzovikhMaIcon {
  width: 536px;
  height: 402px;
  position: relative;
  object-fit: contain;
  display: none;
  max-width: 100%;
  z-index: 0;
}

.theLogoMakerBuiltFor10001 {
  height: 33px;
  width: 213px;
  position: relative;
  object-fit: cover;
  cursor: pointer;
}

.button4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 37px 0 0;
}

.stockPhotoBigRigGrayBonneIcon {
  width: 564px;
  position: absolute;
  margin: 0 !important;
  top: 307px;
  right: -157px;
  height: 527px;
  object-fit: contain;
  z-index: 1;
}

.inputText25,
.inputText26 {
  align-self: stretch;
  position: relative;
}

.inputText25 {
  height: 56px;
  line-height: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.inputText26 {
  font-size: 14px;
  line-height: 20px;
}

.celesClubPVidiGruzovikhMaParent,
.inputTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inputTextContainer {
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
}

.celesClubPVidiGruzovikhMaParent {
  width: 376px;
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: -68px;
  background-color: #cbd5e1;
  overflow: hidden;
  align-items: center;
  padding: 25px 38px 828px 88px;
  box-sizing: border-box;
  gap: 146px;
  max-width: 100%;
  z-index: 1;
  font-size: 18px;
  color: #0f172a;
}

.text3 {
  position: relative;
  font-family: Inter;
  text-align: left;
}

.frameParent {
  display: flex;
  width: 37.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.text3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button6 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #f1f5f9;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button6:hover,
.button7:hover {
  background-color: #d9dbe0;
}

.inputDropdownFrame {
  color: var(--Neutral-900, #0F172A);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button7 {
  cursor: pointer;
  border: 0;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Neutral-100, #F1F5F9);
}

.text4 {
  color: var(--Basic-White, #FFF);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.button8 {
  cursor: pointer;
  border: 0;
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--Brand-600---main, #FA6F32);
}

.button8:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.buttonParent1 {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.buttonContainer {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background: #FFF;
}

.button5,
.registerATruckOrEdit2P {
  display: flex;
  box-sizing: border-box;
}

.button5 {
  /* width: 848px; */
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 20px;
  max-width: 100%;
}

.registerATruckOrEdit2P {
  width: 100%;
  position: relative;
  background-color: #f8fafc;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  gap: 16px;
  padding: 1rem;
  letter-spacing: normal;
}

@media screen and (max-width: 1000px) {
  .frameParent {
    width: 100%;
  }

  .inputWithLabelContainer {
    flex-direction: column;
  }

  .tagParentContainer {
    flex-direction: column;
  }

  .tag {
    width: 100%;
  }

  .basicInput {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
  }

  .buttonContainer {
    padding: 1rem;
  }

  .buttonParent1 {
    flex-direction: column;
    width: 100%;
  }

  .button7,
  .button8 {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .component35 {
    gap: 16px;
  }

  .tagContainer {
    flex-wrap: wrap;
    min-width: 100%;
    margin-left: 0;
  }

  .frameParent {
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {
  .textButtonParent {
    flex-wrap: wrap;
  }

  .userName {
    font-size: 19px;
    line-height: 26px;
  }

  .tagGroup {
    flex-wrap: wrap;
    margin-left: 0;
  }

}

.selectedTag {
  background-color: #feede5;
  border: 1px solid #fa6f32;
  color: #fa6f32;
}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
}

@media screen and (max-width: 495px) {
  .component35Wrapper {
    padding: 0px;
  }

  .text3 {
    font-size: small;
  }
}

.userName {
  position: absolute;
  top: 14px;
  left: 32px;
  line-height: 20px;
  font-weight: 600;
}

.iconsolidx {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.button {
  position: absolute;
  top: calc(50% - 18px);
  right: 12px;
  border-radius: 8px;
  background-color: #f1f5f9;
  border: 1px solid #64748b;
  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
}
.frameChild,
.header {
  align-self: stretch;
  position: relative;
}
.header {
  background-color: #fff;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameChild {
  background-color: #d9d9d9;
  height: 1px;
}
.inputText,
.userName1 {
  position: relative;
  font-weight: 600;
}
.userName1 {
  align-self: stretch;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
}
.inputText {
  line-height: 20px;
}
.inputText1 {
  position: absolute;
  height: 100%;
  left: 12px;
  line-height: 24px;
}
.basicInput {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size: 16px;
  color: #94a3b8;
}
.inputWithLabel {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.privacyPolicy {
  text-decoration: underline;
  color: #dc4705;
}
.inputText2 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  display: none;
  color: #334155;
}
.inputWithLabelParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  font-size: 14px;
}
.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button1,
.inputText3 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.button1 {
  border: none;
  align-self: stretch;
  border-radius: 8px;
  background-color: #FA6F32;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}

.button1:hover {
  background-color: #e0571a !important;
  transition: background-color 0.3s !important;
}

.inputText3 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  width: 139px;
}
.earnRevenueFromYourIdleFl {
  position: relative;
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.inputTextParent,
.tag,
.tagParent {
  flex-direction: row;
  align-items: center;
}
.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}
.inputTextParent,
.tagParent {
  justify-content: flex-start;
}
.tagParent {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}
.inputTextParent {
  align-self: stretch;
  display: none;
}
.dialogWindow,
.rectangleParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rectangleParent {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 32px 32px;
  gap: 24px;
  font-size: 16px;
}
.dialogWindow {
  position: relative;
  margin-top: 300px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(18, 27, 33, 0.2);
  overflow: hidden;
  align-items: center;
  text-align: left;
  font-size: 14px;
  color: #475569;
  font-family: Inter;
}

.buttonDisabled {
  background-color: #c8c9ca;
}

.forgotPassword {
  background-color: #f5f5f5;
  display: flex;
  border-radius: 20px;
  justify-content: center;
}

@media (max-width: 850px) {
  .dialogWindow {
    margin-top: 160px;
    box-shadow: unset;
    background-color: unset;
    width: 100%;
  }
  .header {
    background-color: unset;
  }
}

.mainContainer {
  overflow: hidden;
}

.mainContainer,
.mainContainer * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 1200px;
  padding: 20px 24px 20px 24px;
  overflow: hidden;
}
.logo {
  flex-shrink: 0;
  position: relative;
  width: 137.322px;
  height: 32px;
  z-index: 1;
}
.royban {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-size: 100% 100%;
  z-index: 2;
}
.frame {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  width: 264px;
  z-index: 3;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 120px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  background: #fa6f32;
  border: none;
  z-index: 4;
  overflow: hidden;
  border-radius: 8px;
}
.listVehicle {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 5;
}
.rectangle {
  flex-shrink: 0;
  position: relative;
  width: 1px;
  height: 24px;
  background: #e2e8f0;
  z-index: 6;
}
.frame1 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  width: 79px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 7;
}

.featuresContainer {
  width: 100%;
}

.featuresSection {
  margin: 0 12vw;
}

.button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 79px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  background: #f1f5f9;
  z-index: 8;
  overflow: hidden;
  border-radius: 8px;
}
.logIn {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #0f172a;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 9;
}
.heroHeaderSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 720px;
  background: #ffffff;
  z-index: 10;
  overflow: hidden;
}

.section5 > img {
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 11;
  height: inherit;
}
.section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 64px;
  position: relative;
  width: 50%;
  z-index: 12;
}
.container3 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 73%;
  height: 100%;

  z-index: 13;
}
.headingSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  justify-content: center;
  width: 100%;
  z-index: 14;
}
.headingBadge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 15;
}
.subheading {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #fa6f32;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 16;
}
.heading {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 544px;
  height: 180px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  letter-spacing: -0.96px;
  z-index: 17;
}
.supportingText {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 480px;
  height: 90px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  z-index: 18;
}
.button4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  width: 116px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  background: #fa6f32;
  border: none;
  z-index: 19;
  overflow: hidden;
  border-radius: 8px;
}
.applyNow {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #ffffff;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 20;
}
.section5 {
  width: 55%;
  margin: auto;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  background-size: cover;
  z-index: 21;
  border-radius: 8px;
}
.backgroundPattern {
  flex-shrink: 0;
  position: relative;
  width: 1920px;
  height: 1440px;
  z-index: 22;
  overflow: hidden;
}
.featuresSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 64px;
  position: relative;
  min-width: 0;
  padding: 96px 0 96px 0;
  z-index: 23;
  overflow: hidden;
}
.container6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  align-self: baseline;
  z-index: 24;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  z-index: 25;
}
.headingSupportingText7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;

  z-index: 26;
}
.headingSubheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  min-width: 0;
  z-index: 27;
}
.values {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 83px;
  height: 24px;
  color: #fa6f32;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  z-index: 28;
}
.whyRentOutVehicles {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 487px;
  height: 44px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;

  letter-spacing: -0.72px;
  z-index: 29;
}
.container8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 64px;
  position: relative;
  width: 100%;
  z-index: 30;
}
.content9 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 31;
}
.featureText {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 20px;
  position: relative;
  z-index: 32;
}
.featuredIcon {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 33;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.chartBreakoutSquare {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 34;
  overflow: hidden;
}
.textSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  z-index: 35;
}
.avoidExpensiveStorage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  z-index: 36;
}

.supportingTextA {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 48px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 37;
}
.featureTextB {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 384px;
  z-index: 38;
}
.featuredIconC {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 39;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.messageSmileCircle {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 40;
  overflow: hidden;
}
.textSupportingTextD {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  min-width: 0;
  z-index: 41;
}
.increaseVehicleUtilization {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  z-index: 42;
}
.maximizeEfficiency {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 72px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 43;
}
.featureTextE {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 20px;
  position: relative;
  z-index: 44;
}
.featuredIconF {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 45;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.zap {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 46;
  overflow: hidden;
}
.textSupport {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  z-index: 47;
}
.offsetTransportation {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  z-index: 48;
}
.payOffCosts {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 49;
}
.content10 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 50;
  justify-content: space-between;
}
.featureText11 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 384px;
  z-index: 51;
}
.featuredIcon12 {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 52;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.messageChatCircle {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 53;
  overflow: hidden;
}
.textSupport13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  min-width: 0;
  z-index: 54;
}
.generateRevenue {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  z-index: 55;
}
.earnThousands {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 48px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 56;
}
.featureText14 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 384px;
  z-index: 57;
}
.featuredIcon15 {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 58;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.barChart {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 59;
  overflow: hidden;
}
.textSupport16 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  min-width: 0;
  z-index: 60;
}
.seasonalNeeds {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  z-index: 61;
}
.profitDowntime {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 48px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 62;
}
.featureText17 {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 384px;
  z-index: 63;
}
.featuredIcon18 {
  flex-shrink: 0;
  position: relative;
  width: 48px;
  height: 48px;
  background: #fa6f32;
  border: 8px solid #fdc9b2;
  z-index: 64;
  border-radius: 70px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.fleetExpansion {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 12px 0 0 12px;

  background-size: cover;
  z-index: 65;
  overflow: hidden;
}
.increaseProfits {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 8px;
  position: relative;
  min-width: 0;
  z-index: 66;
}
.text13 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 30px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;

  z-index: 67;
}
.text14 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 48px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 68;
}
.blogSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 64px;
  position: relative;
  min-width: 0;
  padding: 96px 0 96px 0;
  background: #f8f9fb;
  z-index: 69;
  overflow: hidden;
}
.container19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;

  z-index: 70;
}
.content1a {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  z-index: 71;
}
.headingSupport {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;

  z-index: 72;
}
.frame1b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  min-width: 0;
  z-index: 73;
}
.subheading1c {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 63px;
  height: 24px;
  color: #fa6f32;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  z-index: 74;
}
.howTruckSharing {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 44px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: left;

  letter-spacing: -0.72px;
  z-index: 75;
}
.container1d {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;
  width: -webkit-fill-available; /* For WebKit browsers */
  width: -moz-available; /* For Firefox */
  width: -webkit-available;
  margin: 0 12vw;
  z-index: 76;
}
.content1e {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 77;
}
.blogPostCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 78;
}
.image {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 240px;
  width: 280px;
  background-size: cover;
  z-index: 79;
  overflow: hidden;
  border-radius: 8px;
}

.image > img {
  height: inherit;
  object-fit: cover;
}

.container28Parent {
  width: 100%;
}

.listCommercialVehicle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  z-index: 80;
}
.createAccount {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 268px;
  height: 28px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 81;
}
.startRenting {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 286px;
  height: 72px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 82;
}
.blogPostCard1f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 83;
}
.manWithPhone {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 240px;

  background-size: cover;
  z-index: 84;
  overflow: hidden;
  border-radius: 8px;
}
.setAvailability {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  z-index: 85;
}
.text19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 268px;
  height: 28px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 86;
}
.supportingText20 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 286px;
  height: 72px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 87;
}
.blogPostCard21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 88;
}
.image22 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 240px;

  background-size: cover;
  z-index: 89;
  overflow: hidden;
  border-radius: 8px;
}
.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  z-index: 90;
}
.approveReservations {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 268px;
  height: 28px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 91;
}
.supportingText23 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 286px;
  height: 72px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 92;
}
.blogPostCard24 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  gap: 24px;
  position: relative;
  z-index: 93;
}
.image25 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 240px;

  background-size: cover;
  z-index: 94;
  overflow: hidden;
  border-radius: 8px;
}
.headingText26 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  z-index: 95;
}
.sitBackEarn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 268px;
  height: 28px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 96;
}
.supportingText27 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 286px;
  height: 72px;
  color: #475466;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 97;
}
.container28 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  margin: 0 12vw;
  padding: 96px 0 96px 0;
  z-index: 98;
  justify-content: space-between;
}
.image29 {
  flex-shrink: 0;
  position: relative;
  width: 596px;
  height: 528px;

  background-size: cover;
  z-index: 99;
  border-radius: 8px;
}
.frame2a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 48px;
  position: relative;
  min-width: 0;
  padding: 0 0 0 32px;
  z-index: 100;
}

.frame2a > div {
  /* width: fit-content; */
}
.frame2b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 12px;
  position: relative;
  width: 511px;
  z-index: 101;
}
.testimonials {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 98px;
  height: 24px;
  color: #fa6f32;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  z-index: 102;
}
.howTruckSharingWorks {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  width: 511px;
  height: 44px;
  color: #0f1728;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: left;

  letter-spacing: -0.72px;
  z-index: 103;
}
.testimonialsTestimonial {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 104;
}
.icon {
  flex-shrink: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  top: -5px;
  left: -9px;

  background-size: cover;
  z-index: 105;
  overflow: hidden;
}
.leadingContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 106;
}
.text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 84px;
  color: #1e293b;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 107;
}
.cite {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 108;
}
.avatar {
  flex-shrink: 0;
  position: relative;
  width: 52px;
  height: 52px;

  background-size: cover;
  z-index: 109;
  overflow: hidden;
  border-radius: 26px;
}
.text2c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 140px;
  z-index: 110;
}
.name {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #1e293b;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 111;
}
.title {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #fca57f;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  z-index: 112;
}
.testimonialsTestimonial2d {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 113;
}
.icon2e {
  flex-shrink: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  top: -5px;
  left: -9px;

  background-size: cover;
  z-index: 114;
  overflow: hidden;
}
.leadingContent2f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 115;
}
.text30 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;

  height: 84px;
  color: #1e293b;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 116;
}
.cite31 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  z-index: 117;
}
.avatar32 {
  flex-shrink: 0;
  position: relative;
  width: 52px;
  height: 52px;

  background-size: cover;
  z-index: 118;
  overflow: hidden;
  border-radius: 26px;
}
.text33 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 250px;
  z-index: 119;
}
.name34 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #1e293b;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  z-index: 120;
}
.title35 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 250px;
  height: 48px;
  color: #fca57f;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-overflow: initial;
  z-index: 121;
  overflow: hidden;
}
.container36 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  padding: 96px 12vw 96px 12vw;
  background: #f8fafc;
  z-index: 122;
}
.frame37 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  z-index: 123;
}
.accordion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 20px;
  position: relative;
  width: 50%;
  z-index: 124;
}
.topBorder {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 1px;
  background: #e5e7eb;
  z-index: 125;
}
.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  min-width: 0;
  z-index: 126;
}
.faqsAccordionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 127;
}
.content38 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  padding: 24px 0 0 0;
  z-index: 128;
}
.toggle {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 129;
}
.whatHappensIf {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 130;
}
.icon39 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 28px;
  z-index: 131;
}
.chevronUp {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;

  background-size: cover;
  z-index: 132;
  overflow: hidden;
}
.panel {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 0 48px 0 0;
  z-index: 133;
}
.text3a {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  width: 516px;
  height: 48px;
  color: #6b7280;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 134;
}
.faqsAccordionSection3b {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 135;
}
.topBorder3c {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 1px;
  background: #e5e7eb;
  z-index: 136;
}
.content3d {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  padding: 24px 0 0 0;
  z-index: 137;
}
.toggle3e {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 138;
}
.heading3f {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 139;
}
.icon40 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 28px;
  z-index: 140;
}
.chevronDown {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;

  background-size: cover;
  z-index: 141;
  overflow: hidden;
}
.faqsAccordionSection41 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 142;
}
.topBorder42 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 1px;
  background: #e5e7eb;
  z-index: 143;
}
.content43 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  padding: 24px 0 0 0;
  z-index: 144;
}
.toggle44 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 145;
}
.heading45 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative;
  width: 516px;
  height: 56px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  z-index: 146;
}
.icon46 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 28px;
  z-index: 147;
}
.chevronDown47 {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;

  background-size: cover;
  z-index: 148;
  overflow: hidden;
}
.faqsAccordionSection48 {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 149;
}
.topBorder49 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 1px;
  background: #e5e7eb;
  z-index: 150;
}
.content4a {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  padding: 24px 0 0 0;
  z-index: 151;
}
.toggle4b {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 152;
}
.heading4c {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 28px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;

  z-index: 153;
}
.icon4d {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 28px;
  z-index: 154;
}
.chevronDown4e {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;

  background-size: cover;
  z-index: 155;
  overflow: hidden;
}
.header4f {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  z-index: 156;
  width: 40%;
}
.headingSupportingText50 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 157;
}
.heading51 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  z-index: 158;
}
.frequentlyAskedQuestions {
  position: relative;
  color: #0f172a;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}
.asked {
  position: relative;
  color: #0f172a;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}
.questions {
  position: relative;
  color: #0f172a;
  font-family: Inter, var(--default-font-family);
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
}
.supportingText52 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  z-index: 159;
}
.moreInformationCommonQuestions {
  position: relative;
  color: #64748b;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}
.helpCenter {
  position: relative;
  color: #64748b;
  font-family: Inter, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 1600px;
  z-index: 160;
}
.companyMissionDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;
  min-width: 0;
  height: 453px;
  background: #334155;
  z-index: 161;
}
.container53 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 48px;
  position: relative;

  padding: 64px 32px 64px 32px;
  z-index: 162;
}
.headerLinks {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 220px;
  z-index: 163;
}
.header54 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 32px;
  position: absolute;
  top: 0;
  right: 832px;
  bottom: 0;
  left: 0;
  z-index: 164;
}
.logo55 {
  flex-shrink: 0;
  position: relative;
  width: 43.75px;
  height: 40px;

  background-size: cover;
  z-index: 165;
  overflow: hidden;
}
.supportingText56 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  width: 384px;
  min-width: 0;
  height: 48px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  z-index: 166;
}
.socialLinks {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 24px;
  position: relative;
  width: 24px;
  z-index: 167;
}
.socialLink {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 24px;
  z-index: 168;
}
.socialIcon {
  flex-shrink: 0;
  position: relative;
  width: 24px;
  height: 24px;

  background-size: cover;
  z-index: 169;
  overflow: hidden;
}
.links {
  position: absolute;
  height: 220px;
  top: 0;
  right: 0;
  left: 416px;
  z-index: 170;
  overflow: hidden;
}
.column {
  position: absolute;
  height: 220px;
  top: 0;
  right: 0;
  left: 416px;
  z-index: 171;
}
.footersColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  height: 220px;
  top: 0;
  right: 208px;
  left: 0;
  z-index: 172;
}
.heading57 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 20px;
  color: #cbd5e1;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  z-index: 173;
}
.links58 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 174;
}
.footersTextLink {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 46px;
  z-index: 175;
}
.text59 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 176;
}
.footersTextLink5a {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 34px;
  z-index: 177;
}
.text5b {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 178;
}
.footersTextLink5c {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 37px;
  z-index: 179;
}
.text5d {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 180;
}
.footersTextLink5e {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 42px;
  z-index: 181;
}
.text5f {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 182;
}
.footersTextLink60 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 65px;
  z-index: 183;
}
.text61 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 184;
}
.footersColumn62 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  height: 140px;
  top: 0;
  right: 0;
  left: 208px;
  z-index: 185;
}
.heading63 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 20px;
  color: #cbd5e1;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  z-index: 186;
}
.links64 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 187;
}
.footersTextLink65 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 112px;
  z-index: 188;
}
.text66 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 189;
}
.footersTextLink67 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 88px;
  z-index: 190;
}
.text68 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 191;
}
.footersTextLink69 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 83px;
  z-index: 192;
}
.text6a {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 193;
}
.column6b {
  position: absolute;
  height: 220px;
  top: 0;
  right: 416px;
  left: 0;
  z-index: 194;
}
.footersColumn6c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  height: 100px;
  top: 0;
  right: 208px;
  left: 0;
  z-index: 195;
}
.heading6d {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 20px;
  color: #cbd5e1;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  z-index: 196;
}
.links6e {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 197;
}
.footersTextLink6f {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 111px;
  z-index: 198;
}
.text70 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 199;
}
.footersTextLink71 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 145px;
  z-index: 200;
}
.text72 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 201;
}
.footersColumn73 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 16px;
  position: absolute;
  height: 60px;
  top: 0;
  right: 0;
  left: 208px;
  z-index: 202;
}
.heading74 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 20px;
  color: #cbd5e1;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  z-index: 203;
}
.links75 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 16px;
  position: relative;
  min-width: 0;
  z-index: 204;
}
.footersTextLink76 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 123px;
  z-index: 205;
}
.text77 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #94a3b8;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  z-index: 206;
}
.copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  z-index: 207;
}
.topBorder78 {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  height: 1px;
  background: #e2e8f0;
  z-index: 208;
}
.text79 {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  padding: 32px 0 0 0;
  z-index: 209;
}
.copyright7a {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 24px;
  color: #cbd5e1;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  z-index: 210;
}
.sectionDivider {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  background: #ffffff;
  z-index: 211;
}
.container7b {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 32px;
  position: relative;

  z-index: 212;
}
.divider {
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  height: 1px;

  background-size: cover;
  z-index: 213;
}

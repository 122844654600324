.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
}

.iconoutlinebell {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.avatarFace04 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}

.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}

.photo,
.text1 {
  position: relative;
}

.photo {
  width: 20px;
  border-radius: 10px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}

.button2 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}

.button3,
.iconsolidmenu {
  overflow: hidden;
  flex-shrink: 0;
}

.iconsolidmenu {
  width: 24px;
  position: relative;
  border-radius: 10px;
  height: 24px;
}

.button3 {
  width: 44px;
  border-radius: 0 8px 8px 0;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.button3,
.buttonGroup,
.buttonParent {
  display: flex;
  flex-direction: row;
}

.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 12px;
  color: #334155;
}

.buttonParent {
  position: absolute;
  top: calc(50% - 22px);
  right: 0;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.theLogoMakerBuiltFor1000 {
  position: fixed;
  top: calc(5% - 17px);

  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.alreadyHaveAccount {
  position: absolute;
  top: 0;
  left: calc(50% - 612px);
  width: 1224px;
  height: 84px;
  overflow: hidden;
}

.registerATruckOrEdit7PChild {
  position: absolute;
  top: 0;
  left: calc(50% - 800px);
  background-color: rgba(0, 0, 0, 0.3);
  width: 1600px;
  height: 1200px;
  display: none;
}

.icon,
.text2 {
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text2 {
  line-height: 20px;
  font-weight: 600;
}

.textButton {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #64748b;
}

.icon1 {
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}

.userName {
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.iconParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 20px;
  color: #0f172a;
}

.inputText,
.inputText1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.inputTextParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #334155;
}

.text3 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button4,
.inputText2 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.subscriptionCard {
    margin-left: 24rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.button4 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  color: #fff;
}

.inputText2 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.earnRevenueFromYourIdleFl {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl1 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextGroup,
.tag1,
.tagParent {
  flex-direction: row;
  align-items: center;
}

.tag1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextGroup,
.tagParent {
  justify-content: flex-start;
}

.tagParent {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextGroup {
  width: 836px;
  display: none;
}

.inputText3 {
  width: 139px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.earnRevenueFromYourIdleFl2 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag2 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl3 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer3 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.tag3 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.earnRevenueFromYourIdleFl4 {
  width: 32px;
  position: relative;
  height: 32px;
  object-fit: cover;
}

.reefer4 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.inputTextContainer,
.tag4,
.tagGroup {
  flex-direction: row;
  align-items: center;
}

.tag4 {
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  gap: 4px;
}

.inputTextContainer,
.tagGroup {
  justify-content: flex-start;
}

.tagGroup {
  flex: 1;
  display: flex;
  gap: 8px;
  margin-left: -2px;
  text-align: right;
  font-size: 14px;
}

.inputTextContainer {
  width: 836px;
  display: none;
}

.modal {
  display: flex;
  width: 37.5rem;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.25rem;
  background: #FFF;
}

.celesClubPVidiGruzovikhMaIcon {
  position: absolute;
  top: 457px;
  left: -117px;
  width: 536px;
  height: 402px;
  object-fit: contain;
  display: none;
}

.inputText4,
.inputText5 {
  align-self: stretch;
  position: relative;
}

.inputText4 {
  line-height: 28px;
  font-weight: 600;
}

.inputText5 {
  font-size: 14px;
  line-height: 20px;
}

.frameDiv {
  position: absolute;
  top: 204px;
  left: 88px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}

.stockPhotoBigRigGrayBonneIcon {
  position: absolute;
  top: 307px;
  left: -31px;
  width: 564px;
  height: 527px;
  object-fit: contain;
}

.theLogoMakerBuiltFor10001 {
  position: absolute;
  top: calc(50% - 575px);
  left: 88px;
  width: 213px;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}

.celesClubPVidiGruzovikhMaParent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #cbd5e1;
  width: 376px;
  height: 1200px;
  overflow: hidden;
  font-size: 18px;
  color: #0f172a;
}

.registerATruckOrEdit7P {
  width: 100%;
  position: relative;
  background-color: #f8fafc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  gap: 16px;
  letter-spacing: normal;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media screen and (max-width: 1250px) {
  .celesClubPVidiGruzovikhMaParent {
    display: none;
  }
    .subscriptionCard {
        margin-left: 0;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


@media screen and (max-width: 1000px) {
  .modal {
    margin-top: 5rem;
    padding: 1.5rem 1rem;
    width: 100%;
  }

}
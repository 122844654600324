.mainContent {
  display: flex;
  margin: 0 13vw;
  gap: 2vw;
}

.closeIcon {
  position: absolute;
  z-index: 2;
  top: 9%;
  width: 20px;
  height: 20px;
  right: 7.5%;
  cursor: pointer;
}

.MUITabs {
  display: flex;
  flex-direction: row;
}

.closeIcon:hover {
  fill: gray;
  opacity: 0.7;
}

.inputText {
  position: absolute;
  height: 100%;
  height: 100%;
  left: 12px;
  line-height: 24px;
}
.basicInput {
  flex: 1;
  position: relative;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  font-size:14px !important;
}

.hoveredMarker {
  z-index: 9999 !important;
}

.hoveredMarker > div {
  border: 4px solid #fa6f32 !important;
  color: black !important;
  transition: background-color 0.6s ease-in-out, color 0.6s ease-in-out;
}

.customMarker > div {
  transition: background-color 0.6s ease-in-out, color 0.6s ease-in-out;
}
.iconoutlinesearch {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.inputLeftContent {
  position: absolute;
  height: 100%;
  height: 100%;
  left: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.popupStacked > div {
  display: flex;
  flex-direction: row;
  padding-bottom: 2%;
  margin-top: 2%;
  margin: 2%;
  border-bottom: 1px solid lightgray;
}

.popupStacked > div > .vecicleCard2 {
  height: 10vh;
  width: 90px;
}

.popupStacked > div .vecicleCard2 > div:nth-child(2) {
  border-radius: 8px !important;
}

.popupStacked > div .vecicleCard2 > div:nth-child(2) > div {
  min-height: unset !important;
  height: 12vh;
  /* width: 10vw; */
}

.popupStacked > div .vecicleCard2 > div:nth-child(2) > div > img {
  height: inherit !important;
}

.basicInput1 {
  flex: 1;
  position: relative;
  border-radius: 0 8px 8px 0;
  background-color: #fff;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  height: 44px;
  overflow: hidden;
  margin-left: -1px;
}
.basicInputParent {
  order: 2;
  width: 24vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}
.button {
  border-radius: 8px;
  background-color: #fa6f32;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  z-index: 0;
}
.iconoutlinebell {
  position: relative;
  border-radius: 10px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button1 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}
.vt {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  line-height: 16px;
  font-weight: 600;
}
.photo,
.text1 {
  position: relative;
}
.photo {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}
.text1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #0f172a;
}
.button2 {
  border-radius: 8px 0 0 8px;
  height: 44px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  gap: 8px;
}

.button2:hover {
  background-color: #d9dbe0 !important;
  border-radius: 8px;
  transition: background-color 0.3s !important;
}

.button2,
.button3,
.buttonGroup {
  display: flex;
  flex-direction: row;
}
.button3 {
  border-radius: 0 8px 8px 0;
  width: 44px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}
.buttonGroup {
  border-radius: 8px;
  background-color: #f1f5f9;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 2;
  font-size: 12px;
  color: #334155;
}
.frameChild {
  position: absolute;
  margin: 0 !important;
  top: 12px;
  left: 152px;
  border-radius: 40px;
  background-color: #fe0000;
  border: 1px solid #f1f5f9;
  box-sizing: border-box;
  width: 9px;
  height: 9px;
  z-index: 3;
}
.buttonParent {
  display: flex;
  order: 3;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: #fff;
}
.theLogoMakerBuiltFor1000 {
  order: 1;
  top: calc(50% - 17px);
  left: 0;
  width: 12vw;
  height: 33px;
  object-fit: cover;
  cursor: pointer;
}
.frameParent {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}
.alreadyHaveAccount {
  /*border:1px solid #e2e8f0;*/
  padding: 0 12vw;
  background:#fff;
  flex-direction: column;
  z-index: 3;
  display: flex;
  top: 0;
  left: 10%;
  height: 84px;
  font-size: 16px;
}

.emptyDivParent {
  padding: 0 12vw;
}
.borderBottom {
  border-top:1px solid #e2e8f0;
  border-bottom:1px solid #e2e8f0;

}

.iconsolidchevronLeft {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button4,
.button5,
.button6 {
  position: absolute;
  top: 176px;
  left: 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  height: 56px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
}
.button5,
.button6 {
  top: 0;
  left: 0;
  border-radius: 8px 8px 0 0;
}
.button6 {
  top: 56px;
  border-radius: 0 0 8px 8px;
}
.groupDiv,
.image76Icon {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 56px;
  height: 112px;
}
.image76Icon {
  top: 821px;
  left: 0;
  width: 801px;
  height: 26px;
  object-fit: cover;
}

.filterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vh;
}
.component13,
.component14,
.component15 {
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
}
.component13 {
  position: absolute;
  top: 375px;
  left: 196px;
  overflow: hidden;
}
.component14,
.component15 {
  top: 556px;
}
.component14 {
  position: absolute;
  left: 175px;
  overflow: hidden;
}
.component15 {
  left: 394px;
}
.buttonContainer,
.component15,
.component16 {
  position: absolute;
  overflow: hidden;
}

.component16 {
  top: 308px;
  left: 405px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
}
.buttonContainer {
  position: relative;
  width: 41vw;
  height: 70vh;
  background-image: url(/public/Map_Page/frame-2394@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 16px;
  color: #000;
}
.button7,
.frameDiv {
  display: flex;
  flex-direction: row;
}
.button7 {
  border-radius: 8px;
  background-color: #f1f5f9;
  height: 45px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  gap: 6px;
}
.frameDiv {
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.clickHereToResetFilters {
  cursor: pointer;
  color: #fa6f32;
}

.resultsFound {
  /* margin-top: 8px; */
  display: flex;
  flex-direction: column;
  align-self: center;
  line-height: 20px;
  color: #000;
}
.frameGroup {
  margin-top: 2vh;
  width: 600px;
  height: 36px;
  color: #0f172a;
}
.frameInner,
.frameItem {
  position: relative;
  border-radius: 50%;
  background-color: #fa6f32;
  width: 8px;
  height: 8px;
}
.frameInner {
  background-color: #fff;
  opacity: 0.5;
}
.ellipseParent {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.mdicardsHeartOutlineIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  z-index: 999;
}

.popupParent {
  background-color: red !important;

  padding: 15px;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.leaflet-popup-content-wrapper {
  background-color: red !important;
}

.leaflet .premium {
  position: relative;
  line-height: 140%;
}
.badgesOnCard {
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 4px;
  background-color: #ffba0a;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 6px;
  gap: 4px;
}
.frameParent1 {
  align-self: stretch;
  position: relative;
  border-radius: 8px;
  height: 157px;
  background-image: url(/public/Map_Page/frame-2403@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.vecicleCardrectangle1271Child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #cbd5e1;
}
.vecicleCardrectangle1271 {
  position: relative;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  overflow: hidden;
  flex-shrink: 0;
}
.tandemTractor {
  position: relative;
  line-height: 24px;
  text-align: end;
}
.peterbilt579Parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-size: 15px;
}
.materialSymbolsstarIcon {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsstarParent {
  gap: 4px;
}
.frameWrapper,
.materialSymbolsstarParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconoutlinetruck {
  position: absolute;
  top: 28px;
  left: -22px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
}
.k,
.mileage {
  position: relative;
  display: flex;
  line-height: 20px;
}

.iconoutlinetruckParent {
  width: 100% !important;
  display: flex;
  gap: 1vh;
}

.iconoutlinetruckParent,
.pointMapIcon {
  position: relative;
  width: 90px;
  height: 20px;
}
.pointMapIcon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.milesAway {
  position: relative;
  line-height: 20px;
}
.toledoOhio43612Parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  color: #64748b;
}
.span {
  font-weight: 600;
}
.component31Inner,
.dayWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.component31Inner {
  align-self: stretch;
  align-items: center;
}
.component31 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: #000;
  padding-top: 0.5vh;
}

.component31 > .peterbilt579Parent {
  padding-top: 1vh;
}

.component31,
.vecicleCard,
.vecicleCardParent {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.vecicleCardParent {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  width: 31vw;
  overflow-x: auto;
}

.vecicleCardParent::-webkit-scrollbar {
  padding-top: 20px;
  display: initial;
  height: 3px;
}

.vecicleCardParent::-webkit-scrollbar-thumb {
  background-color: #fa6f32;
  border-radius: 10px;
}

.vecicleCardParent::-webkit-scrollbar-track {
  background-color: transparent;
}

.vecicleCardParent::-webkit-scrollbar-corner {
  background-color: transparent;
}

.vecicleCardParent > .vecicleCard2 {
  min-width: 15vw;
}

.frameChild7 {
  position: relative;
  border-radius: 50%;
  background-color: #0f172a;
  width: 8px;
  height: 8px;
  opacity: 0.3;
}
.badgesOnCard2,
.badgesOnCard3 {
  border-radius: 4px;
  flex-direction: row;
  padding: 2px 6px;
}
.badgesOnCard2 {
  background-color: #3c85eb;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.badgesOnCard3 {
  background-color: #ffba0a;
}
.badgesOnCard3,
.badgesOnCard4,
.badgesOnCardParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.badgesOnCard4 {
  border-radius: 4px;
  background-color: #38b762;
  flex-direction: row;
  padding: 2px 6px;
}
.badgesOnCardParent {
  position: absolute;
  top: 12px;
  left: 12px;
  flex-direction: column;
  gap: 6px;
}
.frameParent3 {
  align-self: stretch;
  position: relative;
  /* border-radius: 8px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 19vh;
}

.frameParent3 > a > img {
  height: 170px;
  object-fit: cover;
}

.component32 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.component32,
.vecicleCard2,
.vecicleCardGroup {
  display: flex;
  justify-content: flex-start;
}
.vecicleCard2 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-height: 100%;
}

.vecicleCard2 > div {
  border-radius: 8px !important;
}

.vecicleCard2_popup > div {
  border-radius: 8px 8px 0 0 !important;
}

.vecicleCard2 > a {
  height: 25% !important;
  margin: auto 0;
  /* display: none !important; */
}

.popupStacked > div > .vecicleCard2_popup > div:nth-child(1),
.popupStacked > div > .vecicleCard2_popup > a {
  display: none !important;
}

.vecicleCard2_popup > .carousel-indicators > button {
  width: 16px !important;
}

.vecicleCard2_popup > div {
  margin-bottom: 0 !important;
}

.vecicleCardGroup {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  color: #000;
  height: inherit;
}
.frameChild14 {
  align-self: stretch;
  position: relative;
  background-color: #e2e8f0;
  height: 1px;
}
.clearTheFilter {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #64748b;
}
.iconoutlinetruckParent2,
.moreDistantButNoLessImpoParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconoutlinetruckParent2 {
  flex-direction: row;
  gap: 16px;
}
.clearFilters {
  margin-block-start: 0;
  margin-block-end: 6px;
  font-weight: 600;
}
.more458Transport {
  margin: 0;
  font-size: 14px;
}
.text4 {
  position: relative;
  line-height: 12px;
}
.button10,
.button9 {
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button9 {
  background-color: #f1f5f9;
  display: none;
  padding: 16px;
}
.button10 {
  background-color: #fa6f32;
  height: 36px;
  display: flex;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 14px;
  color: #fff;
}
.frameParent5,
.frameParent6,
.toast {
  align-self: stretch;
  border-radius: 8px;
}
.toast {
  background-color: #f1f5f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: 16px;
  color: #0f172a;
}
.frameParent5,
.frameParent6 {
  position: relative;
  height: 157px;
  background-image: url(/public/Map_Page/frame-24032@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.frameParent6 {
  background-image: url(/public/Map_Page/frame-24033@3x.png);
}
.resultsFound1 {
  position: relative;
  line-height: 150%;
  color: #000;
  display: none;
}
.topBorder {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: #e2e8f0;
  height: 1px;
}
.topBorder1 {
  align-self: stretch;
  position: relative;
  height: 2px;
}
.topBorder3 {
  background-color: rgb(251, 129, 76);
  align-self: stretch;
  position: relative;
  height: 2px;
}
.previous {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}
.content,
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.content {
  align-items: center;
  justify-content: flex-start;
  padding: 16px 4px 0 0;
  gap: 12px;
}
.content1 {
  align-items: flex-start;
  justify-content: center;
  padding: 16px 16px 0;
}
.bottomBorder3 {
  align-self: stretch;
  position: relative;
  background-color: #fb814c;
  height: 2px;
}
.numberLinks,
.paginationnumberLink1 {
  align-items: flex-start;
  justify-content: flex-start;
}
.paginationnumberLink1 {
  display: flex;
  flex-direction: column;
  color: #fa6f32;
}
.numberLinks {
  text-align: center;
}
.content8,
.links,
.numberLinks {
  display: flex;
  flex-direction: row;
}
.content8 {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0 0 4px;
  gap: 12px;
}
.links {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  align-items: flex-start;
  justify-content: space-between;
}
.centeredPageNumbersdesktop {
  align-self: stretch;
  position: relative;
  height: 38px;
  color: #64748b;
}
.frameContainer {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  color: #fff;
}
.frameChild23 {
  position: relative;
  background-color: #e2e8f0;
  width: 100%;
  height: 1px;
}
.image75Icon {
  position: relative;
  width: 70px;
  height: 24px;
  object-fit: contain;
}
.headline {
  position: relative;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.frameParent8,
.image75Parent {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.frameParent8 {
  flex-direction: row;
  gap: 0 !important;
  z-index: 999;
}

.frameParent8::nth-child(1) {
  border-right: 1px solid gray;
  border-right: 1px solid gray;
}

.frameParent8_1 {
  top: 1vw !important;
  flex-direction: row;
  gap: 0 !important;
  z-index: 999;
}
.choiceChild {
  position: absolute;
  margin: 0 !important;
  top: 72px;
  left: 1vw;
  background-color: #0f172a;
  width: 39px;
  height: 2px;
  z-index: 1;
}
.choice {
  position: relative;
  color: #0f172a;
  height: fit-content;
}
.choice > p {
  color: black;
  font-weight: bolder;
}

.frameChild24 {
  align-self: stretch;
  position: relative;
  background-color: #cbd5e1;
  width: 2px;
}
.image75Icon1 {
  position: relative;
  width: 50px;
  height: 24px;
  object-fit: contain;
  opacity: 0.5;
}
.iconsolidchevronDown2 {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.instanceChild {
  position: absolute;
  margin: 0 !important;
  top: 70px;
  left: 0;
  background-color: #0f172a;
  width: 39px;
  height: 2px;
  display: none;
  z-index: 1;
}
.frameParent9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  gap: 18px;
}
.frameChild25 {
  align-self: stretch;
  position: relative;
  background-color: #cbd5e1;
  width: 2px;
  display: none;
}
.button11,
.choiceParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.listFade {
  position: absolute;
  background: Red;
  width: 80px;
  height: 100px;
  top: 0;
  right: 7.5vw;
  z-index: 9999;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.choiceParent:not(:nth-child(1)) {
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: inherit;
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 85%;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
}
.button11 {
  display: flex;
  border-radius: 8px;
  background-color: #f1f5f9;
  overflow: hidden;
  justify-content: center;
  padding: 16px;
  gap: 8px;
  color: #0f172a;
}
.frameChild26 {
  position: absolute;
  top: 0;
  right: 8vw;
  background: linear-gradient(-90deg, #fff, rgba(255, 255, 255, 0));
  width: 113px;
  height: 100px;
}
.frameParent7 {
  position: relative;
  justify-content: space-between;
  display: flex;
  width: 100%;
  left: 5vw;
  height: 100px;
  overflow: hidden;
  flex-shrink: 0;
  left: 0;
}

.filterSpan {
  font-size: 15px;
}
.rectangleParent {
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.mapPage {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: #94a3b8;
  font-family: Inter, sans-serif;
}

.mainTruckList {
  width: 100%;
  gap: 2%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.truckContainer2 {
  display: flex;
  margin-bottom: 1vh;
  flex-direction: column;
}

.dashboardButton {
  color: #616161 !important;
  margin: auto;
  font-weight: 400;
}

::-webkit-scrollbar {
  display: none;
}

button[data-bs-target][aria-current="false"] {
  position: relative;
  border-radius: 50%;
  background-color: #f8fafc;
  width: 8px;
  height: 8px;
  opacity: 0.2;
}

button[data-bs-target][aria-current="true"] {
  position: relative;
  border-radius: 50%;
  background-color: #f8fafc;
  width: 8px;
  height: 8px;
}

.vecicleCard2_popup > div > button[data-bs-target] {
  height: 8px;
  width: 8px;
}

.vecicleCard2_popup > a:nth-of-type(1) {
  background-color: #141414 !important;
  margin-top: 25% !important;
  border-radius: 320px;
  width: 35px;
  height: 35px !important;
  left: 3% !important;
}

.vecicleCard2_popup > a:nth-of-type(2) {
  background-color: #141414 !important;
  border-radius: 320px;
  width: 35px;
  height: 35px !important;
  margin-top: 25% !important;

  right: 3% !important;
}

.vecicleCard2_popup > a:nth-of-type(1) > span {
  background-image: url("/public/Map_Page/chevron-left.svg") !important;

  margin-right: 2%;
  width: 16px;
  height: 16px;
}

.vecicleCard2_popup > a:nth-of-type(2) > span {
  background-image: url("/public/Map_Page/chevron-right.svg") !important;

  margin-left: 2%;
  width: 16px;
  height: 16px;
}

.carousel-indicators {
  bottom: -17px !important;
  display: none !important;
}

.badgesOnCard4,
.badgesOnCard2,
.badgesOnCard3 {
  width: 30px;
  transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  position: relative;
}

.frameParent3:hover .badgesOnCard2 {
  width: 90px;
}

.frameParent3:hover .badgesOnCard3 {
  width: 100px;
}

.frameParent3:hover .badgesOnCard4 {
  width: 80px;
}

.frameParent3 .badgesOnCard2::after {
  color: white;
  content: "Truck4";
  position: absolute;
  top: 0;
  left: 35px;
  opacity: 0;
  transition: opacity 0.1s 0.001s ease-in;
}

.frameParent3 .badgesOnCard4::after {
  color: white;
  content: "Boost";
  position: absolute;
  top: 0;
  left: 35px;
  opacity: 0;
  transition: opacity 0.1s 0.001s ease-in;
}

#portals {
  position: absolute;
  top: 7vh;
  right: 16vw;
}

.frameParent3 .badgesOnCard3::after {
  color: white;
  content: "Premium";
  position: absolute;
  top: 0;
  left: 35px;
  opacity: 0;
  transition: opacity 0.1s 0.001s ease-in;
}

.frameParent3:hover .badgesOnCard2::after,
.frameParent3:hover .badgesOnCard3::after,
.frameParent3:hover .badgesOnCard4::after {
  opacity: 1;
}

.frameParent3Popup > img {
  height: 22vh !important;
}

.carousel-control-next,
.carousel-control-prev {
  height: 50% !important;
  margin: auto 0 !important;
}

.truck_container {
  display: none !important;
}

.arrowRightContainer {
  right: 13vh;
  top: 30%;
  box-shadow: 0px 0px 4px 0px #9b9b9b;
  z-index: 10001;
  background-color: white;
  border-radius: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  width: 4vh;
  height: 4vh;
}

.popupScrollable {
  border-radius: 16px !important;
  max-height: 26vh;
  /* overflow-y: auto; */
  height: 250px !important;
  width: 300px !important;

  font-family: Arial, sans-serif;
}

.popupScrollable2 {
  overflow: auto !important;
  width: 300px !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.popupScrollable2 > div:nth-of-type(2) {
  display: none !important;
}

.popupScrollable2::-webkit-scrollbar {
  height: 10px !important;
}
/*  


.vehicleCard2_popup {
  width: 100%;
  margin: auto;
}

.frameParent3Popup {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.popupScrollable2 > div {
  border-radius: 12px 0 0 12px;
}

.popupScrollable2 > .leaflet-popup-content-wrapper > div {
  width: 100% !important;
}

.containerLinks2 > div > button,
.containerLinks2 > div > button > button {
  width: 100% !important;
  padding: 0 !important;
}

.containerLinks2 {
  width: 12vw !important;
}

.arrowLeftContainer {
  left: 13vh;
  top: 35%;
  box-shadow: 0px 0px 4px 0px #9b9b9b;
  z-index: 99999;
  background-color: white;
  border-radius: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  width: 4vh;
  height: 4vh;
}

.arrowRight {
  font-size: 1.25rem;
}

.selectedTruck {
  position: absolute;
  height: 2px;
  width: 4vw;
  justify-content: center;
  background-color: transparent;
  bottom: 0;
}

.selectedTruckContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.topMap {
  display: none;
  height: 300px;
  overflow: scroll;
}

.verticalDivider {
  display: flex;
  width: 1px;
  background: #e7e4e4;
  height: 6vh;
  margin: 0 2vh;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownParent {
  position: absolute;
  border-radius: 8px;
  left: -3vw;
  overflow: hidden;
  box-shadow: 0px 5px 11px 0px #0000004d;
}

.dropdownContent {
  display: none;
  min-width: 160px;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 2px;
  z-index: 1;
  color: #575757;
}

.dropdownContent b {
  display: block;
  padding: 8px;
  background-color: #fff;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .dropdownContent b {
  background-color: #fff;
}

@media screen and (max-width: 1550px) {
  .buttonContainer {
    width: 30vw;
  }
}

@media (max-width: 1350px) {
  .button {
    width: 10vw;
    font-size: small;
  }
  .basicInput {
    font-size:12px !important;
  }
}

@media (max-width: 1300px) {
  .frameContainer,
  .frameDiv,
  .buttonContainer {
    width: 450px;
  }
  .frameParent3 > a > img {
    height: 150px;
  }
}

@media (max-width: 1200px) {
  .basicInputParent {
    width: 25vw;
  }
  .frameParent3 > a > img {
    height: 150px;
  }
}

@media (max-width: 1050px) {
  .frameContainer,
  .frameDiv,
  .buttonContainer {
    width: 400px;
  }
  .mainTruckList {
    grid-template-columns: auto;
    width: 100%;
  }

  .vecicleCardGroup {

    justify-content: unset;
  }
  .tandemTractor,
  .clearTheFilter,
  .headline {
    font-size: small;
  }
  .button9 {
    padding: 0px;
  }
  .theLogoMakerBuiltFor1000 {
    width: 15vw;
  }
  /*.emptyDivParent {*/
  /*  padding: 0px 5%;*/
  /*}*/
  .alreadyHaveAccount
   {
    padding: 0px 5%;
  }
  .frameParent3 > a > img {
    height: 240px;
  }
}

.truckListChoices {
  max-width: 7vw;
}

@media (max-width: 992px) {
  .verticalDivider {
    display: none;
  }

    .basicInput {
      font-size:9px !important;
    }

}

@media (max-width: 849px) {
  .mainTruckList {
    grid-template-columns: auto;
    width: 100%;
    gap:0;
  }
  .mainContent {
    display: block;
  }
  .buttonContainer {
    display: block;
    width: 100%;
  }
  .frameContainer {
    background-color: #ffffff;
    padding: 8px;
    width: 100%;
    border-radius: 10px;
  }

  .frameDiv {
    width: 70%;
    text-align: center;
  }
  .topMap {
    display: block;
    border-radius: 10px;
  }

  .bottomMap {
    display: none;
    width: 100vh;
    height: 100vh;
  }

  .dialogWindow {
    width: 300px;
  }

  .rectangleParent {
    margin: unset;
    width: 100%;
  }

  .navbar > .container {
    flex-wrap: unset;
  }

  .navbar {
    width: 84% !important;
  }

  .containerLinks > a {
    max-width: unset !important;
  }

  .containerMain {
    flex-flow: nowrap;
  }

  .truckListChoices {
    max-width: unset;
  }

  .theLogoMakerBuiltFor1000 {
    width: 30vw;
  }

  .frameParent3 > a > img {
    height: 300px;
  }
}
@media (max-width: 550px) {
  .frameGroup {
    width: unset !important;
    margin: auto;
  }
  .frameGroup > .frameDiv {
    width: 90vw;
  }

  .mainContent {
    margin: 0px 3%;
  }
  .theLogoMakerBuiltFor1000 {
    width: 40vw;
  }
  .frameParent3 > a > img {
    height: 250px;
  }
}

@media (max-width: 550px) {
  .links1 > * {
    font-size: 16px;
  }
  .mainContent {
    margin: 0px 3%;
  }
  .theLogoMakerBuiltFor1000 {
    width: 40vw;
  }
  .frameGroup > .frameDiv {
    display: flex;
    flex-direction: column;
  }
  .frameGroup {
    margin-bottom: 20px;
  }
}

@media (max-width: 420px) {
  .iconoutlinetruckParent2 {
    display: none;
  }
  .filterContainer,
  .button10 {
    width: 100%;
  }
  .links1 {
    font-size: x-small;
  }
  .frameParent3 > a > img {
    height: 180px;
  }
}

.footerssimpleLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-size: 12px;
}

.text8 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
}
.text9 {
  position: relative;
  line-height: 24px;
}
.footerssimpleLink2,
.links1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footerssimpleLink2 {
  justify-content: center;
  padding: 8px 20px;
}
.links1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  height: 24px;
  justify-content: flex-start;
}
.container1,
.footerssocialIcon {
  width: 100%;
  position: relative;
  height: 24px;
}

.container1 {
  margin-bottom: 2vh;
}

.links1 > * > a:hover {
  color: #e1642d !important;
}
.footerssocialIcon {
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon1 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon3 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footerssocialLink3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialIcon4 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.footersocialLinks,
.footerssocialLink4 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.footerssocialLink4 {
  display: flex;
}
.footersocialLinks {
  display: none;
  gap: 24px;
}
.copyright {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #9ca3af;
  text-align: center;
  font-size: 12px;
}

.footer {
  background-color: #ffffff;
  height: 150px;
  justify-content: center;
}
.container,
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdownArrow {
  position: absolute;
  right: 0;
  transition: transform 0.3s;
}

.arrowDown {
  transform: rotate(0deg);
  right: -5px;
  top: 35%;
}

.arrowUp {
  transform: rotate(180deg);
  right: -5px;
  top: 35%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure overlay is above other content */
}

.overlayText {
  color: white;
  font-size: 32px; /* Adjust size as needed */
  text-align: center;
}

.button7 {
  position: relative; /* Needed for absolute positioning of dropdown */
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 0;
  background-color: white; /* Background for dropdown */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  z-index: 1000; /* Ensure it appears above other elements */
  width: 100%; /* Full width of the button */
}

.dropdownOption {
  padding: 10px; /* Padding for dropdown items */
  cursor: pointer; /* Pointer cursor for options */
}

.dropdownOption:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

@media (max-width: 768px) {
  .frameParent3 {
    height: 10rem !important;
  }
}
